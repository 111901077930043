import classnames from 'classnames';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath, NavLink } from 'react-router-dom';

import { ROUTES } from '@app/config/constants';
import { getUserIsLoggedIn } from '@app/store/user/selectors';
import Animation from '@component/Animation';

enum Type {
  'general',
  'pageNotFound',
  'collectionNotFound',
  'itemNotFound',
  'embedNotFound',
}

export type Props = {
  type: Type;
  className?: string;
};

export const ErrorState = ({ type = Type.general, className }: Props) => {
  const isUserLoggedIn = useSelector(getUserIsLoggedIn);

  const illustration = useMemo(() => {
    switch (type) {
      case Type.collectionNotFound:
      case Type.itemNotFound:
      case Type.embedNotFound:
        return <Animation type="CollectionDeleted" className="w-60 mb-9" />;
      default:
        return <Animation type="NotFound" className="w-60 mb-9" />;
    }
  }, [type]);

  const content = useMemo(() => {
    switch (type) {
      case Type.pageNotFound:
        return (
          <>
            <Trans
              i18nKey="error_state.page-not-found.title"
              parent="h1"
              className="ts-h1 mb-3"
            >
              Nothing to see here
            </Trans>
            <Trans
              i18nKey="error_state.page-not-found.description"
              parent="p"
              className="ts-b1 text-center max-w-lg"
            >
              We looked all over but couldn’t find what you’re looking for—yes,
              even in the shoebox under the bed.
            </Trans>
            {isUserLoggedIn ? (
              <NavLink
                to={ROUTES.HOME}
                className="ts-b1 mt-3 text-black underline"
              >
                <Trans i18nKey="error_state.page-not-found.link">
                  Back to All items
                </Trans>
              </NavLink>
            ) : (
              <NavLink
                to={ROUTES.HOME}
                className="ts-b1 mt-3 text-black underline"
              >
                <Trans i18nKey="error_state.page-not-found.link-logged-in">
                  Get started with Collect
                </Trans>
              </NavLink>
            )}
          </>
        );

      case Type.collectionNotFound:
        return (
          <>
            <Trans
              i18nKey="error_state.collection-not-found.title"
              parent="h1"
              className="ts-h1 mb-3"
            >
              This board was not found
            </Trans>
            {isUserLoggedIn ? (
              <Trans
                i18nKey="error_state.collection-not-found.description-logged-in"
                parent="p"
                className="ts-b1 text-center max-w-lg"
              >
                Don’t worry, there’s more inspiration out there.
                <br />
                To view everything else you’ve saved, go to{' '}
                <NavLink to={ROUTES.HOME} className="text-black underline">
                  All items
                </NavLink>
                .
              </Trans>
            ) : (
              <>
                <Trans
                  i18nKey="error_state.collection-not-found.description"
                  parent="p"
                  className="ts-b1 text-center max-w-lg"
                >
                  Don’t worry, you can always create your own beautiful board.
                </Trans>
                <NavLink
                  to={ROUTES.HOME}
                  className="ts-b1 mt-3 text-black underline"
                >
                  <Trans i18nKey="error_state.collection-not-found.link">
                    Get started with Collect
                  </Trans>
                </NavLink>
              </>
            )}
          </>
        );

      case Type.embedNotFound:
        return (
          <>
            <Trans
              i18nKey="error_state.embed-not-found.title"
              parent="h1"
              className="ts-h1 mb-3"
            >
              This board or item was not found
            </Trans>

            <Trans
              i18nKey="error_state.collection-not-found.description"
              parent="p"
              className="ts-b1 text-center max-w-lg"
            >
              Don’t worry, you can always create your own beautiful board.
            </Trans>
            <Trans
              i18nKey="error_state.collection-not-found.link"
              parent="a"
              href={generatePath(ROUTES.HOME)}
              className="ts-b1 mt-3 text-black underline"
              target="_blank"
              rel="noreferrer"
            >
              Get started with Collect
            </Trans>
          </>
        );

      case Type.itemNotFound:
        return (
          <>
            <Trans
              i18nKey="error_state.item-not-found.title"
              parent="h1"
              className="ts-h1 mb-3"
            >
              Item not found
            </Trans>
            {isUserLoggedIn ? (
              <Trans
                i18nKey="error_state.item-not-found.description-logged-in"
                parent="p"
                className="ts-b1 text-center max-w-lg"
              >
                Don’t worry, there’s more inspiration out there
                <NavLink to={ROUTES.HOME} className="text-black underline pl-1">
                  All items
                </NavLink>
                .
              </Trans>
            ) : (
              <>
                <Trans
                  i18nKey="error_state.item-not-found.description"
                  parent="p"
                  className="ts-b1 text-center max-w-lg"
                >
                  Don’t worry, there’s more inspiration out there.
                  <br />
                  To save more items,
                </Trans>
                <NavLink
                  to={ROUTES.HOME}
                  className="ts-b1 mt-3 text-black underline"
                >
                  <Trans i18nKey="error_state.item-not-found.link">
                    Get started with Collect
                  </Trans>
                </NavLink>
              </>
            )}
          </>
        );

      default:
        return (
          <>
            <Trans
              i18nKey="error_state.general.title"
              parent="h1"
              className="ts-h1 mb-3"
            >
              Hmm, that’s odd
            </Trans>
            <Trans
              i18nKey="error_state.general.description"
              parent="p"
              className="ts-b1 text-center max-w-lg"
            >
              Something’s gone wrong, but we’re not quite sure what.
              <br />
              Try reloading this page or come back in a few minutes.
            </Trans>
          </>
        );
    }
  }, [type, isUserLoggedIn]);

  return (
    <div
      className={classnames(
        'flex flex-col items-center justify-center grow',
        {
          'absolute top-0 left-0 w-screen h-screen':
            type === Type.general ||
            type === Type.itemNotFound ||
            type === Type.pageNotFound,
        },
        className
      )}
      data-test-id="error-state"
    >
      {illustration}
      {content}
    </div>
  );
};

ErrorState.type = Type;
