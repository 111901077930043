import { createSlice } from '@reduxjs/toolkit';

import {
  addCollection,
  removeCollection,
  editCollection,
  addCollectionMemberships,
  removeCollectionMemberships,
} from '@app/store/collections/action-creators';
import type { CollectionsState } from '@app/store/collections/types';
import {
  addItems,
  removeItems,
  moveItem,
  updateItem,
} from '@app/store/items/actions';

export const initialState: CollectionsState = {};

export const collectionsSlice = createSlice({
  name: 'collections',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addCollection, (state, { payload: { collection } }) => {
        return {
          ...state,
          [collection.id]: collection,
        };
      })
      .addCase(removeCollection, (state, { payload: { collectionId } }) => {
        if (!state[collectionId]) {
          return state;
        }

        delete state[collectionId];
      })
      .addCase(
        editCollection,
        (state, { payload: { collectionId, collection } }) => {
          if (!state[collectionId]) {
            return state;
          }

          return {
            ...state,
            [collectionId]: {
              ...state[collectionId],
              ...collection,
            },
          };
        }
      )
      // Item actions
      .addCase(addItems, (state, { payload: { collectionId, items } }) => {
        if (!state[collectionId]) {
          return state;
        }

        const newItemIds = Array.from(
          new Set([
            ...state[collectionId].items,
            ...items.map((item) => item.id),
          ])
        );

        return {
          ...state,
          [collectionId]: {
            ...state[collectionId],
            itemCount: Math.max(
              newItemIds.length,
              state[collectionId].itemCount
            ),
            items: newItemIds,
          },
        };
      })
      .addCase(removeItems, (state, { payload: { collectionId, itemIds } }) => {
        if (!state[collectionId]) {
          return state;
        }

        const newItemIds = state[collectionId].items.filter(
          (item) => !itemIds.includes(item)
        );

        return {
          ...state,
          [collectionId]: {
            ...state[collectionId],
            itemCount: state[collectionId].itemCount - itemIds.length,
            items: newItemIds,
          },
        };
      })
      .addCase(
        moveItem,
        (state, { payload: { collectionId, newCollectionId, itemId } }) => {
          const updatedFromCollectionItems = [
            ...state[collectionId].items.filter((id) => id !== itemId),
          ];
          const updatedToCollectionItems = Array.from(
            new Set([...state[newCollectionId].items, itemId])
          );
          return {
            ...state,
            [collectionId]: {
              ...state[collectionId],
              itemCount: updatedFromCollectionItems.length,
              items: updatedFromCollectionItems,
            },
            [newCollectionId]: {
              ...state[newCollectionId],
              itemCount: updatedToCollectionItems.length,
              items: updatedToCollectionItems,
            },
          };
        }
      )
      .addCase(updateItem, (state, { payload: { itemId, item } }) => {
        // Item id has not changed
        if (item.id === undefined || item.id === itemId) {
          return;
        }

        // Get collection id from item, or find it in the collections
        const collectionId =
          item.collectionId ??
          Object.keys(state).find((collectionId) =>
            state[collectionId].items.includes(itemId)
          );

        // No matching collection id found
        if (collectionId === undefined) {
          return;
        }

        // Remove old itemId, and add new
        const collectionItems = state[collectionId].items.filter(
          (collectionItemId) => collectionItemId !== itemId
        );
        collectionItems.push(item.id);

        const uniqueCollectionItems = Array.from(new Set(collectionItems));

        // Remove duplicates, and add back to collection
        state[collectionId].itemCount = uniqueCollectionItems.length;
        state[collectionId].items = uniqueCollectionItems;
      })
      // Membership actions
      .addCase(
        addCollectionMemberships,
        (state, { payload: { collectionId, memberships } }) => {
          if (!state[collectionId] || memberships.length === 0) {
            return state;
          }

          const collectionMembershipIds = state[collectionId].memberships.map(
            (membership) => membership.id
          );

          return {
            ...state,
            [collectionId]: {
              ...state[collectionId],
              memberships: [
                ...state[collectionId].memberships,
                ...memberships.filter(
                  (membership) =>
                    !collectionMembershipIds.includes(membership.id)
                ),
              ],
            },
          };
        }
      )
      .addCase(
        removeCollectionMemberships,
        (state, { payload: { collectionId, membershipIds } }) => {
          if (!state[collectionId] || membershipIds.length === 0) {
            return state;
          }

          return {
            ...state,
            [collectionId]: {
              ...state[collectionId],
              memberships: state[collectionId].memberships.filter(
                (membership) => !membershipIds.includes(membership.id)
              ),
            },
          };
        }
      );
  },
});

export default collectionsSlice.reducer;
