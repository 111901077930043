import { createSelector } from 'reselect';

import type { RootState } from '@app/store';

/* State */

const uiState = (state: RootState) => state.ui;

/* Selectors */

export const getUI = createSelector([uiState], (ui) => ui);
export const getSidebarClosed = createSelector(
  [getUI],
  (ui) => ui.sidebarClosed
);
export const getDraggingItemIds = createSelector(
  [getUI],
  (ui) => ui.draggingItemIds
);
export const getIsDraggingItems = createSelector(
  [getDraggingItemIds],
  (itemIds) => itemIds.length > 0
);
export const getShouldShowOnboarding = createSelector(
  [getUI],
  (ui) => !ui.seenOnboarding
);
export const getShouldIgnoreUnsavedChanges = createSelector(
  [getUI],
  (ui) => ui.ignoreUnsavedChanges
);
export const getUIMode = createSelector([getUI], (ui) => ui.mode);
