import { useTranslation } from 'react-i18next';

import type { Membership } from '@app/types/collections';
import AvatarList from '@component/AvatarList';
import Member from '@component/Member';
import Popover from '@component/Popover';

export type Props = {
  memberships?: Membership[];
};

export const PopoverMemberships = ({ memberships }: Props) => {
  const { t } = useTranslation();

  if (!memberships) {
    return null;
  }

  return (
    <Popover
      trigger={<AvatarList limit={5} members={memberships} size="small" />}
    >
      <div className="px-2">
        <span className="ts-l6 pb-2 uppercase text-gray-60 dark:text-gray-55">
          {t('label.in_this_board')}
        </span>
        <div>
          {memberships.map((member) => (
            <Member key={member.id} member={member} />
          ))}
        </div>
      </div>
    </Popover>
  );
};
