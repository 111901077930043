import type { Action, History, Location } from 'history';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import type { Middleware, Reducer, Store } from 'redux';

export type Methods = 'push' | 'replace' | 'go' | 'back' | 'forward';

export const ROUTER_ON_LOCATION_CHANGED = '@@router/ON_LOCATION_CHANGED';
export const ROUTER_CALL_HISTORY_METHOD = '@@router/CALL_HISTORY_METHOD';

export type LocationChangeAction = {
  type: typeof ROUTER_ON_LOCATION_CHANGED;
  payload: {
    location: Location;
    action: Action;
  };
};

export const onLocationChanged = (
  location: Location,
  action: Action
): LocationChangeAction => ({
  type: ROUTER_ON_LOCATION_CHANGED,
  payload: { location, action },
});

export type UpdateLocationAction<M extends Methods = Methods> = {
  type: typeof ROUTER_CALL_HISTORY_METHOD;
  payload: {
    method: M;
    args: Parameters<History[M]>;
  };
};

function updateLocation<M extends Methods = Methods>(method: M) {
  return (...args: Parameters<History[M]>): UpdateLocationAction<M> => ({
    type: ROUTER_CALL_HISTORY_METHOD,
    payload: { method: method, args },
  });
}

export const push = updateLocation('push');
export const replace = updateLocation('replace');
export const go = updateLocation('go');
export const back = updateLocation('back');
export const forward = updateLocation('forward');

export const routerActions = {
  push,
  replace,
  go,
  back,
  forward,
};

export type RouterActions = LocationChangeAction | UpdateLocationAction;

// Middleware

export function createRouterMiddleware(history: History): Middleware {
  return () =>
    (next) =>
    (
      action: ReturnType<
        typeof push & typeof replace & typeof go & typeof back & typeof forward
      >
    ) => {
      if (action.type !== ROUTER_CALL_HISTORY_METHOD) {
        return next(action);
      }
      history[action.payload.method](...action.payload.args);
    };
}

// Reducer

export type ReduxRouterState = {
  location: Location;
  action: Action;
};

export function createRouterReducer(
  history: History
): Reducer<ReduxRouterState, RouterActions> {
  const initialRouterState: ReduxRouterState = {
    location: history.location,
    action: history.action,
  };

  /*
   * This reducer will update the state with the most recent location history
   * has transitioned to.
   */
  return (state = initialRouterState, action: RouterActions) => {
    if (action.type === ROUTER_ON_LOCATION_CHANGED) {
      return { ...state, ...action.payload };
    }

    return state;
  };
}

// Component

type Props = {
  store: Store<{ router: ReduxRouterState }>;
  history: History;
  basename?: string;
  children: ReactNode;
  debug?: boolean;
  onLocationChange?: () => void;
};

export const ReduxRouter = ({
  store,
  history,
  children,
  debug,
  basename,
  onLocationChange,
}: Props) => {
  const [state, setState] = useState<{ action: Action; location: Location }>({
    action: history.action,
    location: history.location,
  });
  const [isTimeTravelling, setIsTimeTravelling] = useState(false);

  useEffect(() => {
    if (!debug) {
      return;
    }

    const unsubscribe = store.subscribe(() => {
      const locationInStore = store.getState().router.location;
      const historyLocation = history.location;

      if (
        history.action === 'PUSH' &&
        (historyLocation.pathname !== locationInStore.pathname ||
          historyLocation.search !== locationInStore.search ||
          historyLocation.hash !== locationInStore.hash ||
          historyLocation.state !== locationInStore.state)
      ) {
        setIsTimeTravelling(true);
        history.push(locationInStore);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [debug, history, store]);

  useEffect(() => {
    const unsubscribe = history.listen(({ location, action }) => {
      if (isTimeTravelling === false) {
        store.dispatch(onLocationChanged(location, action));
        if (onLocationChange !== undefined) {
          onLocationChange();
        }
      } else {
        setIsTimeTravelling(false);
      }
      setState({ action, location });
    });

    return () => {
      unsubscribe();
    };
  }, [history, isTimeTravelling, onLocationChange, store]);

  return (
    <Router
      navigationType={state.action}
      location={state.location}
      basename={basename}
      navigator={history}
    >
      {children}
    </Router>
  );
};
