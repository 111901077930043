import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath, useParams } from 'react-router-dom';

import { ROUTES, URL_SEARCH_PARAMS } from '@app/config/constants';
import type { AppDispatch } from '@app/store';
import { fetchCollectionItems } from '@app/store/collections/actions';
import {
  getNextItemByCollectionIdAndItemId,
  getPreviousItemByCollectionIdAndItemId,
  getCollectionById,
  getSortedItemIdsByCollectionId,
} from '@app/store/collections/selectors';
import { getItemByItemId } from '@app/store/items/selectors';
import { getIsFetchingInitialData } from '@app/store/pending/selectors';
import ErrorState from '@component/ErrorState';
import ItemDetail from '@component/ItemDetail';
import { useSearchParams } from '@hook/useSearchParams';

export const ItemPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { collectionId, itemId } = useParams();

  const item = useSelector(getItemByItemId({ id: itemId }));
  const nextItem = useSelector(
    getNextItemByCollectionIdAndItemId({
      id: collectionId,
      itemId: itemId,
    })
  );
  const previousItem = useSelector(
    getPreviousItemByCollectionIdAndItemId({
      id: collectionId,
      itemId: itemId,
    })
  );
  const collection = useSelector(
    getCollectionById({
      id: collectionId,
    })
  );
  const itemIds = useSelector(
    getSortedItemIdsByCollectionId({
      id: collectionId,
    })
  );
  const isFetchingInitialData = useSelector(getIsFetchingInitialData);

  const isLoading = useMemo(
    () =>
      isFetchingInitialData ||
      itemIds.length < (collection?.itemCount ?? Infinity),
    [isFetchingInitialData, itemIds.length, collection?.itemCount]
  );

  const {
    params: { [URL_SEARCH_PARAMS.fromAll]: fromAllParam },
  } = useSearchParams();

  const isFromAllItems = fromAllParam !== undefined;

  const closeUrl = useMemo(
    () =>
      collectionId && !isFromAllItems
        ? generatePath(ROUTES.COLLECTION, {
            collectionId,
          })
        : ROUTES.HOME,
    [collectionId, isFromAllItems]
  );

  // Load items
  useEffect(() => {
    if (
      isFetchingInitialData ||
      collection === undefined ||
      collection.itemCount === itemIds.length
    ) {
      return;
    }

    void dispatch(fetchCollectionItems(collection.id));
  }, [isFetchingInitialData, itemIds.length, collection, dispatch]);

  if (
    // Collection not found
    (!isFetchingInitialData && collection === undefined) ||
    // Item not found
    (!isLoading && item === undefined)
  ) {
    return <ErrorState type={ErrorState.type.itemNotFound} />;
  }

  return (
    <ItemDetail
      isFromAll={isFromAllItems}
      collection={collection}
      item={item}
      nextItem={nextItem}
      previousItem={previousItem}
      closeUrl={closeUrl}
      isLoading={isLoading}
    />
  );
};
