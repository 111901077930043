import classNames from 'classnames';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MODAL_TYPE } from '@app/config/constants';
import type { AppDispatch } from '@app/store';
import { getCaptionByItemId } from '@app/store/items/selectors';
import { openModal } from '@app/store/modal/actions';
import { setNotification } from '@app/store/notification/actions';
import { getCanEditCollection } from '@app/store/selectors';
import type { Collection } from '@app/types/collections';
import type { Item } from '@app/types/items';
import Icon from '@component/Icon';
import ItemCaptionContent from '@component/ItemCaptionContent';
import { NOTIFICATION_TYPE } from '@component/Notification/types';
import Popover from '@component/Popover';
import Tooltip from '@component/Tooltip';
import { useSearchParams } from '@hook/useSearchParams';
import { useToolbarAction } from '@hook/useToolbarAction';
import { track } from '@module/analytics';

type Props = {
  collectionId: Collection['id'];
  itemId: Item['id'];
};

export const ItemActionCaption = ({ itemId, collectionId }: Props) => {
  const {
    params: { caption: showCaption },
  } = useSearchParams();
  const ref = useRef<SVGSVGElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const toolbarAction = useToolbarAction();
  const caption = useSelector(getCaptionByItemId({ id: itemId }));
  const canEditContent = useSelector(
    getCanEditCollection({ id: collectionId })
  );

  const handleViewCaption = useCallback(() => {
    track('view_caption_tapped', {
      location: 'item_detail',
    });
  }, []);

  const handleUpdateCaption = useCallback(() => {
    if (!canEditContent) {
      dispatch(
        setNotification({ type: NOTIFICATION_TYPE.INFO_VIEW_ONLY_BOARD })
      );
      return;
    }

    track(caption ? 'update_caption_tapped' : 'add_caption_tapped', {
      location: 'item_detail',
    });

    dispatch(
      openModal({
        type: MODAL_TYPE.CAPTION,
        config: {
          itemId,
          shouldRedirect: true,
        },
      })
    );
  }, [caption, itemId, dispatch, canEditContent]);

  if (!caption) {
    return (
      <Tooltip label={t('button:tooltip_write_caption')}>
        <button
          className={classNames(toolbarAction, 'w-7 mr-1', {
            'opacity-50': !canEditContent,
          })}
          onClick={handleUpdateCaption}
          data-test-id="caption-add-button"
        >
          <Icon type="Write" />
        </button>
      </Tooltip>
    );
  }

  return (
    <Popover
      defaultOpen={showCaption !== undefined}
      buttonClassName={classNames(toolbarAction, 'mr-1')}
      trigger={
        <Tooltip label={t('button:tooltip_view_caption')}>
          <span
            className={classNames(
              'inline-flex w-7 h-7 justify-center items-center'
            )}
            data-test-id="caption-popover"
          >
            <Icon ref={ref} type="Caption" onClick={handleViewCaption} />
          </span>
        </Tooltip>
      }
    >
      <div className="w-80" data-test-id="caption-content-popover">
        <ItemCaptionContent itemId={itemId} collectionId={collectionId} />
      </div>
    </Popover>
  );
};
