export const CollectionListLoading = () => (
  <ul className="list-none m-0 p-0">
    {Array(5)
      .fill(1)
      .map((_, i) => (
        <li key={i}>
          <div className="flex p-2 animate-pulse">
            <span className="rounded-lg mr-2 w-8 h-8 bg-gray-40 dark:bg-gray-60" />
            <span>
              <span className="block rounded-lg w-24 h-3 mt-1 mb-1 bg-gray-40 dark:bg-gray-60" />
              <span className="block rounded-lg w-13 h-2 bg-gray-40 dark:bg-gray-60" />
            </span>
          </div>
        </li>
      ))}
  </ul>
);
