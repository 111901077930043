import type { RefObject } from 'react';
import { useMemo, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';

import { ROUTES } from '@app/config/constants';
import { dragEventContainsFiles } from '@app/helper';
import type { AppDispatch } from '@app/store';
import { moveItems } from '@app/store/items/actions';
import { getItemByItemId } from '@app/store/items/selectors';
import { getCanEditCollection } from '@app/store/selectors';
import {
  getDraggingItemIds,
  getIsDraggingItems,
} from '@app/store/ui/selectors';
import { getUserUnsortedId } from '@app/store/user/selectors';
import type { Collection } from '@app/types/collections';

type Props = {
  collectionId?: Collection['id'];
};

export type ReturnProps = {
  ref: RefObject<HTMLElement> | null;
  isDropTargetActive: boolean;
  isDropTargetDisabled: boolean;
};

export function useCollectionDropTarget<HTMLElement>({ collectionId }: Props) {
  const fieldRef = useRef<HTMLElement>(null);
  const [isDropTargetActive, setIsDropTargetActive] = useState(false);
  const [isDropTargetDisabled, setIsDropTargetDisabled] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const draggingItemIds = useSelector(getDraggingItemIds);
  const currentDragItem = useSelector(
    getItemByItemId({ id: draggingItemIds[0] })
  );
  const isDraggingItems = useSelector(getIsDraggingItems);

  const canEditTargetCollection = useSelector(
    getCanEditCollection({ id: collectionId })
  );

  const canEditFromCollection = useSelector(
    getCanEditCollection({ id: currentDragItem?.collectionId })
  );

  const unsortedId = useSelector(getUserUnsortedId);
  const isSortLaterRoute = useMatch(ROUTES.SORT_LATER) !== null;

  const isDroppingDisabled = useMemo(
    () =>
      !canEditTargetCollection ||
      !canEditFromCollection ||
      collectionId === currentDragItem?.collectionId ||
      (unsortedId === collectionId && isSortLaterRoute),
    [
      currentDragItem,
      collectionId,
      canEditTargetCollection,
      canEditFromCollection,
      unsortedId,
      isSortLaterRoute,
    ]
  );

  useEffect(() => {
    setIsDropTargetDisabled(isDraggingItems && isDroppingDisabled);
  }, [isDraggingItems, isDroppingDisabled]);

  useEffect(() => {
    const field = fieldRef.current;

    if (!(field instanceof HTMLElement)) {
      return;
    }

    const moveItem = async (itemId: string) => {
      if (collectionId === undefined || itemId === '') {
        return;
      }
      await dispatch(
        moveItems({
          itemIds: [itemId],
          toCollectionId: collectionId,
        })
      );
    };

    const handleDragOver = (event: DragEvent) => {
      event.preventDefault();

      if (isDroppingDisabled || dragEventContainsFiles(event)) {
        return;
      }

      // Set the dropEffect to move
      if (event.dataTransfer) {
        event.dataTransfer.dropEffect = 'move';
      }

      setIsDropTargetActive(true);
    };

    const handleDragLeave = () => {
      setIsDropTargetActive(false);
    };

    const handleDrop = (event: DragEvent) => {
      setIsDropTargetActive(false);

      if (event.dataTransfer === null || isDroppingDisabled) {
        return;
      }

      const itemId = event.dataTransfer.getData('text/plain');

      void moveItem(itemId);
      event.dataTransfer.clearData();
    };

    field.addEventListener('dragover', handleDragOver, false);
    field.addEventListener('dragleave', handleDragLeave);
    field.addEventListener('drop', handleDrop);

    return () => {
      field.removeEventListener('dragover', handleDragOver, false);
      field.removeEventListener('dragleave', handleDragLeave);
      field.removeEventListener('drop', handleDrop);
    };
  }, [dispatch, fieldRef, collectionId, isDroppingDisabled]);
  return {
    ref: fieldRef,
    isDropTargetActive,
    isDropTargetDisabled,
  };
}
