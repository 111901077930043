import classnames from 'classnames';
import type { InputHTMLAttributes } from 'react';
import { useMemo, forwardRef } from 'react';

import { getRandomToken } from '@app/helper';
import Label from '@component/Label';
import useFieldState from '@hook/useFieldState';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  hasError?: boolean;
  message?: string;
};

export const InputField = forwardRef(
  (
    {
      value,
      name,
      placeholder,
      id,
      className,
      type,
      hasError,
      message,
      onChange,
      ...props
    }: Props,
    ref
  ) => {
    const inputField = useFieldState<HTMLInputElement>();
    const fieldId = useMemo(
      () => id || `${name || 'input'}-${getRandomToken()}`,
      [id, name]
    );

    const messageClassName = classnames('ts-l4 pl-4', {
      'text-gray-60 dark:text-gray-55': !hasError,
      'text-red-55 dark:text-red-50': hasError,
    });

    const inputClassName = classnames(
      'peer p-4 pt-5 pb-3 w-full appearance-none rounded-xl bg-gray-20 dark:bg-gray-90 focus:outline-none ts-l1 disabled:text-gray-60 dark:disabled:text-gray-55 placeholder-gray-60 dark:placeholder-gray-55 placeholder-opacity-0 dark:placeholder-opacity-0',
      className,
      {
        'text-black dark:text-white': !hasError,
        'text-red-55 dark:text-redd-50': hasError,
      }
    );

    const labelClassName = classnames('absolute left-4 top-4 origin-0', {
      'transform -translate-y-3 scale-75': inputField.isFocused || value !== '',
      'text-blue dark:text-blue-50': inputField.isFocused,
    });

    return (
      <>
        <div className="relative w-full rounded-xl">
          <input
            id={fieldId}
            ref={inputField.ref}
            className={inputClassName}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            {...props}
          />
          {placeholder && (
            <Label
              className={labelClassName}
              htmlFor={fieldId}
              hasError={hasError}
            >
              {placeholder}
            </Label>
          )}
        </div>
        {message && (
          <span
            data-test-id={`message-${fieldId}`}
            className={messageClassName}
          >
            {message}
          </span>
        )}
      </>
    );
  }
);

InputField.displayName = 'Input';
