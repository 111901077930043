import { useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { ITEM_TYPE } from '@app/config/constants';
import type { Collection } from '@app/types/collections';
import type { Item } from '@app/types/items';
import AvatarList from '@component/AvatarList';
import DownloadLink from '@component/DownloadLink';
import Grid from '@component/Grid';

type Props = {
  collection: Collection;
  items: Item[];
};

export const PublicCollection = ({ collection, items }: Props) => {
  const { t } = useTranslation();

  const fileItemIds = useMemo(
    () =>
      items
        .filter((item) => item.type === ITEM_TYPE.FILE)
        .map((item) => item.id),
    [items]
  );

  const itemIds = useMemo(() => items.map((item) => item.id), [items]);

  return (
    <>
      <Trans
        className="bg-yellow-50 text-black ts-l3 text-center p-3 hidden sm:block"
        parent="div"
        i18nKey="join.banner"
      >
        You&apos;ve been invited to join this board. To continue, please log in
        or sign up.
      </Trans>
      <div className="mt-20 mb-0 px-4">
        <div className="mb-8 flex justify-between">
          <div>
            <h1
              className="m-0 text-ellipsis whitespace-nowrap overflow-hidden w-full ts-h2"
              data-test-id="public-collection-title"
            >
              {collection?.name ?? ''}
            </h1>
            {collection?.description && (
              <p
                className="ts-l5 mt-1 w-full pr-4"
                data-test-id="public-collection-description"
              >
                {collection.description}
              </p>
            )}
          </div>
          <div>
            <div className="my-4 flex justify-end">
              <AvatarList members={collection?.memberships ?? []} limit={4} />
            </div>
            <div>
              {collection !== undefined && (
                <DownloadLink
                  itemIds={fileItemIds}
                  title={t('button:download_all')}
                  data-test-id="download-all-button"
                >
                  <Trans i18nKey="button:download_all">Download all</Trans>
                </DownloadLink>
              )}
            </div>
          </div>
        </div>

        <Grid itemIds={itemIds} />
      </div>
    </>
  );
};
