import classNames from 'classnames';
import layoutGeometry from 'justified-layout';
import throttle from 'lodash/throttle';
import type { CSSProperties } from 'react';
import { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
  getItemIdsWithCaptionByItemIds,
  getItemsByItemIds,
  getPreviewDimensionsByItemIds,
} from '@app/store/items/selectors';
import { getSidebarClosed } from '@app/store/ui/selectors';
import { screens } from '@app/styles';

import type { Props as GridItemProps } from './GridItem';
import GridItem from './GridItem';
import GridLoading from './GridLoading';

type Props = {
  itemIds: GridItemProps['itemId'][];
  isLoading?: boolean;
  selectedItems?: GridItemProps['itemId'][];
} & Pick<GridItemProps, 'showItemLocation' | 'showItemCaption' | 'onSelect'>;

const GRID_GAP = 16;
const DEFAULT_PREVIEW_SIZE = 220;

export const Grid = ({
  isLoading,
  itemIds,
  selectedItems,
  onSelect,
  showItemLocation,
  showItemCaption,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number | undefined>(
    undefined
  );
  const [isAnimatingSidebar, setIsAnimatingSidebar] = useState<boolean>(false);

  const sidebarClosed = useSelector(getSidebarClosed);
  const items = useSelector(getItemsByItemIds({ ids: itemIds }));
  const filteredItemIds = useMemo(() => items.map((item) => item.id), [items]);
  const previews = useSelector(
    getPreviewDimensionsByItemIds({ ids: filteredItemIds })
  );
  const itemIdsWithCaption = useSelector(
    getItemIdsWithCaptionByItemIds({ ids: filteredItemIds })
  );

  const gridSizes = useMemo(
    () =>
      previews.map((preview) => {
        const width =
          preview.width && preview.width > 0
            ? preview.width
            : DEFAULT_PREVIEW_SIZE;

        const height =
          preview.height && preview.height > 0
            ? preview.height
            : DEFAULT_PREVIEW_SIZE;

        const aspectRatio = width / height;

        return aspectRatio < 0.66 && itemIdsWithCaption.includes(preview.id)
          ? 0.66
          : aspectRatio;
      }),
    [previews, itemIdsWithCaption]
  );

  useEffect(() => {
    const throttledResizeHandler = throttle(() => {
      const currentContainerWidth = containerRef.current?.offsetWidth;
      if (currentContainerWidth) {
        setContainerWidth(currentContainerWidth);
      }
    }, 100);
    throttledResizeHandler();
    window.addEventListener('resize', throttledResizeHandler);
    return () => window.removeEventListener('resize', throttledResizeHandler);
  }, []);

  useEffect(() => {
    setIsAnimatingSidebar(true);
    setTimeout(() => {
      const currentContainerWidth = containerRef.current?.offsetWidth;
      if (currentContainerWidth) {
        setContainerWidth(currentContainerWidth);
      }
    }, 300);
    setTimeout(() => {
      setIsAnimatingSidebar(false);
    }, 600);
  }, [sidebarClosed]);

  const { containerHeight, boxes } = useMemo(() => {
    return layoutGeometry(gridSizes, {
      containerWidth: containerWidth,
      boxSpacing: GRID_GAP,
      targetRowHeight: Math.floor(window.outerHeight / 4),
      targetRowHeightTolerance: 0.25,
      containerPadding: 0,
      fullWidthBreakoutRowCadence:
        window.outerWidth <= parseInt(screens.sm) ? 1 : false,
    });
  }, [gridSizes, containerWidth]);

  const containerStyle: CSSProperties = useMemo(
    () => ({
      height: isLoading ? '100%' : containerHeight,
    }),
    [isLoading, containerHeight]
  );

  const isDraggingEnabled = useMemo(
    () => selectedItems?.length === 0,
    [selectedItems]
  );

  const onDragStart = useCallback((e: React.DragEvent<HTMLElement>) => {
    const target = e.currentTarget as HTMLElement;

    if (e.dataTransfer) {
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/plain', target.dataset.id as string);
    }
  }, []);

  return (
    <div
      ref={containerRef}
      style={containerStyle}
      className={classNames('relative box-border mb-8 w-full', {
        'flex flex-wrap gap-4 w-full h-full overflow-hidden': isLoading,
        'overflow-hidden': isAnimatingSidebar,
      })}
    >
      {isLoading ? (
        <GridLoading size={DEFAULT_PREVIEW_SIZE} />
      ) : (
        filteredItemIds.map((id, index) => (
          <GridItem
            key={id}
            itemId={id}
            selected={selectedItems?.includes(id) ?? false}
            onSelect={onSelect}
            onDragStart={onDragStart}
            showItemLocation={showItemLocation}
            showItemCaption={showItemCaption}
            draggable={isDraggingEnabled}
            gridLayout={boxes[index]}
          />
        ))
      )}
    </div>
  );
};
