import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ROUTES, MODAL_TYPE, MODAL_MOVE_TYPE } from '@app/config/constants';
import type { AppDispatch } from '@app/store';
import { addFileItems, addWebItem, moveItems } from '@app/store/items/actions';
import { openModal } from '@app/store/modal/actions';
import { getUser } from '@app/store/user/selectors';
import type { MoveItemsModalProps } from '@app/types/modal';
import CollectionList from '@component/CollectionList';
import Icon from '@component/Icon';
import ListItem from '@component/ListItem';
import { push } from '@module/ReduxRouter';

export const MoveItemsModal = ({ closeModal, config }: MoveItemsModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(getUser);

  const type = config.type;
  const url = config?.url;
  const files = config?.files;
  const itemIds = config?.itemIds;
  const currentCollectionId = config?.currentCollectionId;

  useEffect(() => {
    if (!type) {
      dispatch(push(ROUTES.HOME));
    }
  }, [type, dispatch]);

  const handleCreateCollection = useCallback(() => {
    void dispatch(
      openModal({
        type: MODAL_TYPE.COLLECTION,
        config: {
          url,
          files,
          itemIds,
        },
      })
    );
  }, [dispatch, files, itemIds, url]);

  const handleOnClick = useCallback(
    (collectionId: string) => {
      closeModal();

      switch (type) {
        case MODAL_MOVE_TYPE.ADD_LINK:
          url && void dispatch(addWebItem(url, collectionId));
          break;
        case MODAL_MOVE_TYPE.ADD_FILES:
          if (!files) {
            return;
          }
          void dispatch(addFileItems(files, collectionId));
          break;
        case MODAL_MOVE_TYPE.MOVE_ITEMS:
          if (!itemIds) {
            return;
          }
          void dispatch(
            moveItems({
              itemIds,
              toCollectionId: collectionId,
            })
          );
          break;
        default:
          break;
      }
    },
    [closeModal, dispatch, files, itemIds, type, url]
  );

  const handleOnSingleItemsClick = useCallback(() => {
    if (!user.unsortedId) {
      return;
    }
    handleOnClick(user.unsortedId);
  }, [handleOnClick, user.unsortedId]);

  function renderSubtitle(type?: MODAL_MOVE_TYPE) {
    switch (type) {
      case MODAL_MOVE_TYPE.MOVE_ITEMS:
        return t('modal:move.title');

      case MODAL_MOVE_TYPE.ADD_FILES:
      case MODAL_MOVE_TYPE.ADD_LINK:
      default:
        return t('modal:add.title');
    }
  }

  function renderSingleItemsButton() {
    if (currentCollectionId === user.unsortedId) {
      return null;
    }

    return (
      <ListItem
        onClick={handleOnSingleItemsClick}
        data-id={user.unsortedId}
        icon="SortLater"
        iconClassName="text-blue dark:text-blue-50"
        size="large"
      >
        {t('button:sort_later')}
      </ListItem>
    );
  }

  return (
    <>
      <span className="text-center block uppercase ml-2 mb-2 ts-l6 text-gray-55">
        {renderSubtitle(type)}
      </span>
      {renderSingleItemsButton()}
      <div>
        <CollectionList
          currentCollectionId={currentCollectionId}
          onCollectionClick={handleOnClick}
        />
        <ListItem
          onClick={handleCreateCollection}
          size="large"
          className="py-1"
        >
          <span className="rounded-lg mr-2 w-8 h-8 bg-orange inline-flex items-center justify-center">
            <Icon
              type="Create"
              className="text-white dark:text-black fill-current"
            />
          </span>
          {t('button:create_new_board')}
        </ListItem>
      </div>
    </>
  );
};
