import classNames from 'classnames';
import type { ButtonHTMLAttributes } from 'react';
import { useSelector } from 'react-redux';

import { getCanEditCollection } from '@app/store/selectors';
import type { Collection } from '@app/types/collections';
import ListItemCollectionContent from '@component/ListItemCollectionContent';

type ListItemCollectionButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  collectionId: Collection['id'];
};

export const ListItemCollectionButton = ({
  collectionId,
  ...props
}: ListItemCollectionButtonProps) => {
  const canEditCollection = useSelector(
    getCanEditCollection({ id: collectionId })
  );
  return (
    <button
      {...props}
      disabled={!canEditCollection}
      className={classNames(
        'w-full rounded-xl hover:bg-gray-20 active:bg-gray-20 dark:hover:bg-black dark:hover:bg-opacity-70 dark:active:bg-black dark:active:bg-opacity-70 focus:outline-none focus-visible:ring-2 ring-inset focus-visible:ring-blue disabled:opacity-50 disabled:pointer-events-none',
        props.className
      )}
    >
      <ListItemCollectionContent collectionId={collectionId} />
    </button>
  );
};
