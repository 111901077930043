import type { ReactNode, ButtonHTMLAttributes } from 'react';
import { forwardRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { AppDispatch } from '@app/store';
import { downloadItems } from '@app/store/items/actions';
import { getIsDownloadingItems } from '@app/store/pending/selectors';
import type { Item } from '@app/types/items';
import { useAction } from '@hook/useAction';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: string | ReactNode;
  itemIds: Item['id'][];
};

export const DownloadLink = forwardRef<HTMLButtonElement, Props>(
  ({ itemIds, children, ...props }, ref) => {
    const action = useAction();
    const dispatch = useDispatch<AppDispatch>();
    const isDownloading = useSelector(getIsDownloadingItems);
    const hasNoItemsToDownload = itemIds.length === 0;

    const handleDownload = useCallback(() => {
      if (isDownloading) {
        return;
      }

      void dispatch(downloadItems(itemIds));
    }, [dispatch, itemIds, isDownloading]);

    return (
      <button
        {...props}
        className={action.primary}
        type="button"
        onClick={handleDownload}
        disabled={isDownloading || hasNoItemsToDownload}
        ref={ref}
      >
        {children}
      </button>
    );
  }
);

DownloadLink.displayName = 'DownloadLink';
