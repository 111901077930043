import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';

import {
  MODAL_CONFIRM_TYPE,
  MODAL_TYPE,
  ROUTES,
  URL_SEARCH_PARAMS,
} from '@app/config/constants';
import type { AppDispatch } from '@app/store';
import { updateCaption } from '@app/store/items/actions';
import { getItemByItemId } from '@app/store/items/selectors';
import { openModal } from '@app/store/modal/actions';
import type { CaptionModalProps } from '@app/types/modal';
import Icon from '@component/Icon';
import TextareaField from '@component/TextareaField';
import { useAction } from '@hook/useAction';
import { track } from '@module/analytics';

export const CaptionModal = ({ closeModal, config }: CaptionModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const action = useAction();
  const { t } = useTranslation();

  const itemId = config.itemId;

  const item = useSelector(getItemByItemId({ id: itemId }));
  const [caption, setCaption] = useState<string>('');
  const isUpdatingCaption = item?.caption !== '';
  const collectionId = item?.collectionId;

  useEffect(() => {
    setCaption(item?.caption || '');
  }, [item?.caption]);

  const handleClick = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();

      track('caption_save_tapped');
      await dispatch(updateCaption(itemId, caption));
      track('caption_saved', {
        update: isUpdatingCaption,
      });

      closeModal(
        config.shouldRedirect
          ? {
              redirectTo: `${generatePath(ROUTES.ITEM, {
                collectionId: collectionId ?? null,
                itemId,
              })}?${URL_SEARCH_PARAMS.caption}`,
            }
          : undefined
      );
    },
    [
      dispatch,
      itemId,
      caption,
      isUpdatingCaption,
      closeModal,
      config.shouldRedirect,
      collectionId,
    ]
  );

  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      track('caption_save_cancel_tapped');
      closeModal();
    },
    [closeModal]
  );

  const handleDelete = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();

      // If there is no previous caption present
      // we treat this like a cancel action
      if (item?.caption === undefined) {
        handleClose(event);
        return;
      }

      dispatch(
        openModal({
          type: MODAL_TYPE.CONFIRM,
          config: {
            type: MODAL_CONFIRM_TYPE.DELETE_CAPTION,
            itemId,
          },
        })
      );
    },
    [dispatch, itemId, item?.caption, handleClose]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      event.preventDefault();
      setCaption(event.currentTarget.value);
    },
    []
  );

  return (
    <>
      <Dialog.Title
        className="ts-h3 mb-3 text-black dark:text-white"
        data-test-id="modal-caption-title"
      >
        {t('modal:caption.title')}
      </Dialog.Title>
      <TextareaField
        value={caption}
        className="w-full"
        placeholder={t('modal:caption.placeholder')}
        onChange={handleChange}
      />
      <div className="flex">
        <div className="mt-3">
          <button
            onClick={handleDelete}
            className={classNames(action.icon, 'w-7')}
            data-test-id="cta-delete-caption-action"
          >
            <Icon type="Delete" className="h-4 text-red-55" />
          </button>
        </div>
        <div className="mt-3 ml-auto">
          <button
            className={classNames(action.secondary, 'ml-1')}
            type="button"
            onClick={handleClose}
            data-test-id="cta-cancel-caption-action"
          >
            {t('button:cancel')}
          </button>
          <button
            className={classNames(action.primary, 'ml-1')}
            type="button"
            data-test-id="cta-save-caption-action"
            onClick={handleClick}
            disabled={!caption}
          >
            {t('button:save')}
          </button>
        </div>
      </div>
    </>
  );
};
