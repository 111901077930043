import type { Config } from 'tailwindcss';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from './config.js';

const config = resolveConfig(tailwindConfig as unknown as Config);

export const screens = tailwindConfig.theme.screens;

export default config.theme as Exclude<Config['theme'], 'colors'> & {
  colors: typeof tailwindConfig.theme.colors;
  boxShadow: typeof tailwindConfig.theme.boxShadow;
  dropShadow: typeof tailwindConfig.theme.dropShadow;
};
