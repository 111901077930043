import { differenceInCalendarDays } from 'date-fns';
import { createSelector } from 'reselect';

import {
  ITEM_UPLOAD_STATUS,
  COLLECTION_TYPE,
  MEMBERSHIP_ROLE,
  COLLECTION_UPLOAD_STATUS,
} from '@app/config/constants';
import {
  getAllCollections,
  getAllOwnedCollections,
  getCollectionById,
  getSortedItemsByCollectionId,
} from '@app/store/collections/selectors';
import { getAllItems, getItemByItemId } from '@app/store/items/selectors';
import { paramId } from '@app/store/selectorParams';
import { getUI } from '@app/store/ui/selectors';
import {
  getUploaderFiles,
  getTotalBytesUploading,
} from '@app/store/uploader/selectors';
import { getUserUnsortedId, getUsage } from '@app/store/user/selectors';

export const getAllOwnedCollectionsWithoutUnsorted = createSelector(
  [getAllOwnedCollections, getUserUnsortedId],
  (collections, userUnsortedId) =>
    Object.values(collections).filter(({ id }) => id !== userUnsortedId)
);

export const getCollectionUploadStatusById = createSelector([paramId], (id) =>
  createSelector([getSortedItemsByCollectionId({ id })], (collectionItems) => {
    // Check if items are uploading
    if (
      !collectionItems
        .filter((item) => item?.uploadStatus !== ITEM_UPLOAD_STATUS.FAILED)
        .every((item) => item?.uploadStatus === ITEM_UPLOAD_STATUS.UPLOADED)
    ) {
      return COLLECTION_UPLOAD_STATUS.UPLOADING;
    }
    // Check if any items failed
    if (
      collectionItems.some(
        (item) => item?.uploadStatus === ITEM_UPLOAD_STATUS.FAILED
      )
    ) {
      return COLLECTION_UPLOAD_STATUS.FAILED;
    }
    return COLLECTION_UPLOAD_STATUS.NONE;
  })
);

const getCollectionUploadingItemsById = createSelector([paramId], (id) =>
  createSelector(
    [getSortedItemsByCollectionId({ id }), getUploaderFiles],
    (collectionItems, uploaderFiles) =>
      collectionItems.filter((item) =>
        Object.keys(uploaderFiles).some((itemId) => itemId === item.id)
      )
  )
);

export const getCollectionUploadTotalById = createSelector([paramId], (id) =>
  createSelector(
    [getCollectionUploadingItemsById({ id })],
    (items) => items.length
  )
);

export const getCollectionUploadRemainingById = createSelector(
  [paramId],
  (id) =>
    createSelector(
      [getCollectionUploadingItemsById({ id })],
      (items) =>
        items.filter(
          (item) =>
            item.uploadStatus === ITEM_UPLOAD_STATUS.QUEUED ||
            item.uploadStatus === ITEM_UPLOAD_STATUS.UPLOADING
        ).length
    )
);

export const getItemUploadProgressById = createSelector([paramId], (id) =>
  createSelector(
    [getItemByItemId({ id }), getUploaderFiles],
    (item, uploaderFiles) => {
      if (item === undefined || uploaderFiles[item.id] === undefined)
        return undefined;

      const totalSize = uploaderFiles[item.id].size;
      const totalProgress = Object.values(uploaderFiles[item.id].parts).reduce(
        (total, part) => part.progress + total,
        0
      );
      return Math.round((totalProgress / totalSize) * 100);
    }
  )
);

export const getCollectionByItemId = createSelector([paramId], (id) =>
  createSelector(
    [getAllCollections, getItemByItemId({ id })],
    (collections, item) =>
      item === undefined
        ? undefined
        : collections.find((collection) => collection.id === item.collectionId)
  )
);

export const getCanEditCollection = createSelector([paramId], (id) =>
  createSelector(
    [getCollectionById({ id })],
    (collection) =>
      (collection &&
        (collection.membershipRole === MEMBERSHIP_ROLE.OWNER ||
          collection.membershipRole === MEMBERSHIP_ROLE.EDITOR)) ??
      false
  )
);

export const getCanDeleteCollection = createSelector([paramId], (id) =>
  createSelector(
    [getCollectionById({ id })],
    (collection) =>
      (collection && collection.membershipRole === MEMBERSHIP_ROLE.OWNER) ??
      false
  )
);

export const getCanLeaveCollection = createSelector([paramId], (id) =>
  createSelector(
    [getCollectionById({ id })],
    (collection) =>
      (collection &&
        collection.type !== COLLECTION_TYPE.PRIVATE &&
        (collection.membershipRole !== 'owner' ||
          collection.memberships.length > 1)) ??
      false
  )
);

export const getCanReportCollection = createSelector([paramId], (id) =>
  createSelector(
    [getCollectionById({ id })],
    (collection) =>
      (collection &&
        (collection.type === COLLECTION_TYPE.COLLABORATIVE ||
          collection.type === COLLECTION_TYPE.SHARED ||
          collection.type === COLLECTION_TYPE.PUBLIC)) ??
      false
  )
);

export const getCanEditCaption = createSelector([paramId], (id) =>
  createSelector([getCollectionById({ id })], (collection) => {
    if (!collection) {
      return false;
    }

    if (collection.membershipRole === MEMBERSHIP_ROLE.FOLLOWER) {
      return false;
    }

    if (
      collection.membershipRole === MEMBERSHIP_ROLE.OWNER ||
      collection.membershipRole === MEMBERSHIP_ROLE.EDITOR
    ) {
      return true;
    }

    return false;
  })
);

export const getAllItemsCount = createSelector(
  [getAllCollections],
  (collections) =>
    Object.values(collections).reduce(
      (count, collection) => (count += collection.itemCount),
      0
    )
);

export const getShouldShowSurvey = createSelector(
  [getUI, getAllItems, getAllItemsCount],
  (ui, items, itemCount) => {
    const lastFiveItems = items.slice(0, 5);

    return (
      !ui.seenSurvey &&
      ui.seenOnboarding &&
      itemCount >= 50 &&
      lastFiveItems.length >= 5 &&
      lastFiveItems.every((item) =>
        item.createdAt
          ? differenceInCalendarDays(new Date(), item.createdAt) <= 30
          : false
      )
    );
  }
);

export const getWillExceedStorageCheck = createSelector(
  [getUsage, getTotalBytesUploading],
  ({ bytesUsed, bytesLimit, bytesGraced }, bytesAlreadyUploading) =>
    (bytesToAdd: number, ignoreUploads = false) =>
      bytesUsed + bytesToAdd + (ignoreUploads ? 0 : bytesAlreadyUploading) >
      bytesLimit + bytesGraced
);
