import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getShouldIgnoreUnsavedChanges } from '@app/store/ui/selectors';
import { getIsUploadingItems } from '@app/store/uploader/selectors';

export function useUnsavedChangesGuard() {
  const hasUnsavedChanges = useSelector(getIsUploadingItems);
  const shouldIgnoreUnsavedChanges = useSelector(getShouldIgnoreUnsavedChanges);

  useEffect(() => {
    const eventListener = (event: BeforeUnloadEvent) => {
      if (!hasUnsavedChanges || shouldIgnoreUnsavedChanges) {
        return;
      }

      event.preventDefault();
      return (event.returnValue = '');
    };

    window.addEventListener('beforeunload', eventListener);
    return () => {
      window.removeEventListener('beforeunload', eventListener);
    };
  }, [hasUnsavedChanges, shouldIgnoreUnsavedChanges]);
}
