import type { SnowplowPlugin } from '@analytics/snowplow';
import snowplowPlugin from '@analytics/snowplow';
import type { AnalyticsInstance } from 'analytics';
import Analytics from 'analytics';
import doNotTrack from 'analytics-plugin-do-not-track';

const DEBUG_ENABLED = process.env.REACT_APP_ANALYTICS_DEBUG_ENABLED === 'true';
const SP_APP_ID = process.env.REACT_APP_ANALYTICS_SNOWPLOW_APP_ID;
const SP_COLLECTOR_URL = process.env.REACT_APP_ANALYTICS_SNOWPLOW_COLLECTOR_URL;

// Documentation: https://getanalytics.io
const __analytics = Analytics({
  app: 'CollectWeb',
  debug: DEBUG_ENABLED,
  plugins: [
    doNotTrack(),
    snowplowPlugin({
      name: 'snowplow',
      scriptSrc: '/static/js/sp.v2-17-3.js',
      collectorUrl: SP_COLLECTOR_URL,
      trackerSettings: {
        appId: SP_APP_ID,
        contexts: {
          webPage: true,
        },
        bufferSize: 1,
        post: true,
        encodeBase64: false,
        respectDoNotTrack: true,
        userFingerprint: false,
        platform: 'web',
        stateStorageStrategy: 'cookie',
        cookieName: '_wt_snowplow',
        discoverRootDomain: true,
      },
    }),
  ],
});

let properties: Record<string, unknown> = {};

// Enable some automatic tracking before page event
__analytics.on(
  'initialize:snowplow',
  ({ instance }: { instance: AnalyticsInstance }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: TS doesn't know that snowplow actually exists because analytics plugin is poorly typed
    const snowplow = instance.plugins.snowplow as SnowplowPlugin;

    snowplow.enableActivityTracking(10, 10);
    snowplow.enableLinkClickTracking();
  }
);

function identify() {
  const { id, ...props } = properties;
  if (typeof id === 'string') {
    void __analytics.identify(id, props);
  }
}

const track = (event: string, data?: unknown) => {
  void __analytics.track('selfDescribingEvent', {
    schema: 'iglu:com.wetransfer/collectweb_event/jsonschema/1-0-5',
    data: {
      event_name: event,
      auth0_id: properties.sub,
      event_params: data,
      user_properties: properties,
    },
  });
};

const trackProps = (props: Record<string, unknown>) => {
  properties = {
    ...properties,
    ...props,
  };

  identify();
};

const trackPageView = () => {
  void __analytics.page();
};

export { __analytics, track, trackProps, trackPageView };
