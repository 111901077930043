import { useSelector } from 'react-redux';
import {
  Routes,
  Route,
  useParams,
  useLocation,
  Navigate,
  generatePath,
} from 'react-router-dom';

import { ROUTES } from '@app/config/constants';
import AllItemsPage from '@app/page/AllItemsPage';
import AuthPage from '@app/page/AuthPage';
import ClipperCallbackPage from '@app/page/ClipperCallbackPage';
import CollectionPage from '@app/page/CollectionPage';
import ItemPage from '@app/page/ItemPage';
import NotFoundPage from '@app/page/NotFoundPage';
import PublicCollectionPage from '@app/page/PublicCollectionPage';
import PublicItemPage from '@app/page/PublicItemPage';
import SortLaterPage from '@app/page/SortLaterPage';
import WelcomeBackPage from '@app/page/WelcomeBackPage';
import { getUserIsLoading, getUserIsLoggedIn } from '@app/store/user/selectors';

import Application from './Application';

const LoadGuard = ({ element }: { element: JSX.Element | null }) => {
  const isLoading = useSelector(getUserIsLoading);

  if (isLoading) {
    return null;
  }

  return element;
};

const AuthRoute = ({
  loggedInElement,
  loggedOutElement,
}: {
  loggedInElement?: JSX.Element;
  loggedOutElement?: JSX.Element;
}) => {
  const isLoggedIn = useSelector(getUserIsLoggedIn);

  return (
    <LoadGuard
      element={isLoggedIn ? loggedInElement ?? null : loggedOutElement ?? null}
    />
  );
};

const RedirectFromLatest = () => {
  // Our backend appends /latest by default, and mobile clients
  // require the /latest to be present in the request,
  // a client side redirect/replace is allowed
  const { collectionId } = useParams();
  const { search } = useLocation();
  const route = generatePath(ROUTES.COLLECTION, {
    collectionId: collectionId ?? null,
  });
  return <Navigate to={`${route}${search}`} replace={true} />;
};

const Router = () => (
  <Routes>
    {/* Callback pages */}
    <Route path={ROUTES.CLIPPER} element={<ClipperCallbackPage />} />
    <Route path={ROUTES.WELCOME_BACK} element={<WelcomeBackPage />} />
    {/* Auth pages */}
    <Route
      path={ROUTES.LOGIN}
      element={<AuthPage type={AuthPage.type.login} />}
    />
    <Route
      path={ROUTES.SIGN_UP}
      element={<AuthPage type={AuthPage.type.signUp} />}
    />
    {/* Application */}
    <Route path="/" element={<Application />}>
      <Route
        path={ROUTES.LOGOUT}
        element={
          <LoadGuard element={<AuthPage type={AuthPage.type.logout} />} />
        }
      />
      <Route
        path={ROUTES.CALLBACK}
        element={
          <LoadGuard element={<AuthPage type={AuthPage.type.callback} />} />
        }
      />
      <Route
        path={ROUTES.HOME}
        element={
          <AuthRoute
            loggedInElement={<AllItemsPage />}
            loggedOutElement={<AuthPage type={AuthPage.type.login} />}
          />
        }
      />
      <Route
        path={ROUTES.SORT_LATER}
        element={
          <AuthRoute
            loggedInElement={<SortLaterPage />}
            loggedOutElement={<AuthPage type={AuthPage.type.login} />}
          />
        }
      />
      <Route
        path={ROUTES.COLLECTION}
        element={
          <AuthRoute
            loggedInElement={<CollectionPage />}
            loggedOutElement={
              <PublicCollectionPage type={PublicCollectionPage.type.public} />
            }
          />
        }
      />
      <Route
        path={`${ROUTES.COLLECTION}/latest`}
        element={<RedirectFromLatest />}
      />
      <Route
        path={ROUTES.ITEM}
        element={
          <AuthRoute
            loggedInElement={<ItemPage />}
            loggedOutElement={
              <PublicItemPage type={PublicItemPage.type.public} />
            }
          />
        }
      />
      <Route
        path={ROUTES.EMBED_COLLECTION}
        element={
          <PublicCollectionPage type={PublicCollectionPage.type.embed} />
        }
      />
      <Route
        path={ROUTES.EMBED_ITEM}
        element={<PublicItemPage type={PublicItemPage.type.embed} />}
      />
    </Route>
    {/* Not found */}
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export default Router;
