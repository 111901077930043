import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { BaseModal, ModalState } from './types';

const initialState: BaseModal = {};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<ModalState>) => {
      return action.payload;
    },
    closeModal: (state) => {
      return initialState;
    },
  },
});

export default modalSlice.reducer;
