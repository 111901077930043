import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { MODAL_TYPE } from '@app/config/constants';
import { useMatchRoutes } from '@app/hooks/useMatchRoutes';
import type { AppDispatch } from '@app/store';
import { closeModal } from '@app/store/modal/actions';
import { getModal } from '@app/store/modal/selectors';
import type { BaseModalProps } from '@app/types/modal';

import ModalContent from './Content';

export const Modal = () => {
  const navigate = useNavigate();
  const modal = useSelector(getModal);
  const type = modal.type;
  const config = modal.config;
  const dispatch = useDispatch<AppDispatch>();
  const isLargeScreenRoute = useMatchRoutes('largeScreen');

  const handleClose = useCallback<BaseModalProps['closeModal']>(
    ({ redirectTo } = {}) => {
      dispatch(closeModal());
      if (redirectTo !== undefined) {
        navigate(redirectTo);
        return;
      }
      if (config?.redirectOnCloseUrl !== undefined) {
        navigate(config.redirectOnCloseUrl);
      }
    },
    [dispatch, navigate, config?.redirectOnCloseUrl]
  );

  const onClose = useCallback(() => handleClose(), [handleClose]);

  const contentClassName = classNames(
    'inline-block w-full my-8 overflow-y-auto max-h-full text-left align-middle transition-all transform bg-white dark:bg-black shadow-xl rounded-xl',
    {
      'max-w-4xl': type === MODAL_TYPE.STORAGE_UPSELL,
      'max-w-[880px] mx-4': type === MODAL_TYPE.ONBOARDING,
      'max-w-[226px]': type === MODAL_TYPE.MOVE_ITEMS,
      'max-w-xs': type === MODAL_TYPE.SURVEY,
      'max-w-sm':
        type !== MODAL_TYPE.STORAGE_UPSELL &&
        type !== MODAL_TYPE.ONBOARDING &&
        type !== MODAL_TYPE.MOVE_ITEMS &&
        type !== MODAL_TYPE.SURVEY,
      'p-2': type === MODAL_TYPE.MOVE_ITEMS,
      'p-4': type !== MODAL_TYPE.ONBOARDING && type !== MODAL_TYPE.MOVE_ITEMS,
      'overflow-hidden': type === MODAL_TYPE.ONBOARDING,
      'hidden sm:inline-block': isLargeScreenRoute,
    }
  );

  return (
    <Dialog
      open={type !== undefined}
      onClose={onClose}
      className="flex items-center justify-center fixed z-modal inset-0 py-4"
    >
      <Dialog.Overlay className="fixed inset-0 bg-gray-90 opacity-30" />
      <div className={contentClassName} data-test-id="modal-content">
        {type !== undefined && (
          <ModalContent type={type} closeModal={handleClose} config={config} />
        )}
      </div>
    </Dialog>
  );
};
