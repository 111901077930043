import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import { useCallback, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';

import {
  COLLECTION_TITLE_MAX_LENGTH,
  ROUTES,
  MODAL_TYPE,
  MODAL_MOVE_TYPE,
} from '@app/config/constants';
import type { AppDispatch } from '@app/store';
import {
  updateCollection,
  createCollection,
} from '@app/store/collections/actions';
import { getCollectionById } from '@app/store/collections/selectors';
import { moveItems } from '@app/store/items/actions';
import { openModal } from '@app/store/modal/actions';
import type { CreateCollectionModalProps } from '@app/types/modal';
import InputField from '@component/InputField';
import TextareaField from '@component/TextareaField';
import { useAction } from '@hook/useAction';
import { track } from '@module/analytics';
import Monitor from '@module/Monitor';

export const CollectionModal = ({
  closeModal,
  config,
}: CreateCollectionModalProps) => {
  const action = useAction();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const url = config?.url;
  const files = config?.files;
  const itemIds = config?.itemIds;

  const collectionId = config?.collectionId;
  const collection = useSelector(getCollectionById({ id: collectionId || '' }));

  const [name, setName] = useState((collection && collection.name) || '');
  const [description, setDescription] = useState(
    (collection && collection.description) || ''
  );
  const nameInputRef = useRef(null);

  const handleSubmit = useCallback(
    async (event: React.SyntheticEvent) => {
      event.preventDefault();

      if (!validateName(name) || isSubmitting) {
        return;
      }

      setIsSubmitting(true);

      try {
        if (collection) {
          await dispatch(
            updateCollection(collection.id, name.trim(), description.trim())
          );
          closeModal();
        } else {
          const collection = await dispatch(
            createCollection(name.trim(), description.trim())
          );
          track('bucket_created');

          if (files || url) {
            const type = files
              ? MODAL_MOVE_TYPE.ADD_FILES
              : MODAL_MOVE_TYPE.ADD_LINK;
            dispatch(
              openModal({
                type: MODAL_TYPE.MOVE_ITEMS,
                config: {
                  type,
                  files,
                  url,
                },
              })
            );
            return;
          }

          if (itemIds) {
            await dispatch(
              moveItems({
                itemIds,
                toCollectionId: collection.id,
              })
            );
          }

          const path = generatePath(ROUTES.COLLECTION, {
            collectionId: collection.id,
          });

          closeModal({ redirectTo: path });
        }
      } catch (error) {
        setIsSubmitting(false);
        Monitor.error(error);
      }
    },
    [
      closeModal,
      collection,
      description,
      dispatch,
      files,
      isSubmitting,
      itemIds,
      name,
      url,
    ]
  );

  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length <= COLLECTION_TITLE_MAX_LENGTH) {
        setName(e.target.value);
      }
    },
    []
  );

  const handleDescChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setDescription(e.target.value);
    },
    []
  );

  const handleClose = useCallback(
    (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      e.preventDefault();
      closeModal();
    },
    [closeModal]
  );

  function validateName(name: string): boolean {
    return name.length > 0 && name.length <= COLLECTION_TITLE_MAX_LENGTH;
  }

  const formTitle = collection
    ? t('modal:create_new_board.title_update')
    : t('modal:create_new_board.title_new');
  const formButton = collection ? t('button:save') : t('button:create');

  return (
    <form onSubmit={handleSubmit}>
      <Dialog.Title className="ts-h3 mb-3 text-black dark:text-white">
        {formTitle}
      </Dialog.Title>
      <div className="mb-2">
        <InputField
          ref={nameInputRef}
          type="text"
          value={name}
          name="collection-name"
          onChange={handleNameChange}
          placeholder={t('modal:create_new_board.label.name')}
          data-test-id="input-collection-name"
        />
      </div>
      <div className="mb-2">
        <TextareaField
          value={description}
          id="collection-description"
          name="collection-description"
          onChange={handleDescChange}
          placeholder={t('modal:create_new_board.label.description')}
          data-test-id="input-collection-description"
          hasFloatingPlaceholder
        />
      </div>
      <div className="mt-3 flex justify-end align-center">
        <button
          className={classNames(action.secondary, 'ml-1')}
          type="button"
          onClick={handleClose}
          data-test-id="cta-cancel-collection"
        >
          {t('button:cancel')}
        </button>
        <button
          className={classNames(action.primary, 'ml-1')}
          type="submit"
          disabled={!validateName(name)}
          data-test-id="cta-submit-collection"
        >
          {formButton}
        </button>
      </div>
    </form>
  );
};
