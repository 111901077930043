import Config from '@app/config';

export function isValidUrl(url: string) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+()]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );
  return Boolean(pattern.test(url));
}

export function getRandomToken() {
  // E.g. 8 * 32 = 256 bits token
  const randomPool = new Uint8Array(32);
  crypto.getRandomValues(randomPool);

  const randomToken = randomPool.reduce(
    (hex, item) => `${hex}${item.toString(16)}`,
    ''
  );

  // E.g. db18458e2782b2b77e36769c569e263a53885a9944dd0a861e5064eac16f1a
  return randomToken;
}

export function getDeviceToken() {
  return Config.api.device_prefix + getRandomToken();
}

export function isElementInViewport(el: HTMLElement) {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function dragEventContainsFiles(event: DragEvent) {
  return (
    event.dataTransfer?.types !== undefined &&
    event.dataTransfer.types.includes('Files')
  );
}

export function dragEventContainsFolder(event: DragEvent): boolean {
  return Array.from(event.dataTransfer?.items || []).some(
    (item) => item.webkitGetAsEntry()?.isDirectory
  );
}
