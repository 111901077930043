import type config from '.';

export enum CONTENT_IDENTIFIERS {
  FILE = 'file',
  WEB = 'web_content',
}

export const COLLECTION_TITLE_MAX_LENGTH = 32;

export const UPLOAD_CHUNK_SIZE = 6 * 1024 * 1024;
export const UPLOAD_MAX_CHUNKS_PER_REQUEST = 50;

export const MAX_ITEMS_PER_PAGE = 20;
export const MAX_COLLECTIONS_PER_PAGE = 20;

export enum URL_SEARCH_PARAMS {
  fromAll = 'from-all',
  caption = 'caption',
}

export enum ROUTES {
  // PUBLIC ROUTES
  HOME = '/',
  LOGIN = '/login',
  LOGOUT = '/logout',
  SIGN_UP = '/sign-up',
  CLIPPER = '/clipper/callback',
  CALLBACK = '/callback',
  WELCOME_BACK = '/welcome-back',
  // PRIVATE ROUTES
  SORT_LATER = '/sort-later',
  COLLECTION = '/board/:collectionId',
  ITEM = '/board/:collectionId/:itemId',
  EMBED_COLLECTION = '/embed/:collectionId',
  EMBED_ITEM = '/embed/:collectionId/:itemId',
}

export enum COLLECTION_STATE {
  PROCESSING = 'processing',
  DOWNLOADABLE = 'downloadable',
}

export enum COLLECTION_TYPE {
  PUBLIC = 'public',
  SHARED = 'shared',
  COLLABORATIVE = 'collaborative',
  PRIVATE = 'private',
  UNSORTED = 'unsorted',
}

export enum MEMBERSHIP_ROLE {
  OWNER = 'owner',
  FOLLOWER = 'follower',
  EDITOR = 'editor',
}

export enum ITEM_UPLOAD_STATUS {
  NONE = 'none',
  QUEUED = 'queued',
  UPLOADING = 'uploading',
  UPLOADED = 'uploaded',
  FAILED = 'failed',
}

export enum ITEM_TYPE {
  WEB = 'web',
  FILE = 'file',
}

export type FILE_TYPE = keyof typeof config.supportedFiles | 'unsupported';

export enum ITEM_STATE {
  LOCAL = 'local',
  UPLOADING = 'uploading',
  UPLOADED = 'uploaded',
  DONE = 'done',
}

export enum COLLECTION_UPLOAD_STATUS {
  NONE = 'none',
  UPLOADING = 'uploading',
  FAILED = 'failed',
}

export enum SUBSCRIPTION_STATE {
  PENDING = 'pending',
  ACTIVE = 'active',
  NOTICE = 'notice_period',
}

// https://github.com/WeTransfer/spaceship/blob/cc29927e9c65ff245dff8fd83056ef8638db31dc/app/domains/subscription.rb#L27
export enum SUBSCRIPTION_TIER {
  FREE = 'free',
  PRO = 'pro',
  COLLECT_PRO = 'collect_pro',
  PRO_2022 = 'pro_2022',
  PREMIUM_2022 = 'premium_2022',
}

export enum SIDEBAR_STATUS {
  OPEN = 'open',
  CLOSED = 'closed',
}

export enum MODAL_TYPE {
  COLLECTION = 'collection',
  CAPTION = 'caption',
  ADD_LINK = 'add_link',
  MOVE_ITEMS = 'move_items',
  ACCOUNT = 'account',
  CONFIRM = 'confirm',
  ONBOARDING = 'onboarding',
  JOIN_BOARD = 'join_board',
  STORAGE_LIMIT = 'storage_limit',
  STORAGE_UPSELL = 'storage_upsell',
  SURVEY = 'survey',
  EMPTY = 'empty',
  WARNING = 'warning',
}

export enum MODAL_CONFIRM_TYPE {
  DELETE_ITEM = 'delete_item',
  DELETE_ITEMS = 'delete_items',
  DELETE_BOARD = 'delete_board',
  DELETE_CAPTION = 'delete_caption',
  LEAVE_BOARD = 'leave_board',
  LOGOUT = 'logout',
  LOGOUT_FORCED = 'logout_forced',
}

export enum MODAL_MOVE_TYPE {
  ADD_FILES = 'add_files',
  ADD_LINK = 'add_link',
  MOVE_ITEMS = 'move_items',
}

export const AVATAR_COLORS = {
  YELLOW: '#FFBE06',
  PURPLE: '#5B074B',
  RED: '#FF6E6E',
  GREEN: '#74D985',
};

export enum HTTP_STATUS_CODE {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  NOT_ALLOWED = 405,
  CONFLICT = 409,
  EXPECTATION_FAILED = 417,
  UNPROCESSABLE_ENTITY = 422,
  TOO_MANY_REQUESTS = 429,
}
