import classnames from 'classnames';
import type { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLLabelElement> & {
  htmlFor?: string;
  hasError?: boolean;
};

export const Label = ({ htmlFor, hasError, children, className }: Props) => {
  const labelClassName = classnames(
    'ts-l1 transition pointer-events-none peer-focus-within:text-blue dark:peer-focus-within:text-blue-50',
    className,
    {
      'text-gray-60 dark:text-gray-55': !hasError,
      'text-red-55 dark:text-red-50': hasError,
    }
  );

  return (
    <label className={labelClassName} htmlFor={htmlFor}>
      {children}
    </label>
  );
};

Label.displayName = 'Label';
