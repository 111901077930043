import { ITEM_TYPE } from '@app/config/constants';
import type {
  ITEM_STATE,
  FILE_TYPE,
  ITEM_UPLOAD_STATUS,
} from '@app/config/constants';

export type ItemMeta = {
  title: string;
  source?: string;
  image?: string;
  description?: string;
  provider_display?: string;
};

export type ItemOwner = {
  name: string;
  id: string;
};

export type ItemPreview = {
  name: string;
  url: string;
  width: number;
  height: number;
};

export type BaseItem = {
  id: string;
  collectionId: string;
  meta: ItemMeta;
  url: string;
  owner?: ItemOwner;
  order?: number;
  caption?: string;
  createdAt?: Date;
  uploadStatus: ITEM_UPLOAD_STATUS;
  uploadErrorCount?: number;
  preview?: ItemPreview;
};

export type FileItem = BaseItem & {
  size: number;
  state: ITEM_STATE;
  extension: string;
  type: ITEM_TYPE.FILE;
  fileType: FILE_TYPE;
  detailUrl?: string;
  downloadUrlExpiresAt?: Date | '';
  currentDetailUrlExpiresAt?: null | Date;
};

export type WebItem = BaseItem & {
  type: ITEM_TYPE.WEB;
};

export type Item = FileItem | WebItem;

export const isFileItem = (item: Item): item is FileItem =>
  item.type === ITEM_TYPE.FILE;
export const isWebItem = (item: Item): item is WebItem =>
  item.type === ITEM_TYPE.WEB;
