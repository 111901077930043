import type { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import type { FileItem, ItemPreview } from '@app/types/items';
import Animation from '@component/Animation';
import Icon from '@component/Icon';

type Props = {
  type: FileItem['fileType'];
  url?: FileItem['detailUrl'];
  onClick?: MouseEventHandler<HTMLImageElement>;
  preview?: ItemPreview;
};

export const ItemDetailContentFileItem = ({
  url,
  type,
  onClick,
  preview,
}: Props) => {
  const { t } = useTranslation();

  if (type === 'audio' && url !== undefined) {
    return (
      <div className="w-full flex items-center justify-center bg-purple-50 text-purple-70">
        <Icon type="Audio" className="fill-current scale-[2]" />
        <div className="absolute bottom-12" data-test-id="audio-object-preview">
          <audio controls>
            <source src={url} type="audio/mpeg" />
          </audio>
        </div>
      </div>
    );
  }

  // if (type === 'video' && url !== undefined) {
  //   return (
  //     <video
  //       className={classNames(styles.wrapper, styles.video)}
  //       controls
  //       poster={preview?.url}
  //     >
  //       <source src={url} type="video/mp4" />
  //     </video>
  //   );
  // }

  if (type === 'image' && url !== undefined) {
    return (
      <img
        src={url}
        onClick={onClick}
        alt="Item detail"
        className="object-contain"
        data-test-id="image-object"
      />
    );
  }

  if (type === 'document' && preview?.url !== undefined) {
    return (
      <img
        src={preview.url}
        onClick={onClick}
        alt="Item detail"
        className="object-contain"
        data-test-id="document-object"
      />
    );
  }

  return (
    <div className="w-full flex items-center justify-center flex-col mx-auto my-0 text-center">
      <Animation type="NoPreview" className="w-64 mb-10" />
      <h3 className="ts-l2 text-black dark:text-white m-0">
        {t('error_state.unsupported.title')}
      </h3>
    </div>
  );
};
