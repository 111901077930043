import wordmark from '@asset/images/wordmark.png';
import wordmark2x from '@asset/images/wordmark@2x.png';
import Animation from '@component/Animation';

const ClipperCallbackPage = () => (
  <div className="flex flex-col items-center justify-center h-screen p-8 text-center">
    <img
      src={wordmark}
      srcSet={`${wordmark2x} 2x`}
      alt="Collect and keep your items in one place"
      className="mb-6"
    />
    <Animation type="ClipperCallback" className="mb-6 w-32 h-32" />
    <p className="text-gray-80 dark:text-gray-30 m-0 ts-l2">
      You’re in! Get ready to save inspiration...
    </p>
  </div>
);

export default ClipperCallbackPage;
