import classNames from 'classnames';

import type { Membership } from '@app/types/collections';
import type { AvatarProps } from '@component/Avatar';
import Avatar from '@component/Avatar';

export type Props = {
  members?: Membership[];
  limit?: number;
  size?: AvatarProps['size'];
};

export const AvatarList = ({ members, limit = 0, size = 'large' }: Props) => {
  if (!members || (members && members.length === 0)) {
    return null;
  }

  const memberList = members.slice(0, limit > 0 ? limit : members.length);

  return (
    <div className="flex">
      {memberList.map((member, index) => (
        <span
          className={classNames(
            'rounded-full border-4 border-gray-20 dark:border-black',
            {
              '-ml-3': index !== 0,
            }
          )}
          key={member.id}
        >
          <Avatar id={member.id} name={member.name} size={size} />
        </span>
      ))}
      {members.length > limit && limit > 0 && (
        <span
          className={classNames(
            'rounded-full border-4 border-gray-20 dark:border-black -ml-3'
          )}
        >
          <Avatar name={`+${members.length - limit}`} size={size} isNumber />
        </span>
      )}
    </div>
  );
};
