import { createSelector } from 'reselect';

import type { RootState } from '@app/store';

/* State */

const modalState = (state: RootState) => state.modal;

/* Selectors */

export const getModal = createSelector([modalState], (modal) => modal);
