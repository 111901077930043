import { COLLECTION_UPLOAD_STATUS } from '@app/config/constants';
import Icon from '@component/Icon';

type Props = {
  uploadStatus: COLLECTION_UPLOAD_STATUS;
};

export const UploadStatusIcon = ({ uploadStatus }: Props) => {
  switch (uploadStatus) {
    case COLLECTION_UPLOAD_STATUS.UPLOADING:
      return (
        <Icon
          type="Uploading"
          className="w-[12px] h-[12px] fill-current animate-spin"
          data-test-id="uploading-icon"
        />
      );
    case COLLECTION_UPLOAD_STATUS.FAILED:
      return <Icon type="WarningUpload" className="fill-current scale-110" />;

    default:
      return null;
  }
};
