import classNames from 'classnames';
import { useCallback, useState } from 'react';
import type { ReactNode } from 'react';

import type { Item } from '@app/types/items';
import Icon from '@component/Icon';

type Props = {
  children: ReactNode;
  itemId: Item['id'];
  selected: boolean;
  onSelect?: (id: Item['id']) => void;
};

const GridItemSelect = ({ itemId, children, selected, onSelect }: Props) => {
  const [isActive, setIsActive] = useState(false);

  const handleSelect = useCallback(() => {
    if (onSelect !== undefined) {
      onSelect(itemId);
    }
  }, [onSelect, itemId]);

  const handleMouseOver = useCallback(() => {
    setIsActive(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    setIsActive(false);
  }, []);

  return (
    <>
      <div
        className="relative h-full overflow-hidden"
        onMouseEnter={onSelect !== undefined ? handleMouseOver : undefined}
        onMouseLeave={onSelect !== undefined ? handleMouseOut : undefined}
        data-test-id="grid-item"
      >
        {children}
        <div
          className={classNames(
            'absolute top-0 left-0 w-full h-full bg-blue bg-opacity-50 transition-opacity duration-150 rounded-xl',
            selected ? 'opacity-1' : 'opacity-0 pointer-events-none'
          )}
        ></div>
        {onSelect !== undefined && (
          <button
            className={classNames(
              'absolute top-0 right-0 p-2 transition-opacity duration-100',
              isActive || selected
                ? 'opacity-1'
                : 'opacity-0 pointer-events-none'
            )}
            onClick={handleSelect}
            data-test-id="grid-item-select-button"
          >
            <figure
              className={classNames(
                'flex w-6 h-6 border-white rounded-lg items-center justify-center transition-colors duration-100',
                selected
                  ? 'bg-blue'
                  : 'bg-black bg-opacity-10 border-white border-2'
              )}
            >
              <Icon
                type="Check"
                className={classNames(
                  'transition-opacity text-white fill-current',
                  selected ? 'opacity-1' : 'opacity-0'
                )}
              />
            </figure>
          </button>
        )}
      </div>
    </>
  );
};

export default GridItemSelect;
