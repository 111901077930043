import { isFuture } from 'date-fns';
import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

import type { FILE_TYPE } from '@app/config/constants';
import type { RootState } from '@app/store';
import { paramId, paramIds } from '@app/store/selectorParams';
import { isWebItem } from '@app/types/items';

/* State */

const itemsState = (state: RootState) => state.items;

/* Selectors */

const getItems = createSelector([itemsState], (items) => items);

export const getAllItems = createSelector([getItems], (items) =>
  sortBy(Object.values(items), ['createdAt']).reverse()
);

export const getAllItemIds = createSelector([getAllItems], (allItems) =>
  allItems.map(({ id }) => id)
);

export const getItemByItemId = createSelector([paramId], (id) =>
  createSelector([getItems], (items) =>
    id === undefined ? undefined : items[id]
  )
);

export const getItemsByItemIds = createSelector([paramIds], (ids) =>
  createSelector([getItems], (items) =>
    // We use a string to make sure that we don't return a new response on a new
    // array:
    //  getItemsByItemIds(state, { ids: [1,2,3]}) !== getItemsByItemIds(state, { ids: [1,2,3]})
    ids
      .split(',')
      .map((id) => items[id])
      // filter out any items that don't exist
      .filter((item) => item !== undefined)
  )
);

export const getDetailByItemId = createSelector([paramId], (id) =>
  createSelector(
    [getItemByItemId({ id })],
    (item): { type?: FILE_TYPE | 'web'; url?: string } => {
      if (item === undefined) {
        return {
          type: undefined,
          url: undefined,
        };
      }

      // Web item
      if (isWebItem(item)) {
        return {
          type: 'web',
          url: item.url,
        };
      }

      // From cache or local files
      if (
        item.detailUrl &&
        item.detailUrl !== '' &&
        (item.currentDetailUrlExpiresAt === null ||
          (item.currentDetailUrlExpiresAt !== undefined &&
            isFuture(item.currentDetailUrlExpiresAt)))
      ) {
        return {
          type: item.fileType,
          url: item.detailUrl,
        };
      }

      return {
        type: item.fileType,
        url: undefined,
      };
    }
  )
);

export const getPreviewByItemId = createSelector([paramId], (id) =>
  createSelector([getItemByItemId({ id })], (item) => item?.preview)
);

export const getCaptionByItemId = createSelector([paramId], (id) =>
  createSelector([getItemByItemId({ id })], (item) => item?.caption)
);

export const getItemIdsWithCaptionByItemIds = createSelector(
  [paramIds],
  (ids) =>
    createSelector([getItemsByItemIds({ ids: ids.split(',') })], (items) =>
      items
        .filter((item) => item.caption !== undefined && item.caption !== '')
        .map((item) => item.id)
    )
);

export const getUploadStatusByItemId = createSelector([paramId], (id) =>
  createSelector([getItemByItemId({ id })], (item) => item?.uploadStatus)
);

export const getPreviewDimensionsByItemIds = createSelector([paramIds], (ids) =>
  createSelector([getItemsByItemIds({ ids: ids.split(',') })], (items) =>
    items.map((item) => ({
      id: item.id,
      height: item.preview?.height,
      width: item.preview?.width,
    }))
  )
);
