import classNames from 'classnames';
import type { CSSProperties } from 'react';
import { useLayoutEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ItemMeta from '@app/components/Item/ItemMeta';
import type { AppDispatch } from '@app/store';
import { getItemByItemId } from '@app/store/items/selectors';
import { setDraggingItems } from '@app/store/ui/actions';
import type { Item as ItemType } from '@app/types/items';
import Item from '@component/Item';
import UploadStatus from '@component/UploadStatus';

import GridItemCaption from './GridItemCaption';
import GridItemLocation from './GridItemLocation';
import GridItemSelect from './GridItemSelect';

export type Props = {
  itemId: ItemType['id'];
  onSelect?: (id: ItemType['id']) => void;
  onDragStart?: (e: React.DragEvent<HTMLElement>) => void;
  selected: boolean;
  draggable?: boolean;
  gridLayout: {
    top: number;
    width: number;
    height: number;
    left: number;
  };
  showItemLocation?: boolean;
  showItemCaption?: boolean;
};

const GridItem = ({
  itemId,
  selected,
  showItemLocation = false,
  showItemCaption = false,
  onSelect,
  onDragStart,
  draggable,
  gridLayout,
}: Props) => {
  const { top, width, height, left } = gridLayout;
  const referenceElement = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();

  const item = useSelector(getItemByItemId({ id: itemId }));

  const [animationsEnabled, setAnimationsEnabled] = useState(false);
  const [imageLoadError, setImageLoadError] = useState<
    undefined | string | Event
  >();

  const imageUrl = useMemo(() => item?.preview?.url, [item]);
  const hasImage = useMemo(
    () => imageUrl !== undefined && imageLoadError === undefined,
    [imageUrl, imageLoadError]
  );

  const handleDragStart = useCallback(
    (e: React.DragEvent<HTMLElement>) => {
      if (onDragStart !== undefined) {
        onDragStart(e);
      }
      dispatch(setDraggingItems([itemId]));
    },
    [dispatch, onDragStart, itemId]
  );

  const handleDragEnd = useCallback(
    (e: React.DragEvent<HTMLElement>) => {
      dispatch(setDraggingItems([]));
    },
    [dispatch]
  );

  const itemStyles = useMemo<CSSProperties>(
    () => ({
      width: Math.floor(width),
      height: Math.floor(height),
      top: Math.floor(top),
      left: Math.floor(left),
    }),
    [top, left, height, width]
  );

  useLayoutEffect(() => {
    // Prevent the item from animating in to place on the initial render
    setTimeout(setAnimationsEnabled, 1, true);
  }, []);

  return (
    <div
      className={classNames('absolute top-0 left-0 flex flex-col', {
        'transition-all duration-300': animationsEnabled,
      })}
      ref={referenceElement}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      data-id={itemId}
      data-selected={selected}
      draggable={draggable}
      style={itemStyles}
    >
      <GridItemSelect itemId={itemId} selected={selected} onSelect={onSelect}>
        <div className="overflow-hidden h-full rounded-xl">
          <Item
            id={itemId}
            onImageLoadingError={setImageLoadError}
            hasImage={hasImage}
          >
            <ItemMeta
              itemId={itemId}
              hasImage={hasImage}
              className={classNames({
                'pb-0': showItemLocation,
              })}
            />
            {showItemLocation && (
              <GridItemLocation itemId={itemId} hasImage={hasImage} />
            )}
          </Item>
        </div>
      </GridItemSelect>
      <div className="absolute top-2 left-2 inline-grid grid-cols-2 gap-x-1.5">
        <UploadStatus itemId={itemId} />
        {showItemCaption && (
          <GridItemCaption itemId={itemId} elementRef={referenceElement} />
        )}
      </div>
    </div>
  );
};

export default GridItem;
