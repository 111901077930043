import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import Config from '@app/config';

const PROD = process.env.NODE_ENV === 'production';
const LOG_TO_CONSOLE = process.env.REACT_APP_MONITOR_LOG_TO_CONSOLE === 'true';
const SERVICE_NAME = 'collect-web';

class Monitor {
  private trackErrors: boolean;
  private logToConsole: boolean;

  constructor(config?: { trackErrors?: boolean; logToConsole?: boolean }) {
    this.trackErrors = config?.trackErrors ?? false;
    this.logToConsole = config?.logToConsole ?? false;

    if (this.trackErrors) {
      datadogLogs.init({
        clientToken: Config.monitor.logClientToken,
        site: Config.monitor.site,
        service: SERVICE_NAME,
        env: Config.monitor.env,
        sampleRate: Config.monitor.sampleRate,
      });
    }

    datadogRum.init({
      applicationId: Config.monitor.applicationId,
      clientToken: Config.monitor.rumClientToken,
      site: Config.monitor.site,
      service: SERVICE_NAME,
      env: Config.monitor.env,
      version: process.env.REACT_APP_GIT_REF ?? 'unknown',
      sampleRate: Config.monitor.sampleRate,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  }

  public error = (error: unknown | Error | string) => {
    if (typeof error === 'string') {
      error = new Error(error);
    }

    if (!(error instanceof Error)) {
      if (this.logToConsole) {
        console.error(error);
      }
      return;
    }

    if (this.trackErrors) {
      datadogLogs.logger.error(error.message, error);
    }

    if (this.logToConsole) {
      console.error(error);
    }
  };
}

const monitor = new Monitor({
  trackErrors: PROD,
  logToConsole: LOG_TO_CONSOLE,
});

export default monitor;
