interface OS {
  isAndroid: boolean;
  isIOS: boolean;
}

export function useOS(): OS {
  if (typeof navigator === 'undefined') {
    return { isAndroid: false, isIOS: false };
  }

  if (/Android/i.exec(navigator.userAgent)) {
    return { isAndroid: true, isIOS: false };
  }

  if (/iPhone|iPad|iPod/i.exec(navigator.userAgent)) {
    return { isAndroid: false, isIOS: true };
  }

  return { isAndroid: false, isIOS: false };
}
