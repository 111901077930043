import { useCallback, useMemo, useEffect } from 'react';
import { useKonami } from 'react-konami-code';
import { useDispatch, useSelector } from 'react-redux';

import type { AppDispatch, RootState } from '@app/store';
import { setUIMode } from '@app/store/ui/actions';
import { getUIMode } from '@app/store/ui/selectors';

export type UseUIMode = {
  isDark: boolean;
  isLight: boolean;
  toggle: () => void;
  set: (mode: RootState['ui']['mode']) => void;
};

export const useUIMode = (): UseUIMode => {
  const dispatch = useDispatch<AppDispatch>();
  const stateMode = useSelector(getUIMode);

  // Define UI mode state
  const isDark = useMemo(() => stateMode === 'dark', [stateMode]);
  const isLight = useMemo(() => stateMode === 'light', [stateMode]);

  // Create methods
  const toggle = useCallback(() => {
    dispatch(setUIMode(isDark ? 'light' : 'dark'));
  }, [isDark, dispatch]);

  const set = useCallback<UseUIMode['set']>(
    (mode) => {
      dispatch(setUIMode(mode));
    },
    [dispatch]
  );

  useKonami(toggle);

  // Control body classes
  useEffect(() => {
    if (isDark) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [isDark]);

  return {
    toggle,
    set,
    isDark,
    isLight,
  };
};
