import type { Membership } from '@app/types/collections';
import Avatar from '@component/Avatar';

type MemberProps = {
  member: {
    id: Membership['id'];
    name: Membership['name'];
    role?: Membership['role'];
  };
};

export const Member = ({ member }: MemberProps) => {
  const { id, name, role } = member;
  return (
    <div className="w-full py-2 inline-flex flex-row justify-start items-center font-normal">
      <Avatar id={id} name={name} size="medium" />
      <p className="ml-2 text-left">
        <span className="block ts-l2 text-black dark:text-white whitespace-nowrap overflow-hidden text-ellipsis max-w-xs">
          {name}
        </span>
        {role && (
          <span className="block ts-l6 text-gray-60 dark:text-gray-55 capitalize -mt-1">
            {role}
          </span>
        )}
      </p>
    </div>
  );
};
