import classNames from 'classnames';
import type { ReactNode } from 'react';
import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useMatch } from 'react-router-dom';

import { MODAL_MOVE_TYPE, MODAL_TYPE, ROUTES } from '@app/config/constants';
import { dragEventContainsFiles, dragEventContainsFolder } from '@app/helper';
import type { AppDispatch } from '@app/store';
import { getCollectionById } from '@app/store/collections/selectors';
import { addFileItems } from '@app/store/items/actions';
import { openModal } from '@app/store/modal/actions';
import { getIsFetchingInitialData } from '@app/store/pending/selectors';
import { getCanEditCollection } from '@app/store/selectors';
import { getSidebarClosed } from '@app/store/ui/selectors';
import type { Collection } from '@app/types/collections';
import Icon from '@component/Icon';
import { track } from '@module/analytics';

type Props = {
  children: ReactNode;
  collectionId?: Collection['id'];
  hasDropzoneTarget?: boolean;
  isEmpty?: boolean;
};

export const Dropzone = ({
  collectionId,
  hasDropzoneTarget = false,
  isEmpty,
  children,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const dropzoneRef = useRef<HTMLDivElement>(null);
  const matchedHomeRoute = useMatch(ROUTES.HOME);

  const isFetchingInitialData = useSelector(getIsFetchingInitialData);
  const collection = useSelector(getCollectionById({ id: collectionId ?? '' }));
  const canEditCollection = useSelector(
    getCanEditCollection({ id: collectionId ?? '' })
  );
  const sidebarClosed = useSelector(getSidebarClosed);

  const canAddContent = useMemo(
    () => canEditCollection || matchedHomeRoute !== null,
    [canEditCollection, matchedHomeRoute]
  );

  const handleDrop = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setVisible(false);

      if (isFetchingInitialData) {
        return;
      }

      if (!canAddContent) {
        return;
      }

      if (!e.dataTransfer) {
        return;
      }

      if (e.dataTransfer.files.length === 0) {
        return;
      }

      if (dragEventContainsFolder(e)) {
        dispatch(
          openModal({
            type: MODAL_TYPE.WARNING,
          })
        );
        return;
      }

      if (collection) {
        void dispatch(addFileItems(e.dataTransfer.files, collection.id));
      } else {
        dispatch(
          openModal({
            type: MODAL_TYPE.MOVE_ITEMS,
            config: {
              type: MODAL_MOVE_TYPE.ADD_FILES,
              files: e.dataTransfer.files,
            },
          })
        );
      }

      track('drag_drop_used');
    },
    [dispatch, canAddContent, collection, isFetchingInitialData]
  );

  const handleDragOver = useCallback(
    (event: DragEvent) => {
      event.preventDefault();

      if (isFetchingInitialData) {
        return;
      }

      if (!dragEventContainsFiles(event)) {
        return;
      }

      setVisible(true);
    },
    [isFetchingInitialData]
  );

  function handleDocumentMouseOut(event: MouseEvent) {
    event.preventDefault();
    setVisible(false);
  }

  function handleIncorrectDrop(event: DragEvent) {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    setVisible(false);
  }

  function handleDragEnd() {
    setVisible(false);
  }

  useEffect(() => {
    const dropzone = dropzoneRef.current;
    if (dropzone && canAddContent) {
      document.body.addEventListener('drop', handleIncorrectDrop, false);
      document.body.addEventListener('dragend', handleDragEnd, false);
      document.body.addEventListener('dragexit', handleDragEnd, false);
      dropzone.addEventListener('dragend', handleDragEnd, false);
      dropzone.addEventListener('dragexit', handleDragEnd, false);
      document.body.addEventListener('dragover', handleDragOver, false);
      dropzone.addEventListener('drop', handleDrop, false);
      document.addEventListener('mouseout', handleDocumentMouseOut, false);
    }

    return () => {
      if (dropzone) {
        document.body.removeEventListener('drop', handleIncorrectDrop);
        document.body.removeEventListener('dragend', handleDragEnd);
        document.body.removeEventListener('dragexit', handleDragEnd);
        dropzone.removeEventListener('dragend', handleDragEnd);
        dropzone.removeEventListener('dragexit', handleDragEnd);
        document.body.removeEventListener('dragover', handleDragOver);
        dropzone.removeEventListener('drop', handleDrop);
        document.removeEventListener('mouseout', handleDocumentMouseOut);
      }
    };
  }, [collection?.id, canAddContent, handleDragOver, handleDrop]);

  return (
    <div
      ref={dropzoneRef}
      className={classNames('relative flex-1', {
        'rounded-lg border-white dark:border-gray-90 border-2 mb-4': isEmpty,
      })}
      data-test-id="dropzone"
    >
      <div
        className={classNames('h-full', {
          'opacity-0 pointer-events-none': visible,
        })}
      >
        {children}
      </div>

      {visible && (
        <div
          className={classNames(
            'flex fixed top-0 left-0 right-0 bottom-0 mr-4 mb-4 z-dropzone bg-white dark:bg-gray-90 pointer-events-none rounded-lg items-center justify-center',
            sidebarClosed ? 'ml-4' : 'ml-[17rem]',
            isEmpty ? 'mt-32' : 'mt-14'
          )}
        >
          <div className="text-center">
            <Icon type="Dropzone" className="inline-block" />
            <p className="ts-l2 mt-6 w-56">
              {hasDropzoneTarget
                ? t('dropzone.target_with_collection')
                : t('dropzone.target_no_collection')}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
