import { sortBy, groupBy } from 'lodash';
import { useMemo, useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';

import { ITEM_UPLOAD_STATUS } from '@app/config/constants';
import type { AppDispatch } from '@app/store';
import { getAllCollectionIds } from '@app/store/collections/selectors';
import { addItems } from '@app/store/items/actions';
import { getAllItems, getAllItemIds } from '@app/store/items/selectors';
import { getIsFetchingInitialData } from '@app/store/pending/selectors';
import { getAllItemsCount } from '@app/store/selectors';
import { makeItem } from '@app/transformers/item';
import type { Item } from '@app/types/items';
import API from '@module/api';
import Monitor from '@module/Monitor';

type State = {
  isLoading: boolean;
  fetchedAll: boolean;
  batch: number;
  ids: Item['id'][];
  uploadingIds: Item['id'][];
};

export function useAllItems() {
  const dispatch = useDispatch<AppDispatch>();

  const [state, setState] = useState<State>({
    isLoading: false,
    fetchedAll: false,
    batch: 0,
    ids: [],
    uploadingIds: [],
  });

  const totalItemCount = useRef(0);
  const wasUploading = useRef(false);

  const collectionIds = useSelector(getAllCollectionIds);
  const allItems = useSelector(getAllItems);
  const allItemsCount = useSelector(getAllItemsCount);
  const isFetchingInitialData = useSelector(getIsFetchingInitialData);
  const allItemIds = useSelector(getAllItemIds);

  const uploadingItemIds = useMemo(
    () =>
      allItems
        .filter(
          (item) =>
            item.uploadStatus === ITEM_UPLOAD_STATUS.QUEUED ||
            item.uploadStatus === ITEM_UPLOAD_STATUS.UPLOADING
        )
        .map((item) => item.id),
    [allItems]
  );
  const itemIds = useMemo(() => [...state.uploadingIds, ...state.ids], [state]);
  const isUploadingNewItems = useMemo(
    () => uploadingItemIds.length > 0,
    [uploadingItemIds.length]
  );

  const fetchItems = useCallback(async () => {
    if (state.fetchedAll || state.isLoading) {
      return;
    }
    try {
      setState({ ...state, isLoading: true });
      const itemsFromApi = await API.items.fetchAllItems({
        batch: state.batch,
      });
      // Remove items that are not belonging to a collection
      const filteredItems = itemsFromApi.filter((item) =>
        collectionIds.includes(item.collection_id)
      );
      // Convert them to a { [collection_id]: item[] } format
      const itemsGroupedPerCollection = groupBy(filteredItems, 'collection_id');
      // Sort all by creation date (old first)
      const sortedItemsFromApi = sortBy(filteredItems, [
        'created_at',
      ]).reverse();

      // Add Items to Collections and Items slices
      dispatch(
        batchActions(
          Object.keys(itemsGroupedPerCollection).map((collectionId) =>
            addItems({
              collectionId,
              items: itemsGroupedPerCollection[collectionId]
                // Do not add items that are already in the store
                .filter((item) => !allItemIds.includes(item.id))
                // Transform each
                .map(makeItem),
            })
          )
        )
      );

      const ids = Array.from(
        new Set([...state.ids, ...sortedItemsFromApi.map((item) => item.id)])
      );

      setState({
        ...state,
        ids,
        uploadingIds: state.uploadingIds.filter((id) => !ids.includes(id)),
        batch: state.batch + 1,
        isLoading: false,
        fetchedAll: allItemsCount === ids.length,
      });
    } catch (error) {
      Monitor.error(error);
    }
  }, [dispatch, allItemIds, state, allItemsCount, collectionIds]);

  // Check for items that are only local until we fetch them from the server
  useEffect(() => {
    const newIds = uploadingItemIds.filter(
      (id) => !state.uploadingIds.includes(id)
    );

    if (newIds.length) {
      setState({ ...state, uploadingIds: [...newIds, ...state.uploadingIds] });
    }
  }, [uploadingItemIds, state]);

  // Check for board reset
  useEffect(() => {
    if (isFetchingInitialData) {
      return;
    }

    if (totalItemCount.current === 0 && allItemsCount > 0) {
      totalItemCount.current = allItemsCount;
      return;
    }

    if (
      !isUploadingNewItems &&
      (totalItemCount.current !== allItemsCount || wasUploading.current)
    ) {
      // Reset only on deletion
      if (!wasUploading.current || totalItemCount.current > allItemsCount) {
        setState((state) => ({
          ...state,
          batch: 0,
          ids: [],
          uploadingIds: [],
          fetchedAll: false,
        }));
      }

      totalItemCount.current = allItemsCount;
    }

    wasUploading.current = isUploadingNewItems;
  }, [isUploadingNewItems, isFetchingInitialData, allItemsCount]);

  return {
    isLoading: state.isLoading,
    isUploading: isUploadingNewItems,
    fetchedAll: state.fetchedAll,
    loadNext: fetchItems,
    itemIds,
  };
}
