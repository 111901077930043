import type { ReactNode } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { PreloaderAnimation } from './PreloaderAnimation';

export type Props = {
  // Rendered on success
  children: ReactNode;
  // Media url to be preloaded
  media?: string;
  // Error. Is passed the error
  onError?: OnErrorEventHandlerNonNull;
  // Success callback
  onLoad?: (event?: Event) => void;
  // Shown if the media fails to load
  fallback?: ReactNode;
};

export const Preloader = ({
  children,
  media,
  onError,
  onLoad,
  fallback = null,
}: Props) => {
  const [lastLoadedMedia, setLastLoadedMedia] =
    useState<Props['media']>(undefined);
  const [hasFailed, setHasFailed] = useState(false);
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const loadMedia = useCallback(
    (mediaFile?: string) => {
      setLastLoadedMedia(undefined);

      const handleLoad = (e?: Event) => {
        if (!mounted.current) {
          return;
        }

        if (onLoad !== undefined) {
          onLoad(e);
        }

        setLastLoadedMedia(mediaFile);
      };

      const handleError: OnErrorEventHandlerNonNull = (error) => {
        if (!mounted.current) {
          return;
        }

        if (onError !== undefined) {
          onError(error);
        }
        setLastLoadedMedia(mediaFile);
        setHasFailed(true);
      };

      if (!mediaFile) {
        return;
      }

      const media = new Image();
      media.addEventListener('load', handleLoad);
      media.addEventListener('error', handleError);
      media.src = mediaFile;
    },
    [onError, onLoad]
  );

  useEffect(() => {
    if (media !== lastLoadedMedia) {
      loadMedia(media);
    }
  }, [media, loadMedia, lastLoadedMedia]);

  // There is no media to load in the first place,
  // or we tried loading and failed
  if (media === undefined || hasFailed) {
    return <>{fallback}</>;
  }

  // We are loading the media
  if (lastLoadedMedia === undefined) {
    return <PreloaderAnimation />;
  }

  // Media successfully loaded
  return <>{children}</>;
};
