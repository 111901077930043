import { uiSlice } from './';

export const {
  setSidebarClosed,
  setIgnoreUnsavedChanges,
  setUIMode,
  hasSeenOnboarding,
  hasSeenSurvey,
  setDraggingItems,
} = uiSlice.actions;
