import { UPLOAD_CHUNK_SIZE } from '@app/config/constants';
import type { FilePart, FileUpload } from '@app/types/uploads';

export function makeFileUpload(file: File): Omit<FileUpload, 'id'> {
  const { size, name, type } = file;

  const chunkSize = size > UPLOAD_CHUNK_SIZE ? UPLOAD_CHUNK_SIZE : size;
  const numberOfChunks = Math.ceil(size / chunkSize);
  const parts: Record<number, FilePart> = {};
  for (let index = 0; index < numberOfChunks; index++) {
    const chunkStart = index * chunkSize;
    const chunkEnd = (index + 1) * chunkSize;
    const chunk = file.slice(chunkStart, chunkEnd);
    parts[index + 1] = {
      id: index + 1,
      url: '',
      complete: false,
      uploadExpires: '',
      chunk,
      progress: 0,
      total: 0,
    };
  }

  return {
    name,
    size,
    type,
    parts,
    preview: window.URL.createObjectURL(file),
    errorCount: 0,
  };
}
