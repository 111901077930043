import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import type { AppDispatch } from '@app/store';
import { login, logout, signUp } from '@app/store/user/actions';
import { track } from '@module/analytics';

export const useAuthLinks = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    i18n: { language },
  } = useTranslation();

  return {
    login: useCallback(async () => {
      track('login_tapped');
      await dispatch(login({ language }));
    }, [dispatch, language]),
    signUp: useCallback(async () => {
      track('signup_tapped');
      await dispatch(signUp({ language }));
    }, [dispatch, language]),
    logout: useCallback(async () => {
      track('logout_tapped');
      await dispatch(logout());
    }, [dispatch]),
  };
};
