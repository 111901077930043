import classNames from 'classnames';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getIsFetchingInitialData } from '@app/store/pending/selectors';
import { getAllOwnedCollectionsWithoutUnsorted } from '@app/store/selectors';
import type { Collection } from '@app/types/collections';
import ListItemCollectionButton from '@component/ListItemCollectionButton';
import ListItemSidebarCollectionNavLink from '@component/ListItemSidebarCollectionNavLink';

import { CollectionListLoading } from './CollectionListLoading';

type Props = {
  onCollectionClick?: (collectionId: Collection['id']) => void;
  currentCollectionId?: Collection['id'];
  search?: string;
};

export const CollectionList = ({
  onCollectionClick,
  currentCollectionId,
  search,
}: Props) => {
  const collections = useSelector(getAllOwnedCollectionsWithoutUnsorted);
  const isLoading = useSelector(getIsFetchingInitialData);

  const filteredCollections = useMemo(() => {
    return collections
      .filter((collection) => collection !== undefined)
      .filter(
        (collection) =>
          search === undefined ||
          collection.name
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase())
      )
      .filter(
        (collection) =>
          currentCollectionId === undefined ||
          collection.id !== currentCollectionId
      )
      .map((collection) => ({
        collection,
        handleClick: onCollectionClick
          ? () => onCollectionClick(collection.id)
          : undefined,
      }));
  }, [collections, search, currentCollectionId, onCollectionClick]);

  if (isLoading) {
    return <CollectionListLoading />;
  }

  if (!collections) {
    return null;
  }

  return (
    <ul className="list-none m-0 p-0" data-test-id="collection-list">
      {filteredCollections.map(({ collection, handleClick }, index, list) => (
        <li
          key={collection.id}
          className={classNames({
            'mb-0.5': index < list.length - 1,
          })}
        >
          {handleClick !== undefined ? (
            <ListItemCollectionButton
              collectionId={collection.id}
              onClick={handleClick}
            />
          ) : (
            <ListItemSidebarCollectionNavLink collectionId={collection.id} />
          )}
        </li>
      ))}
    </ul>
  );
};
