import type { TeamFromAPI } from '@app/transformers/user';

import { request } from '../helpers';
import type { APIInstances } from '../types';

const createSection = ({ api, publicApi }: APIInstances) => ({
  fetchAll: async () =>
    request<
      {
        teams: TeamFromAPI[];
      },
      TeamFromAPI[]
    >({
      call: api.get(`/v2/teams`),
      transform: (data) => data.teams,
    }),
});

export default createSection;
