import { Tooltip as ReachTooltip } from '@reach/tooltip';
import type { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  label: string | ReactNode;
  disabled?: boolean;
};

type PRect = Partial<DOMRect> & {
  readonly bottom: number;
  readonly height: number;
  readonly left: number;
  readonly right: number;
  readonly top: number;
  readonly width: number;
};

const centerTooltip = (
  triggerRect?: PRect | null,
  tooltipRect?: PRect | null
) => {
  if (!triggerRect || !tooltipRect) return { left: 0, top: 0 };
  const triggerCenter = triggerRect.left + triggerRect.width / 2;
  const left = triggerCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - 2;
  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.bottom + 8 + window.scrollY,
  };
};

export const Tooltip = ({ children, disabled = false, label }: Props) => {
  if (disabled) {
    return <>{children}</>;
  }
  return (
    <ReachTooltip
      position={centerTooltip}
      label={label}
      className="border-none bg-white dark:bg-gray-90 text-black dark:text-white absolute ts-l4 w-auto min-w-max p-2 rounded-md z-notification shadow text-center whitespace-nowrap"
    >
      {children}
    </ReachTooltip>
  );
};
