const colors = require('tailwindcss/colors');

module.exports = {
  content: ['./src/**/*.{js,jsx,ts,tsx}', './public/**/*.html'],
  darkMode: 'class',
  theme: {
    colors: {
      // Simple Colors
      transparent: 'transparent',
      current: 'currentColor',
      black: '#17181A',
      white: colors.white,

      // Colors
      blue: {
        DEFAULT: '#5268FF',
        20: '#D0E2FF',
        30: '#B5CBFF',
        40: '#96ACFF',
        50: '#7688FF',
        60: '#5268FF',
        70: '#3741D9',
        80: '#2122AC',
      },
      gray: {
        DEFAULT: '#A9ABAE',
        20: '#F4F5F5',
        30: '#E9EBED',
        40: '#DCDEE0',
        50: '#C2C5C7',
        55: '#A9ABAE',
        60: '#76797C',
        70: '#5C5E61',
        80: '#434548',
        90: '#2A2B2E',
      },
      yellow: {
        DEFAULT: '#F9C346',
        50: '#F9C346',
        70: '#B17D14',
        90: '#5B3B04',
      },
      green: {
        DEFAULT: '#50C476',
        55: '#50C476',
        80: '#147245',
        90: '#03502C',
      },
      purple: {
        DEFAULT: '#5C2BCF',
        50: '#A08DED',
        70: '#5C2BCF',
        80: '#5115AA',
        90: '#41097A',
      },
      red: {
        DEFAULT: '#E14343',
        40: '#FDA9A9',
        50: '#FA8585',
        55: '#F36464',
        60: '#E14343',
        90: '#771111',
      },
      orange: {
        DEFAULT: '#FF7217',
        40: '#FFA86F',
        60: '#FF7217',
        65: '#F56000',
        70: '#DD5700',
        80: '#96320B',
      },
    },
    fontWeight: {
      normal: 400,
      bold: 700,
    },
    fontSize: {
      base: ['17px', '22px'],
    },
    screens: {
      sm: '600px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
    },
    extend: {
      fontFamily: {
        sans: "'Actief Grotesque','-apple-system','.SFNSText-Regular','San Francisco','Roboto','Segoe UI','Helvetica Neue','Lucida Grande','sans-serif'",
      },
      transformOrigin: {
        0: '0%',
      },
      backgroundImage: (theme) => ({
        'checkmark-orange': "url('/src/assets/images/checkmark-orange.svg')",
      }),
      keyframes: {
        marquee: {
          '0%': { left: '0%', transform: 'translateX(-100%)' },
          '100%': { left: '100%', transform: 'translateX(0%)' },
        },
      },
      animation: {
        marquee: 'marquee 1.5s ease-in-out infinite',
      },
      gridRowEnd: {
        'span-10': 'span 10',
        'span-15': 'span 15',
        'span-17': 'span 17',
        'span-20': 'span 20',
      },
      maxHeight: {
        '1/2vh': '50vh',
      },
    },
    zIndex: {
      auto: 'auto',
      toolbar: 200,
      'minimize-sidebar': 881,
      popover: 900,
      'arrow-navigation': 910,
      modal: 915,
      notification: 920,
      dropzone: 930,
      'small-screen-prompt': 940,
    },
    boxShadow: {
      sm: '0px 1.15px 3.25px rgba(0, 0, 0, 0.15)',
      DEFAULT: '0px 2px 4px 1px rgb(0 0 0 / 15%)',
    },
    dropShadow: {
      DEFAULT: [
        '0px -4px 34px rgba(0, 0, 0, 0.1)',
        '0px 12px 34px rgba(0, 0, 0, 0.1)',
      ],
    },
  },
  plugins: [],
};
