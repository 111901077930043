import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import { useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { MODAL_CONFIRM_TYPE, ROUTES } from '@app/config/constants';
import type { AppDispatch } from '@app/store';
import {
  deleteCollection,
  leaveCollection,
} from '@app/store/collections/actions';
import { deleteItems, removeCaption } from '@app/store/items/actions';
import { setIgnoreUnsavedChanges } from '@app/store/ui/actions';
import type { ConfirmModalProps } from '@app/types/modal';
import { useAction } from '@hook/useAction';
import { useAuthLinks } from '@hook/useAuthLinks';
import { track } from '@module/analytics';
import { push } from '@module/ReduxRouter';

export const ConfirmModal = ({ closeModal, config }: ConfirmModalProps) => {
  const action = useAction();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { logout } = useAuthLinks();

  const type = config.type;
  const collectionId = config.collectionId;
  const itemId = config.itemId;
  const itemIds = config.itemIds;
  const canDismissModal = !config?.preventDismiss;

  useEffect(() => {
    if (!type) {
      dispatch(push(ROUTES.HOME));
    }
  });

  const modal = useMemo(() => {
    switch (type) {
      case MODAL_CONFIRM_TYPE.LOGOUT:
        return {
          title: t('modal:confirm.title.logout'),
          description: t('modal:confirm.description.logout'),
          confirm: t('modal:confirm.button.logout'),
          handleClick: () => {
            dispatch(setIgnoreUnsavedChanges(true));
            void logout();
          },
        };
      case MODAL_CONFIRM_TYPE.LOGOUT_FORCED:
        return {
          title: t('modal:confirm.title.logout_forced'),
          description: t('modal:confirm.description.logout_forced'),
          confirm: t('modal:confirm.button.logout_forced'),
          handleClick: () => {
            dispatch(setIgnoreUnsavedChanges(true));
            void logout();
          },
        };
      case MODAL_CONFIRM_TYPE.DELETE_BOARD:
        return {
          title: t('modal:confirm.title.delete_board'),
          description: t('modal:confirm.description.delete_board'),
          confirm: t('modal:confirm.button.delete_board'),
          handleClick: () => {
            if (!collectionId) {
              return;
            }
            void dispatch(deleteCollection(collectionId));
          },
        };
      case MODAL_CONFIRM_TYPE.LEAVE_BOARD:
        return {
          title: t('modal:confirm.title.leave_board'),
          description: t('modal:confirm.description.leave_board'),
          confirm: t('modal:confirm.button.leave_board'),
          handleClick: () => {
            if (!collectionId) {
              return;
            }
            void dispatch(leaveCollection(collectionId));
          },
        };
      case MODAL_CONFIRM_TYPE.DELETE_ITEM:
        return {
          title: t('modal:confirm.title.delete_item'),
          description: t('modal:confirm.description.delete_item'),
          confirm: t('modal:confirm.button.delete_item'),
          handleClick: () => {
            if (!itemIds) {
              return;
            }
            void dispatch(deleteItems(itemIds));
          },
        };
      case MODAL_CONFIRM_TYPE.DELETE_ITEMS:
        return {
          title: t('modal:confirm.title.delete_items'),
          description: t('modal:confirm.description.delete_items'),
          confirm: t('modal:confirm.button.delete_items'),
          handleClick: () => {
            if (!itemIds) {
              return;
            }
            void dispatch(deleteItems(itemIds));
          },
        };
      case MODAL_CONFIRM_TYPE.DELETE_CAPTION:
        return {
          title: t('modal:confirm.title.delete_caption'),
          description: t('modal:confirm.description.delete_caption'),
          confirm: t('modal:confirm.button.delete_caption'),
          handleClick: () => {
            if (!itemId) {
              return;
            }
            track('caption_delete_tapped');
            void dispatch(removeCaption(itemId));
            track('caption_deleted');
          },
        };
      default:
        return {
          title: '',
          description: '',
          confirm: '',
          handleClick: () => null,
        };
    }
  }, [collectionId, dispatch, itemId, itemIds, logout, t, type]);

  const handleOnClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      modal.handleClick();

      closeModal();
    },
    [closeModal, modal]
  );

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      closeModal();
    },
    [closeModal]
  );

  return (
    <>
      <Dialog.Title
        className="ts-h3 mb-1 text-black dark:text-white"
        data-test-id="modal-confirm-title"
      >
        {modal.title}
      </Dialog.Title>
      <Dialog.Description
        className="ts-b2 mb-4 dark:text-white"
        data-test-id="modal-confirm-description"
      >
        {modal.description}
      </Dialog.Description>
      <div className="flex justify-end">
        {canDismissModal && (
          <button
            type="button"
            className={classNames(action.tertiary)}
            onClick={handleClose}
            data-test-id="modal-confirm-cancel-button"
          >
            {t('button:cancel')}
          </button>
        )}
        <button
          type="button"
          className={classNames(action.critical)}
          onClick={handleOnClick}
          data-test-id="cta-confirm-dangerous-action"
        >
          {modal.confirm}
        </button>
      </div>
    </>
  );
};
