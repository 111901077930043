import type {
  UserFromAPI,
  UserSubscriptionFromAPI,
} from '@app/transformers/user';

import { request } from '../helpers';
import type { APIInstances } from '../types';

const createSection = ({ api, publicApi }: APIInstances) => ({
  logout: async () =>
    request({
      call: api.patch(`/v2/user/sign_out`),
    }),

  fetch: async () =>
    request<UserFromAPI>({
      call: api.get(`/v2/user`),
    }),

  fetchUsage: async () =>
    request<
      {
        bytes_used: number;
        bytes_limit: number;
        bytes_graced: number;
      },
      {
        bytesUsed: number;
        bytesLimit: number;
        bytesGraced: number;
      }
    >({
      call: api.get(`/v2/user/usage`),
      transform: (data) => ({
        bytesUsed: data.bytes_used,
        bytesLimit: data.bytes_limit,
        bytesGraced: data.bytes_graced,
      }),
    }),

  unlockProPass: async () =>
    request<
      {
        success: boolean;
        message?: string;
        user_info?: { subscription: UserSubscriptionFromAPI };
      },
      UserSubscriptionFromAPI
    >({
      call: api.post(`/v2/subscriptions/unlock_pro_pass`),
      assert: ({ data, status }) => status === 202 && data.success,
      transform: (data) => {
        if (data.user_info?.subscription === undefined) {
          throw new Error('User subscription not found');
        }
        return data.user_info.subscription;
      },
    }),
});

export default createSection;
