import { flatten } from 'lodash';

import {
  COLLECTION_TYPE,
  MAX_COLLECTIONS_PER_PAGE,
} from '@app/config/constants';
import dynamicLinkConfig from '@app/config/dynamicLink';
import type { CollectionFromAPI } from '@app/transformers/collection';
import type { Collection, Membership } from '@app/types/collections';
import type { User } from '@app/types/user';

import { request } from '../helpers';
import type { APIInstances, DiffOperations } from '../types';

const createSection = ({ api, publicApi }: APIInstances) => ({
  fetchSingle: async ({
    collectionId,
    isPublic = false,
  }: {
    collectionId: Collection['id'];
    isPublic?: boolean;
  }) =>
    isPublic
      ? request<CollectionFromAPI>({
          call: publicApi.get(`/v2/web/collections/${collectionId}/public`),
        })
      : request<CollectionFromAPI>({
          call: api.get(`/v2/mobile/collections/${collectionId}`),
        }),

  fetchAll: async () => {
    const getCollectionsBatch = async (offset: number) =>
      request<
        {
          total_collections: number;
          collections: CollectionFromAPI[];
        },
        { total: number; collections: CollectionFromAPI[] }
      >({
        call: api.get(`/v2/collections`, { params: { offset } }),
        transform: ({ total_collections: total, collections }) => ({
          total,
          collections,
        }),
      });

    const { total, collections } = await getCollectionsBatch(0);
    const collectionBatches = await Promise.all([
      collections,
      ...Array.from({
        length: Math.ceil(total / MAX_COLLECTIONS_PER_PAGE) - 1,
      }).map((_, index) =>
        request<
          {
            collections: CollectionFromAPI[];
          },
          CollectionFromAPI[]
        >({
          call: api.get(`/v2/collections`, {
            params: {
              offset: MAX_COLLECTIONS_PER_PAGE * (index + 1),
            },
          }),
          transform: (data) => data.collections,
        })
      ),
    ]);

    return flatten(collectionBatches);
  },

  fetchDiff: async ({
    collectionId,
    collectionVersion,
  }: {
    collectionId: Collection['id'];
    collectionVersion: Collection['version'];
  }) =>
    request<
      {
        operation_version: Collection['version'];
        state: Collection['state'];
        operations: DiffOperations;
      },
      {
        version: Collection['version'];
        state: Collection['state'];
        operations: DiffOperations;
      }
    >({
      call: api.get(
        `/v1/collections/${collectionId}/operations/${collectionVersion}/diff`
      ),
      transform: (data) => ({
        version: data.operation_version,
        state: data.state,
        operations: data.operations,
      }),
    }),

  save: async ({
    name,
    description,
    type = COLLECTION_TYPE.SHARED,
  }: {
    name: Collection['name'];
    description: Collection['description'];
    type: COLLECTION_TYPE;
  }) =>
    request<CollectionFromAPI>({
      call: api.post(`/v2/collections`, {
        name,
        description,
        collection_type: type,
      }),
      assert: ({ data }) => data.id !== undefined,
    }),

  update: async ({
    collectionId,
    name,
    description,
  }: {
    collectionId: Collection['id'];
    name: Collection['name'];
    description?: Collection['description'];
  }) =>
    request<CollectionFromAPI>({
      call: api.patch(`/v1/collections/${collectionId}`, {
        name,
        description,
      }),
    }),

  updateType: async ({
    collectionId,
    type,
  }: {
    collectionId: Collection['id'];
    type: COLLECTION_TYPE;
  }) =>
    request<CollectionFromAPI>({
      call: api.patch(`/v2/collections/${collectionId}`, {
        collection_type: type,
      }),
    }),

  leave: async ({ collectionId }: { collectionId: Collection['id'] }) =>
    request({
      call: api.delete(`/v1/collections/${collectionId}/leave`),
    }),

  join: async ({
    collectionId,
    name,
    token,
  }: {
    collectionId: Collection['id'];
    name?: User['name'];
    token?: string;
  }) =>
    request<CollectionFromAPI>({
      call:
        token !== undefined && name !== undefined
          ? api.post(`/v1/collections/${collectionId}/accept`, {
              collection_id: collectionId,
              collaboration_token: token,
              name,
            })
          : api.post(`/v1/collections/${collectionId}/follow`, {
              collection_id: collectionId,
            }),
    }),

  remove: async ({ collectionId }: { collectionId: Collection['id'] }) =>
    request({
      call: api.delete(`/v1/collections/${collectionId}`),
    }),

  fetchMemberships: async ({
    collectionId,
  }: {
    collectionId: Collection['id'];
  }) =>
    request<Membership[]>({
      call: api.get(`/v2/mobile/collections/${collectionId}/memberships`),
    }),

  fetchDynamicLink: async ({
    collectionId,
    url,
  }: {
    collectionId: Collection['id'];
    url: string;
  }) =>
    request<{ dynamic_link: string }, string>({
      call: publicApi.post(`/v2/web/dynamic_link/public`, {
        ...dynamicLinkConfig,
        link: url,
        collection_id: collectionId,
      }),
      transform: ({ dynamic_link }) => dynamic_link,
    }),
});

export default createSection;
