import Popover from 'components/Popover';
import { useCallback } from 'react';
import type { RefObject } from 'react';
import { useSelector } from 'react-redux';

import { getCollectionById } from '@app/store/collections/selectors';
import {
  getItemByItemId,
  getCaptionByItemId,
} from '@app/store/items/selectors';
import type { Item as ItemType } from '@app/types/items';
import Icon from '@component/Icon';
import ItemCaptionContent from '@component/ItemCaptionContent';
import { track } from '@module/analytics';

type Props = {
  itemId: ItemType['id'];
  elementRef?: RefObject<HTMLElement>;
};

const GridItemCaption = ({ itemId, elementRef }: Props) => {
  const caption = useSelector(getCaptionByItemId({ id: itemId }));
  const item = useSelector(getItemByItemId({ id: itemId }));
  const collection = useSelector(getCollectionById({ id: item?.collectionId }));

  const handleViewCaption = useCallback(() => {
    track('view_caption_tapped', {
      location: 'grid',
    });
  }, []);

  return caption ? (
    <div
      className="text-blue"
      data-test-id="griditem-caption-content-popover-button"
    >
      <Popover
        elementRef={elementRef}
        trigger={<Icon type="CaptionFilled" onClick={handleViewCaption} />}
        viewportHandling="preferScroll"
      >
        <div className="w-72" data-test-id="griditem-caption-content-popover">
          <ItemCaptionContent
            itemId={itemId}
            collectionId={collection?.id}
            isSummary
          />
        </div>
      </Popover>
    </div>
  ) : null;
};

export default GridItemCaption;
