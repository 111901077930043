import classNames from 'classnames';
import type { ForwardedRef } from 'react';
import { useCallback } from 'react';
import type { NavLinkProps } from 'react-router-dom';
import { generatePath, NavLink } from 'react-router-dom';

import { ROUTES } from '@app/config/constants';
import type { Collection } from '@app/types/collections';
import ListItemCollectionContent from '@component/ListItemCollectionContent';
import { useCollectionDropTarget } from '@hook/useCollectionDropTarget';

type ListItemSidebarCollectionNavLinkProps = Omit<
  NavLinkProps,
  'to' | 'children'
> & {
  collectionId: Collection['id'];
};

export const ListItemSidebarCollectionNavLink = ({
  collectionId,
  ...props
}: ListItemSidebarCollectionNavLinkProps) => {
  const path = generatePath(ROUTES.COLLECTION, {
    collectionId,
  });

  const dropHandler = useCollectionDropTarget<HTMLElement>({
    collectionId,
  });

  const propsClassName: string =
    typeof props.className === 'string' ? props.className : '';

  const createClassName = useCallback(
    ({ isActive }: { isActive: boolean }) =>
      classNames(
        'w-full rounded-xl block hover:bg-white transition-colors dark:hover:bg-gray-90 focus:outline-none focus-visible:ring-2 ring-inset focus-visible:ring-blue',
        propsClassName,
        {
          'bg-white dark:bg-gray-90':
            dropHandler.isDropTargetActive || isActive,
        }
      ),
    [dropHandler.isDropTargetActive, propsClassName]
  );

  return (
    <NavLink
      {...props}
      to={path}
      ref={dropHandler.ref as ForwardedRef<HTMLAnchorElement>}
      className={createClassName}
    >
      <ListItemCollectionContent
        collectionId={collectionId}
        disabled={dropHandler.isDropTargetDisabled}
      />
    </NavLink>
  );
};
