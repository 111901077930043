import { useEffect, useState } from 'react';

import Monitor from '@app/modules/Monitor';
import type { Collection } from '@app/types/collections';
import { useOS } from '@hook/useOs';
import { useSearchParams } from '@hook/useSearchParams';
import API from '@module/api';

export function useAppLink({
  collectionId,
}: {
  collectionId?: Collection['id'];
}) {
  const { isAndroid, isIOS } = useOS();
  const [url, setUrl] = useState<undefined | string>();
  const {
    params: { token },
  } = useSearchParams();

  useEffect(() => {
    if (
      collectionId === undefined ||
      // Don't fetch the URL if we're not on a Android or IOS device
      (!isAndroid && !isIOS)
    ) {
      return;
    }

    API.collections
      .fetchDynamicLink({
        url: `${window.location.origin}/board/${collectionId}/latest${
          token ? `?token=${token}` : ''
        }`,
        collectionId,
      })
      .then(setUrl)
      .catch(Monitor.error);
  }, [token, setUrl, collectionId, isAndroid, isIOS]);

  return { url };
}
