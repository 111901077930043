export enum NOTIFICATION_TYPE {
  ERROR = 'error',
  ERROR_MESSAGE_NOT_ALLOWED_TO_ADD_ITEMS = 'item_rejected_not_owner_editor',
  ERROR_MESSAGE_NOT_ALL_ITEMS_ARE_SYNCED = 'item_rejected_not_all_items_are_synced',
  ERROR_MESSAGE_ITEMS_NOT_UPLOADED = 'items_not_uploaded_error',
  INFO_VIEW_ONLY_BOARD = 'view_only_board',
  SUCCESS_MESSAGE_LOG_IN_COMPLETE = 'log_in_success',
  SUCCESS_MESSAGE_BOARD_DELETED = 'board_deleted_success',
  SUCCESS_MESSAGE_BOARD_LEFT = 'board_leave_success',
  SUCCESS_MESSAGE_CAPTION_DELETED = 'caption_deleted_success',
  SUCCESS_MESSAGE_ITEM_INIT_DOWNLOAD = 'item_init_download_success',
  SUCCESS_MESSAGE_ITEM_DELETED = 'item_deleted_success',
  SUCCESS_MESSAGE_ITEM_ADDED = 'item_added_success',
  SUCCESS_MESSAGE_ITEM_MOVED = 'item_moved_success',
}
