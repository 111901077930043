import type { CSSProperties } from 'react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '@app/components/Loader';
import { MODAL_TYPE } from '@app/config/constants';
import type { AppDispatch } from '@app/store';
import { openModal } from '@app/store/modal/actions';
import { getIsFetchingInitialData } from '@app/store/pending/selectors';
import { getShouldShowSurvey } from '@app/store/selectors';
import StorageAlert from '@component/Alert/Storage';
import CollectionToolbar from '@component/CollectionToolbar';
import Dropzone from '@component/Dropzone';
import EmptyState from '@component/EmptyState';
import Grid from '@component/Grid';
import PopoverAddContent from '@component/PopoverAddContent';
import { useAllItems } from '@hook/useAllItems';
import { useSelectedItems } from '@hook/useSelectedItems';

const INFINITE_SCROLL_OFFSET = 300;

const AllItemsPage = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView();
  const allItems = useAllItems();

  const dispatch = useDispatch<AppDispatch>();
  const isFetchingInitialData = useSelector(getIsFetchingInitialData);
  const shouldShowSurvey = useSelector(getShouldShowSurvey);
  const { selectedItems, selectItem, clearSelection } = useSelectedItems(
    allItems.itemIds
  );

  const isLoadingInitialData = useMemo(
    () =>
      isFetchingInitialData ||
      (allItems.isLoading && allItems.itemIds.length === 0),
    [isFetchingInitialData, allItems.isLoading, allItems.itemIds.length]
  );

  const inViewElementStyle: CSSProperties = useMemo(
    () => ({
      bottom: allItems.itemIds.length === 0 ? 0 : INFINITE_SCROLL_OFFSET,
    }),
    [allItems.itemIds.length]
  );

  const isEmpty = useMemo(
    () => allItems.itemIds?.length === 0 && !isLoadingInitialData,
    [allItems, isLoadingInitialData]
  );

  // Scroll to top on reset
  useEffect(() => {
    if (!allItems.fetchedAll) {
      document.documentElement.scrollTop = 0;
    }
  }, [allItems.fetchedAll]);

  // Decide if we should show the Survey modal
  useEffect(() => {
    if (shouldShowSurvey) {
      dispatch(
        openModal({
          type: MODAL_TYPE.SURVEY,
        })
      );
    }
  }, [shouldShowSurvey, dispatch]);

  // Handle fetching new items
  useEffect(() => {
    if (isFetchingInitialData || !inView || allItems.fetchedAll) {
      return;
    }
    void allItems.loadNext();
  }, [isFetchingInitialData, inView, allItems]);

  return (
    <div className="grow px-4 flex flex-col">
      <CollectionToolbar
        title={t('title.all_items')}
        description={t('description.all_items')}
        selectedItems={selectedItems}
        onClearSelection={clearSelection}
      >
        <span className="h-7">
          <PopoverAddContent hasCreateCollectionOption isAllItems />
        </span>
      </CollectionToolbar>
      <StorageAlert className="mt-0 mx-4 mb-4" />
      <Dropzone isEmpty={isEmpty}>
        {isEmpty ? (
          <EmptyState />
        ) : (
          <Grid
            isLoading={isLoadingInitialData}
            itemIds={allItems.itemIds}
            selectedItems={selectedItems}
            onSelect={selectItem}
            showItemLocation
            showItemCaption
          />
        )}
        {allItems.isLoading && (
          <Loader
            className="w-8 mt-2 mb-7 mx-auto"
            hasColor
            type={Loader.type.DOTS}
          />
        )}
        {!allItems.isLoading && !allItems.fetchedAll && (
          <>
            <div
              className="h-1 w-1 absolute"
              ref={ref}
              style={inViewElementStyle}
            />
            <button
              className="mt-4 mb-6 py-2 mx-auto ts-l5 text-gray-60"
              onClick={allItems.loadNext}
            >
              {t('label.show_more')}
            </button>
          </>
        )}
      </Dropzone>
    </div>
  );
};

export default AllItemsPage;
