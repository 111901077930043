import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsFetchingInitialData } from '@app/store/pending/selectors';
import { getHasFreeSpace, getUserCanUpgrade } from '@app/store/user/selectors';

import { Alert } from '../Alert';

export type Props = {
  className?: string;
};

export const Storage = ({ className }: Props) => {
  const { t } = useTranslation();
  const userCanUpgrade = useSelector(getUserCanUpgrade);
  const isFetchingInitialData = useSelector(getIsFetchingInitialData);
  const userHasSpaceLeft = useSelector(getHasFreeSpace);

  if (userHasSpaceLeft || isFetchingInitialData) {
    return null;
  }

  return (
    <Alert variant="danger" className={className}>
      {userCanUpgrade ? (
        <Trans i18nKey="storage.alert.free">
          You’re out of storage. Delete some items that don’t spark joy, or{' '}
          <a
            href={t('upsell.pro.button-link')}
            target="_blank"
            rel="noopener noreferrer"
            title=""
            className="text-red-60 dark:text-red-55 underline ml-1"
          >
            upgrade to WeTransfer Premium
          </a>
          .
        </Trans>
      ) : (
        <Trans i18nKey="storage.alert.pro">
          You’re out of storage. Delete some items that don’t spark joy, or
          contact Support to keep adding items.
        </Trans>
      )}
    </Alert>
  );
};
