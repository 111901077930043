import classnames from 'classnames';
import type { ReactNode } from 'react';
import { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

import visual1 from '@asset/images/onboarding/Screen1.png';
import visual12x from '@asset/images/onboarding/Screen1@2x.png';
import visual2 from '@asset/images/onboarding/Screen2.png';
import visual22x from '@asset/images/onboarding/Screen2@2x.png';
import visual3 from '@asset/images/onboarding/Screen3.png';
import visual32x from '@asset/images/onboarding/Screen3@2x.png';
import visual4 from '@asset/images/onboarding/Screen4.png';
import visual42x from '@asset/images/onboarding/Screen4@2x.png';
import Icon from '@component/Icon';
import { useAction } from '@hook/useAction';

import { OnboardingPage } from './OnboardingPage';

import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';

export interface Props {
  onClose: () => void;
}

export const Onboarding = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const action = useAction();
  const [slider, setSlider] = useState<Slider>();
  const [currentPage, setCurrentPage] = useState(0);

  const numSlides = useMemo(
    () => ((slider?.props?.children as ReactNode[]) ?? []).length,
    [slider]
  );
  const isLastPage = useMemo(
    () => currentPage === numSlides - 1,
    [currentPage, numSlides]
  );

  const goToPrevPage = useCallback(() => {
    slider?.slickPrev();
  }, [slider]);
  const goToNextPage = useCallback(() => {
    slider?.slickNext();
  }, [slider]);
  const updateSliderRef = useCallback((slider: Slider) => {
    if (!slider) {
      return;
    }
    setSlider(slider);
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    beforeChange: (oldIndex: number, newIndex: number) =>
      setCurrentPage(newIndex),
    dotsClass: 'dots flex p-1 items-center justify-center',
    appendDots: function appendDots(dots: ReactNode) {
      return (
        <div>
          <button
            className={classnames('mr-auto', action.icon)}
            onClick={goToPrevPage}
            disabled={currentPage === 0}
          >
            <Icon type="Arrow" className="rotate-180" />
          </button>
          <ul className="flex">{dots}</ul>
          <button
            className={classnames('ml-auto', action.icon)}
            onClick={goToNextPage}
            disabled={isLastPage}
          >
            <Icon type="Arrow" />
          </button>
        </div>
      );
    },
    customPaging: function customPaging(i: number) {
      return (
        <button className="block w-2 h-2 bg-gray-40 p-0 mx-[3px] rounded-[3px] transition-colors hover:bg-blue active:bg-blue-70"></button>
      );
    },
  };

  return (
    <div
      className={classnames(
        'slider relative w-full bg-white dark:bg-black dark:text-white transition-colors'
      )}
    >
      <div className="flex justify-center pt-8 pb-3">
        <Icon
          type="Wordmark"
          className="text-black dark:text-white fill-current"
        />
      </div>
      <div className="relative w-full">
        <Slider {...settings} ref={updateSliderRef}>
          <OnboardingPage visual={visual1} visual2x={visual12x}>
            <h3 className="ts-h1 p-0 m-0 mb-2">
              {t('onboarding.page_1.title')}
            </h3>
            <p className="ts-b2 p-0 m-0">
              {t('onboarding.page_1.description')}
            </p>
          </OnboardingPage>
          <OnboardingPage visual={visual2} visual2x={visual22x}>
            <h3 className="ts-h1 p-0 m-0 mb-2">
              {t('onboarding.page_2.title')}
            </h3>
            <p className="ts-b2 p-0 m-0">
              {t('onboarding.page_2.description')}
            </p>
          </OnboardingPage>
          <OnboardingPage visual={visual3} visual2x={visual32x}>
            <h3 className="ts-h1 p-0 m-0 mb-2">
              {t('onboarding.page_3.title')}
            </h3>
            <p className="ts-b2 p-0 m-0">
              {t('onboarding.page_3.description')}
            </p>
          </OnboardingPage>
          <OnboardingPage visual={visual4} visual2x={visual42x}>
            <h3 className="ts-h1 p-0 m-0 mb-2">
              {t('onboarding.page_4.title')}
            </h3>
            <p className="ts-b2 p-0 m-0">
              {t('onboarding.page_4.description')}
            </p>
            <div className="mt-5 -mb-2">
              <a
                href="https://chrome.google.com/webstore/detail/save-to-collect/flblmacldekcjcoockeoggionmhflecp"
                title={t('button:download_now')}
                target="_blank"
                rel="noopener noreferrer"
                className={classnames(
                  'mx-2 lg:mr-4 lg:ml-0 lg:mb-3',
                  action.primary
                )}
              >
                {t('button:download_now')}
              </a>
            </div>
          </OnboardingPage>
        </Slider>
      </div>
      <button
        className={classnames('absolute top-0 right-0 m-1', action.icon)}
        onClick={onClose}
        data-test-id="modal-close"
      >
        <Icon type="Close" />
      </button>
    </div>
  );
};
