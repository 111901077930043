import { useEffect, useRef, useState } from 'react';

export function useFieldState<
  T extends
    | HTMLInputElement
    | HTMLTextAreaElement
    | HTMLSelectElement = HTMLInputElement,
>() {
  const fieldRef = useRef<T>(null);

  const [isTouched, setIsTouched] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    const field = fieldRef.current;

    if (
      !(field instanceof HTMLInputElement) &&
      !(field instanceof HTMLTextAreaElement) &&
      !(field instanceof HTMLSelectElement)
    ) {
      return;
    }

    const onTouched = () => {
      setIsTouched(true);
    };
    const onFocus = () => {
      setIsFocused(true);
    };
    const onBlur = () => {
      setIsFocused(false);
    };
    const onChange = () => {
      setIsChanged(true);
    };

    field.addEventListener('mousedown', onTouched);
    field.addEventListener('touchstart', onTouched);
    field.addEventListener('focus', onFocus);
    field.addEventListener('blur', onBlur);
    field.addEventListener('change', onChange);

    return () => {
      field.removeEventListener('mousedown', onTouched);
      field.removeEventListener('touchstart', onTouched);
      field.removeEventListener('focus', onFocus);
      field.removeEventListener('blur', onBlur);
      field.removeEventListener('change', onChange);
    };
  }, [fieldRef]);

  return {
    ref: fieldRef,
    isTouched,
    isFocused,
    isChanged,
  };
}
