import classnames from 'classnames';
import type { MouseEvent } from 'react';
import { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MODAL_TYPE, ROUTES } from '@app/config/constants';
import { openModal } from '@app/store/modal/actions';
import { setSidebarClosed } from '@app/store/ui/actions';
import { getIsDraggingItems, getSidebarClosed } from '@app/store/ui/selectors';
import {
  getUserCanUpgrade,
  getUserTeams,
  getUserUnsortedId,
} from '@app/store/user/selectors';
import CollectionList from '@component/CollectionList';
import Icon from '@component/Icon';
import ListItemSidebar from '@component/ListItemSidebar';
import ListItemSidebarNavLink from '@component/ListItemSidebarNavLink';
import Tooltip from '@component/Tooltip';
import UploadStatus from '@component/UploadStatus';
import { useAction } from '@hook/useAction';
import { useCollectionDropTarget } from '@hook/useCollectionDropTarget';

import SidebarAccountOverview from './SidebarAccountOverview';
import SidebarSearch from './SidebarSearch';

export const Sidebar = () => {
  const action = useAction();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const isSidebarClosed = useSelector(getSidebarClosed);
  const isDraggingItems = useSelector(getIsDraggingItems);
  const userCanUpgrade = useSelector(getUserCanUpgrade);
  const userTeams = useSelector(getUserTeams);
  const unsortedId = useSelector(getUserUnsortedId);

  const [searchActive, setSearchActive] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const dropHandler = useCollectionDropTarget<HTMLElement>({
    collectionId: unsortedId,
  });

  const handleSearchClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      setSearchActive(!searchActive);
      setSearchInput('');
    },
    [searchActive]
  );

  const handleSearchInput = useCallback((input: string) => {
    setSearchInput(input);
  }, []);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      dispatch(setSidebarClosed(!isSidebarClosed));
    },
    [dispatch, isSidebarClosed]
  );

  const handleCreateCollectionClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      dispatch(openModal({ type: MODAL_TYPE.COLLECTION }));
    },
    [dispatch]
  );

  const tooltipLabel = isSidebarClosed ? t('sidebar.show') : t('sidebar.hide');

  const shouldShowUpsell = useMemo(
    () => userCanUpgrade && userTeams.length === 0,
    [userCanUpgrade, userTeams]
  );

  return (
    <>
      <div
        className={classnames(
          'sticky top-0 grow-0 shrink-0 h-screen bg-gray-20 dark:bg-black transition-all duration-300',
          {
            'w-0 overflow-hidden': isSidebarClosed,
            'w-64': !isSidebarClosed,
          }
        )}
      >
        <div className="w-64 h-full flex flex-col p-2">
          <SidebarAccountOverview />
          <ListItemSidebarNavLink
            to={ROUTES.HOME}
            icon="AllItems"
            className="mt-5 mb-1"
            disabled={isDraggingItems}
          >
            {t('button:all_items')}
          </ListItemSidebarNavLink>
          <ListItemSidebarNavLink
            to={ROUTES.SORT_LATER}
            icon="SortLater"
            className={classnames('mb-5', {
              'bg-white dark:bg-gray-90': dropHandler.isDropTargetActive,
            })}
            ref={dropHandler.ref}
            disabled={dropHandler.isDropTargetDisabled}
            rightIcon={<UploadStatus collectionId={unsortedId} />}
          >
            {t('button:sort_later')}
          </ListItemSidebarNavLink>
          <div className="flex flex-row items-center h-8">
            <span className="text-gray-60 dark:text-gray-55 ts-l6 uppercase ml-2 mr-auto">
              {t('label.boards')}
            </span>
            <button
              onClick={handleSearchClick}
              className={classnames(action.icon)}
            >
              {searchActive ? (
                <Icon type="CloseSmall" />
              ) : (
                <Icon
                  type="Search"
                  className="w-3 h-3"
                  data-test-id="search-button"
                />
              )}
            </button>
          </div>
          <div className="w-full h-9 overflow-hidden relative mb-1">
            <ListItemSidebar
              onClick={handleCreateCollectionClick}
              className={classnames({
                'h-full opacity-0 transition-opacity': searchActive,
              })}
              data-test-id="cta-create-collection"
              icon="Create"
              iconClassName="text-orange w-4 h-4 px-2"
              size="large"
              disabled={searchActive}
            >
              {t('button:create_new_board')}
            </ListItemSidebar>
            <div
              className={classnames(
                'absolute left-0 w-full h-full transition-transform top-0',
                {
                  'transform -translate-y-0 delay-150': searchActive,
                  'transform -translate-y-full': !searchActive,
                }
              )}
            >
              <SidebarSearch
                onSearch={handleSearchInput}
                isSearchActive={searchActive}
              />
            </div>
          </div>
          <div className="flex-1 overflow-hidden">
            <div
              className="w-full h-full overflow-x-hidden overflow-y-auto"
              tabIndex={-1}
            >
              <CollectionList search={searchInput} />
            </div>
          </div>
          {shouldShowUpsell && (
            <a
              href={t('upsell.pro.button-link')}
              target="_blank"
              rel="noopener noreferrer"
              className="rounded-xl ts-l2 py-2 px-3 h-10 mt-2 cursor-pointer flex justify-start items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-blue transition-colors text-white dark:text-black bg-black dark:bg-white hover:bg-gray-90 dark:hover:bg-gray-30"
            >
              <Icon type="We" className="block mr-2 fill-current h-3" />
              <span>{t('button:upsell_wetransfer_premium')}</span>
            </a>
          )}
        </div>
      </div>
      <div
        className={classnames(
          'fixed top-2 h-12 px-2 transition-all transform duration-300 flex items-center z-minimize-sidebar',
          {
            'left-64 -translate-x-full': !isSidebarClosed,
            'left-2': isSidebarClosed,
          }
        )}
      >
        <Tooltip label={tooltipLabel}>
          <button
            onClick={handleClick}
            data-test-id="sidebar-hide-unhide-button"
            className="rounded fill-current text-gray-60 dark:text-gray-55 hover:bg-white dark:hover:bg-black active:text-black dark:active:text-white"
          >
            <Icon
              type="Minimize"
              className={classnames(
                'transform transition-transform duration-300',
                {
                  'rotate-0': !isSidebarClosed,
                  'rotate-180': isSidebarClosed,
                }
              )}
            />
          </button>
        </Tooltip>
      </div>
    </>
  );
};
