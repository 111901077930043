import { Popover as HeadlessPopover } from '@headlessui/react';
import classNames from 'classnames';
import type { RefObject, ReactNode } from 'react';
import { useRef, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';

import { isElementInViewport } from '@app/helper';

export type Props = {
  children: ReactNode;
  trigger: ReactNode;
  className?: string;
  buttonClassName?: string;
  disabled?: boolean;
  defaultOpen?: boolean;
  elementRef?: RefObject<HTMLElement>;
  viewportHandling?: 'preferScroll' | 'preferReposition';
};

export const Popover = ({
  children,
  trigger,
  className,
  buttonClassName,
  disabled,
  defaultOpen,
  elementRef,
  viewportHandling = 'preferReposition',
}: Props) => {
  const hasScrolledIntoView = useRef(false);
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(
    elementRef?.current || referenceElement,
    popperElement,
    {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 5],
          },
        },
        {
          name: 'arrow',
          options: {
            element: arrowElement,
            padding: 15,
          },
        },
        {
          name: 'flip',
          enabled: viewportHandling === 'preferReposition',
        },
      ],
    }
  );

  const placement = (attributes?.popper || {})['data-popper-placement'] ?? '';

  const arrowClassNames = classNames('w-0 h-0', {
    'top-0': placement.includes('bottom'),
    'left-0': placement.includes('right'),
    'bottom-0': placement.includes('top'),
    'right-0': placement.includes('left'),
  });

  useEffect(() => {
    if (referenceElement === null) {
      return;
    }

    if (defaultOpen) {
      referenceElement.click();
    }
  }, [referenceElement, defaultOpen]);

  return (
    <HeadlessPopover className={classNames(className, 'relative inline-block')}>
      {({ open }) => {
        if (viewportHandling === 'preferScroll') {
          if (open && popperElement && !hasScrolledIntoView.current) {
            hasScrolledIntoView.current = true;
            setTimeout(() => {
              if (isElementInViewport(popperElement)) {
                return;
              }
              popperElement.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
              });
            });
          } else if (!open && hasScrolledIntoView.current) {
            hasScrolledIntoView.current = false;
          }
        }
        return (
          <>
            <HeadlessPopover.Button
              ref={setReferenceElement}
              disabled={disabled}
              className={classNames(
                buttonClassName,
                'focus:outline-none focus-visible:ring-2 ring-inset focus-visible:ring-blue'
              )}
            >
              {trigger}
            </HeadlessPopover.Button>

            <HeadlessPopover.Panel
              className="absolute z-popover rounded-2xl w-52 min-w-min bg-white dark:bg-gray-90 drop-shadow p-2 bg-clip-padding border-8 border-transparent text-black dark:text-white"
              style={styles.popper}
              {...attributes.popper}
              ref={setPopperElement}
              data-test-id="popover-panel"
            >
              <div
                style={styles.arrow}
                {...attributes.arrow}
                ref={setArrowElement}
                className={arrowClassNames}
              >
                <div className="w-3 h-3 transform rotate-45 -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-gray-90"></div>
              </div>
              {children}
            </HeadlessPopover.Panel>
          </>
        );
      }}
    </HeadlessPopover>
  );
};
