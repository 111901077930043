import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import config from '@app/config';
import { MODAL_CONFIRM_TYPE, MODAL_TYPE } from '@app/config/constants';
import { openModal } from '@app/store/modal/actions';
import {
  getUser,
  getUsage,
  getUserCanUpgrade,
} from '@app/store/user/selectors';
import Avatar from '@component/Avatar';
import ListItem from '@component/ListItem';
import ListItemLink from '@component/ListItemLink';
import Popover from '@component/Popover';
import StorageStatus from '@component/StorageStatus';

const SidebarAccountOverview = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector(getUser);
  const userStorageUsage = useSelector(getUsage);
  const userCanUpgrade = useSelector(getUserCanUpgrade);

  const accountUrl = useMemo(
    () =>
      `${config.centralAccountManagement.url}?returnUrl=${escape(
        window.location.origin
      )}&app=collect`,
    []
  );

  const handleLogoutClick = useCallback(() => {
    dispatch(
      openModal({
        type: MODAL_TYPE.CONFIRM,
        config: {
          type: MODAL_CONFIRM_TYPE.LOGOUT,
        },
      })
    );
  }, [dispatch]);

  return (
    <div className="pr-8" data-test-id="sidebar-account-overview-button">
      <Popover
        trigger={
          <>
            <Avatar id={user.id} size="medium" name={user.name || user.email} />
            <p
              className="ts-l2 ml-2 text-black dark:text-white whitespace-nowrap overflow-hidden text-ellipsis"
              data-test-id="sidebar-account-overview-userid"
            >
              {user.name || user.email}
            </p>
          </>
        }
        className="w-full"
        buttonClassName="w-full p-2 flex flex-row items-center justify-start rounded-xl hover:bg-white transition-colors dark:hover:bg-gray-90 focus:outline-none focus-visible:ring-2 ring-inset focus-visible:ring-blue"
      >
        <div className="w-52" data-test-id="sidebar-account-overview-popover">
          <div className="w-full p-2">
            <StorageStatus
              userCanUpgrade={userCanUpgrade}
              {...userStorageUsage}
            />
          </div>
          <hr className="-ml-2 -mr-2 mt-1 mb-2 border-t-2 border-b-0 border-gray-30 dark:border-black" />
          <ListItemLink href={accountUrl}>{t('button:account')}</ListItemLink>
          <ListItemLink href="https://collect.bywetransfer.com">
            {t('button:about')}
          </ListItemLink>
          <ListItemLink href="https://wetransfer.zendesk.com/hc/en-us/requests/new?ticket_form_id=243603">
            {t('button:feedback')}
          </ListItemLink>
          <ListItemLink href="https://wetransfer.zendesk.com/hc/en-us/sections/360000255366-Our-Collect-apps">
            {t('button:help')}
          </ListItemLink>
          <ListItem onClick={handleLogoutClick}>{t('button:logout')}</ListItem>
        </div>
      </Popover>
    </div>
  );
};

export default SidebarAccountOverview;
