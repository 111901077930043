import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import type { Collection } from '@app/types/collections';
import { useAction } from '@hook/useAction';
import useAppLink from '@hook/useAppLink';

type Props = {
  collectionId?: Collection['id'];
};

const OpenInApp = ({ collectionId }: Props) => {
  const action = useAction();
  const { t } = useTranslation();

  const { url } = useAppLink({ collectionId });

  if (!url) {
    return null;
  }

  return (
    <a
      role="button"
      href={url}
      title={t('button:use_the_app')}
      className={classNames(
        action.primary,
        'fixed bottom-0 left-1/2 -translate-x-1/2 mb-8 shadow'
      )}
    >
      {t('button:use_the_app')}
    </a>
  );
};

export default OpenInApp;
