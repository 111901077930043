import { Trans, useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { ROUTES } from '@app/config/constants';
import { Animation } from '@component/Animation/Animation';
import PublicCollectionHeader from '@component/PublicCollectionHeader';
import { useSearchParams } from '@hook/useSearchParams';

const WelcomeBackPage = () => {
  const {
    params: { name },
  } = useSearchParams();
  const { t } = useTranslation();

  return (
    <div className="flex h-screen flex-col">
      <PublicCollectionHeader />
      <div className="flex  flex-1 flex-col justify-center items-center">
        <Animation
          type="WelcomeBack"
          className="w-[370px] h-[300px] pointer-events-none"
        />
        <h1 className="ts-h1 mt-9">
          {name !== undefined
            ? t('welcome_back.title', { name })
            : t('welcome_back.title_anonymous', 'Welcome back')}
        </h1>
        <Trans i18nKey="welcome_back.body" parent="p" className="ts-b1 mt-3">
          To continue, please{' '}
          <a
            className="text-black underline"
            href={generatePath(ROUTES.HOME)}
            title="Log in"
            data-test-id="login-button"
          >
            log in
          </a>
          .
        </Trans>
      </div>
    </div>
  );
};

export default WelcomeBackPage;
