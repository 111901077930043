import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { NotificationState } from './types';

const initialState: NotificationState[] = [];

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<NotificationState>) => {
      state.push(action.payload);
    },
    resetNotification: (
      state,
      action: PayloadAction<NotificationState['id']>
    ) => {
      return state.filter((notification) => notification.id !== action.payload);
    },
  },
});

export default notificationSlice.reducer;
