import { Dialog } from '@headlessui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { StorageLimitModalProps } from '@app/types/modal';
import { useAction } from '@hook/useAction';

export const StorageLimit = ({ closeModal }: StorageLimitModalProps) => {
  const action = useAction();
  const { t } = useTranslation();

  const handleClick = useCallback(() => closeModal(), [closeModal]);

  return (
    <>
      <Dialog.Title className="ts-h3 mb-3 text-black dark:text-white">
        {t('storage.prompt.title.pro')}
      </Dialog.Title>
      <Dialog.Description className="ts-b2 mb-4 dark:text-white">
        {t('storage.prompt.description.pro')}
      </Dialog.Description>
      <div className="flex justify-end">
        <button
          type="button"
          className={action.secondary}
          onClick={handleClick}
        >
          {t('button:ok')}
        </button>
      </div>
    </>
  );
};
