import classNames from 'classnames';
import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { ROUTES } from '@app/config/constants';
import type { Item } from '@app/types/items';
import Icon from '@component/Icon';
import ItemDetailLoader from '@component/ItemDetail/ItemDetailLoader';
import { useToolbarAction } from '@hook/useToolbarAction';

import ItemDetailArrowNavigation from '../ItemDetailArrowNavigation';

type Props = {
  item?: Item;
  nextItem?: Item;
  previousItem?: Item;
  closeUrl: string;
  isFetchingInitialData?: boolean;
};

const EmbeddedItemDetail = ({
  item,
  nextItem,
  previousItem,
  closeUrl,
  isFetchingInitialData,
}: Props) => {
  const toolbarAction = useToolbarAction();
  const navigate = useNavigate();

  const goToNextPage = useCallback(
    () =>
      nextItem !== undefined &&
      navigate(
        generatePath(ROUTES.EMBED_ITEM, {
          collectionId: nextItem.collectionId,
          itemId: nextItem.id,
        })
      ),
    [navigate, nextItem]
  );

  const goToPrevPage = useCallback(
    () =>
      previousItem !== undefined &&
      navigate(
        generatePath(ROUTES.EMBED_ITEM, {
          collectionId: previousItem.collectionId,
          itemId: previousItem.id,
        })
      ),
    [navigate, previousItem]
  );

  const goToBoardView = useCallback(
    () => navigate(closeUrl),
    [navigate, closeUrl]
  );

  return (
    <div className="relative w-full h-screen overflow-hidden flex flex-col">
      <div className="shrink-0 grow-0 w-full pl-4 py-4 pr-24 text-black dark:text-white">
        <h1 className="w-full block whitespace-nowrap overflow-hidden text-ellipsis ts-h4 !leading-7">
          {item?.meta.title}
        </h1>
      </div>
      <ItemDetailArrowNavigation
        hasNextItem={nextItem !== undefined}
        hasPrevItem={previousItem !== undefined}
        onNextClick={goToNextPage}
        onPrevClick={goToPrevPage}
        onCancel={goToBoardView}
      />
      <div className="flex-1 h-0">
        <ItemDetailLoader
          item={item}
          isLoading={isFetchingInitialData}
          isPublic
        />
      </div>
      <button
        onClick={goToBoardView}
        className={classNames(toolbarAction, 'w-7 fixed top-4 right-12')}
      >
        <Icon type="Close" className="h-4" />
      </button>
    </div>
  );
};

export default EmbeddedItemDetail;
