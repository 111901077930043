import { createSelector } from 'reselect';

import type { RootState } from '@app/store';

/* State */

const pendingState = (state: RootState) => state.pending;

/* Selectors */

export const getPending = createSelector([pendingState], (pending) => pending);
export const getIsFetchingInitialData = createSelector(
  [getPending],
  (pending) => Boolean(pending.fetchInitialData)
);
export const getIsDownloadingItems = createSelector([getPending], (pending) =>
  Boolean(pending.downloadItem)
);
