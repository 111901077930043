import type { AxiosProgressEvent } from 'axios';
import { pickBy, mapValues } from 'lodash';

import {
  ITEM_UPLOAD_STATUS,
  MODAL_TYPE,
  HTTP_STATUS_CODE,
} from '@app/config/constants';
import type { AppThunk } from '@app/store';
import { updateItem, deleteLocalItems } from '@app/store/items/actions';
import { openModal } from '@app/store/modal/actions';
import {
  setNotification,
  setUploadSuccessNotification,
  setUploadErrorNotification,
} from '@app/store/notification/actions';
import { setPending, resetPending } from '@app/store/pending/actions';
import { uploaderSlice } from '@app/store/uploader';
import { fetchUsage } from '@app/store/user/actions';
import type { Collection } from '@app/types/collections';
import type { Item } from '@app/types/items';
import type { FilePart, FileUpload } from '@app/types/uploads';
import { NOTIFICATION_TYPE } from '@component/Notification/types';
import API, { isApiError } from '@module/api';

import { getSortedItemIdsByCollectionId } from '../collections/selectors';
import { getItemByItemId, getUploadStatusByItemId } from '../items/selectors';
import { getUserCanUpgrade } from '../user/selectors';

export const {
  queueFiles,
  deleteUpload,
  updateFile,
  updateFileParts,
  resetUploadPart,
  setUploadProgress,
  setUploadComplete,
} = uploaderSlice.actions;

export function startUpload(): AppThunk {
  return (dispatch, getState) => {
    const { pending } = getState();

    if (pending.syncer) {
      return;
    }

    dispatch(uploadNextItem());
  };
}

const MAX_UPLOAD_RETRIES = 2;
const pendingId = 'syncer';

function uploadNextItem(): AppThunk {
  return (dispatch, getState) => {
    const nextFile = dispatch(getNextItemInQueue());
    if (nextFile) {
      dispatch(setPending(pendingId));
      void dispatch(uploadFileItem(nextFile));
    } else {
      const {
        items,
        uploader: { files },
      } = getState();

      const errorFiles = mapValues(
        pickBy(
          files,
          (value, key) =>
            getUploadStatusByItemId({ id: key })(getState()) ===
            ITEM_UPLOAD_STATUS.FAILED
        ),
        (value: FileUpload) => ({ ...value, errorCount: 0 })
      );

      if (Object.values(errorFiles).length > 0) {
        dispatch(setUploadErrorNotification(errorFiles));
      } else if (Object.keys(files).length > 0) {
        const collectionIds = Object.keys(files)
          .map((itemId) => items[itemId]?.collectionId)
          .filter((id) => id);
        dispatch(
          setUploadSuccessNotification(collectionIds[0], Object.keys(files))
        );
      }

      dispatch(setUploadComplete());
      dispatch(resetPending(pendingId));
    }
  };
}

function uploadFileItem(file: FileUpload): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      const uploadInfo = await API.upload.fetchUploadInfo({
        fileId: file.id,
        numberOfParts: Object.keys(file.parts).length,
      });

      // Set item upload state
      dispatch(
        updateItem({
          itemId: file.id,
          item: {
            uploadStatus: ITEM_UPLOAD_STATUS.UPLOADING,
          },
        })
      );

      dispatch(
        updateFileParts({
          id: file.id,
          expiresAt: uploadInfo.uploadExpiryDate,
          parts: uploadInfo.parts,
        })
      );
      await dispatch(uploadFileParts(file.id));
    } catch (error) {
      if (isApiError(error) && error.status === HTTP_STATUS_CODE.FORBIDDEN) {
        const state = getState();
        const userCanUpgrade = getUserCanUpgrade(state);
        if (isStorageLimitError(error)) {
          dispatch(
            openModal({
              type: userCanUpgrade
                ? MODAL_TYPE.STORAGE_UPSELL
                : MODAL_TYPE.STORAGE_LIMIT,
            })
          );
          dispatch(deleteLocalItems([file.id]));
        } else {
          dispatch(uploadError(error, file.id));
        }

        dispatch(uploadNextItem());
        return;
      }

      dispatch(uploadError(error, file.id));
      dispatch(uploadNextItem());
    }
  };
}

function uploadFileParts(fileId: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      uploader: { files },
    } = getState();
    const file = files[fileId];

    if (!file) {
      deleteUpload(fileId);
      return;
    }

    const fileParts = Object.values(file.parts).filter(
      (part) => part.complete === false
    );

    if (fileParts.length === 0) {
      dispatch(uploadNextItem());
      return;
    }

    for (const part of Object.values(fileParts)) {
      try {
        await API.upload.uploadFilePart({
          ...part,
          onProgress: (progress) => {
            dispatch(uploadProgress(fileId, part.id, progress));
          },
        });
      } catch (error) {
        void dispatch(resetFileParts(fileId, [part.id]));
        return;
      }
    }

    const state = getState();
    const {
      uploader: { files: updateFiles },
    } = state;
    const completedFile = updateFiles[fileId];

    if (!completedFile) {
      dispatch(uploadNextItem());
      return;
    }

    API.upload
      .completeFileUpload({ fileId })
      .then(() => {
        dispatch(
          updateItem({
            itemId: fileId,
            item: {
              id: fileId,
              uploadStatus: ITEM_UPLOAD_STATUS.UPLOADED,
            },
          })
        );
        void dispatch(fetchUsage());
        dispatch(uploadNextItem());
      })
      .catch((error) => {
        if (isApiError(error)) {
          switch (error.status) {
            case HTTP_STATUS_CODE.BAD_REQUEST: {
              const missingParts = (
                error.body as { payload?: { missing_parts?: FilePart['id'][] } }
              )?.payload?.missing_parts;
              if (missingParts !== undefined) {
                void dispatch(resetFileParts(fileId, missingParts));
              }
              break;
            }
            case HTTP_STATUS_CODE.FORBIDDEN:
              if (isStorageLimitError(error)) {
                const userCanUpgrade = getUserCanUpgrade(state);
                dispatch(
                  openModal({
                    type: userCanUpgrade
                      ? MODAL_TYPE.STORAGE_UPSELL
                      : MODAL_TYPE.STORAGE_LIMIT,
                  })
                );
              }
              break;
          }
        }

        dispatch(deleteLocalItems([fileId]));
        dispatch(uploadNextItem());
      });
  };
}

function uploadProgress(
  id: string,
  partNumber: number,
  progress: AxiosProgressEvent
): AppThunk {
  return (dispatch) => {
    dispatch(
      setUploadProgress({
        id,
        partNumber,
        bytesUploaded: progress.loaded,
        bytesTotal: progress.total || 0.0,
      })
    );
  };
}

function uploadError(error: unknown, id: Item['id']): AppThunk {
  return (dispatch, getState) => {
    const state = getState();
    const item = getItemByItemId({ id })(state);

    if (
      item !== undefined &&
      isApiError(error) &&
      error.status === HTTP_STATUS_CODE.NOT_ALLOWED
    ) {
      dispatch(clearQueueForCollection(item.collectionId));
      dispatch(
        setNotification({
          type: NOTIFICATION_TYPE.ERROR_MESSAGE_NOT_ALLOWED_TO_ADD_ITEMS,
        })
      );
      return;
    }

    dispatch(uploadRetry(id));
  };
}

function uploadMultipleDelete(itemIds: Item['id'][]): AppThunk {
  return (dispatch) => {
    itemIds.forEach((id) => {
      dispatch(
        updateItem({
          itemId: id,
          item: {
            id,
            uploadStatus: ITEM_UPLOAD_STATUS.FAILED,
          },
        })
      );

      dispatch(deleteUpload(id));
    });
  };
}

function uploadRetry(id: string): AppThunk {
  return (dispatch, getState) => {
    const {
      uploader: { files },
    } = getState();
    const file = files[id];

    if (file.errorCount === MAX_UPLOAD_RETRIES) {
      dispatch(
        updateItem({
          itemId: id,
          item: {
            uploadStatus: ITEM_UPLOAD_STATUS.FAILED,
          },
        })
      );
    } else {
      dispatch(
        updateFile({
          id,
          errorCount: file.errorCount + 1,
        })
      );
    }
  };
}

function resetFileParts(
  itemId: Item['id'],
  parts: FilePart['id'][]
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      for (const partId of parts) {
        const response = await API.upload.fetchUploadUrl({ itemId, partId });
        dispatch(
          resetUploadPart({
            id: itemId,
            partId: partId,
            url: response.url,
            expiresAt: response.expiresAt,
          })
        );
      }
      void dispatch(uploadFileParts(itemId));
    } catch (error) {
      dispatch(resetPending(pendingId));
    }
  };
}

function getNextItemInQueue(): AppThunk<FileUpload | undefined> {
  return (dispatch, getState) => {
    const { uploader } = getState();

    if (Object.keys(uploader.files).length === 0) {
      return;
    }

    const nextItem = Object.values(uploader.files).find((file) => {
      const uploadStatus = getUploadStatusByItemId({ id: file.id })(getState());
      return (
        (uploadStatus === ITEM_UPLOAD_STATUS.UPLOADING ||
          uploadStatus === ITEM_UPLOAD_STATUS.QUEUED) &&
        Object.values(file.parts).filter((part) => !part.complete).length
      );
    });

    return nextItem;
  };
}

function clearQueueForCollection(collectionId: Collection['id']): AppThunk {
  return (dispatch, getState) => {
    const state = getState();
    const queuedItemIds = getSortedItemIdsByCollectionId({ id: collectionId })(
      state
    ).filter(
      (id) =>
        getUploadStatusByItemId({ id })(state) === ITEM_UPLOAD_STATUS.QUEUED
    );

    dispatch(uploadMultipleDelete(queuedItemIds));
  };
}

function isStorageLimitError(error: unknown) {
  return (
    isApiError(error) &&
    (error.errorCode === 'exceeded_upload_limit' ||
      error.errorCode === 'file_exceeded_upload_limit')
  );
}
