import { createSelector } from 'reselect';

import type { RootState } from '@app/store';

/* State */

const notificationState = (state: RootState) => state.notification;

/* Selectors */

export const getNotification = createSelector(
  [notificationState],
  (notification) => (notification.length ? notification[0] : undefined)
);
