import type { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  visual: string;
  visual2x: string;
}

export const OnboardingPage = ({ children, visual, visual2x }: Props) => (
  <div className="w-full box-border min-h-[400px] px-10">
    <div className="flex items-center justify-around lg:justify-evenly flex-col lg:flex-row">
      <div className="mb-4 lg:mb-0">
        <img
          className="max-w-[250] lg:max-w-none"
          src={visual}
          srcSet={`${visual2x} 2x`}
          alt=""
        />
      </div>
      <div className="max-w-[315px] lg:max-w-[288px] text-center lg:text-left pb-5 lg:pb-0">
        {children}
      </div>
    </div>
  </div>
);
