import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import type { NavLinkProps } from 'react-router-dom';
import { generatePath, NavLink, useMatch } from 'react-router-dom';

import { MODAL_TYPE, ROUTES, URL_SEARCH_PARAMS } from '@app/config/constants';
import type { AppDispatch } from '@app/store';
import { getCaptionByItemId } from '@app/store/items/selectors';
import { openModal } from '@app/store/modal/actions';
import { setNotification } from '@app/store/notification/actions';
import { getCanEditCollection } from '@app/store/selectors';
import type { Collection } from '@app/types/collections';
import type { Item } from '@app/types/items';
import { NOTIFICATION_TYPE } from '@component/Notification/types';
import { useAction } from '@hook/useAction';
import { track } from '@module/analytics';

const MAX_CAPTION_LENGTH = 120;

type Props = {
  collectionId?: Collection['id'];
  itemId: Item['id'];
  isSummary?: boolean;
};

export const ItemCaptionContent = ({
  collectionId,
  itemId,
  isSummary = false,
}: Props) => {
  const { t } = useTranslation();
  const action = useAction();
  const dispatch = useDispatch<AppDispatch>();

  const caption = useSelector(getCaptionByItemId({ id: itemId }));
  const canEditContent = useSelector(
    getCanEditCollection({ id: collectionId })
  );

  const isFromAll = useMatch(ROUTES.HOME) !== null;
  const captionLinkTo: NavLinkProps['to'] = useMemo(
    () => ({
      pathname: generatePath(ROUTES.ITEM, {
        collectionId: collectionId ?? null,
        itemId,
      }),
      search: isFromAll
        ? `?${URL_SEARCH_PARAMS.fromAll}&${URL_SEARCH_PARAMS.caption}`
        : `?${URL_SEARCH_PARAMS.caption}`,
    }),
    [collectionId, itemId, isFromAll]
  );

  const handleCaptionReadMore = useCallback(() => {
    track('caption_see_more_tapped');
  }, []);

  const handleUpdateCaption = useCallback(() => {
    if (!canEditContent) {
      dispatch(
        setNotification({ type: NOTIFICATION_TYPE.INFO_VIEW_ONLY_BOARD })
      );
      return;
    }

    track(caption ? 'update_caption_tapped' : 'add_caption_tapped', {
      location: 'item_detail',
    });

    dispatch(
      openModal({
        type: MODAL_TYPE.CAPTION,
        config: {
          itemId,
          shouldRedirect: !isSummary && caption === undefined,
        },
      })
    );
  }, [canEditContent, caption, dispatch, itemId, isSummary]);

  return (
    <div className="p-2">
      <div className="flex items-center justify-between">
        <Trans
          parent="span"
          className="block ts-h6 uppercase text-gray-60 pb-2"
          i18nKey="caption"
          data-test-id="caption-title"
        >
          Caption
        </Trans>
        {canEditContent && (
          <button
            className={classNames(action.tertiary, '-mt-2')}
            onClick={handleUpdateCaption}
            data-test-id="caption-edit-button"
          >
            {t('button:edit_caption')}
          </button>
        )}
      </div>
      <p
        className="ts-b2 text-black dark:text-white max-h-1/2vh overflow-y-scroll"
        data-test-id="caption-content-text"
      >
        {isSummary ? caption?.substring(0, MAX_CAPTION_LENGTH) : caption}
        {isSummary && caption && caption.length > MAX_CAPTION_LENGTH && ' ...'}
      </p>
      {isSummary && caption && caption.length > MAX_CAPTION_LENGTH && (
        <NavLink
          onClick={handleCaptionReadMore}
          to={captionLinkTo}
          className={action.tertiarySmall}
          data-test-id="caption-read-more-link"
        >
          {t('button:read_more')}
        </NavLink>
      )}
    </div>
  );
};
