import { AVATAR_COLORS } from '@app/config/constants';

export function getAvatarColor({
  publicIdentifier,
}: {
  publicIdentifier: string;
}) {
  if (!publicIdentifier || publicIdentifier.length === 1) {
    return AVATAR_COLORS.YELLOW;
  }

  const asciiValue = publicIdentifier.charCodeAt(1);
  if (!asciiValue) {
    return AVATAR_COLORS.YELLOW;
  }

  const colors = Object.values(AVATAR_COLORS);
  const colorIndex = asciiValue % colors.length;
  return colors[colorIndex];
}
