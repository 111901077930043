import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { COLLECTION_TYPE, MEMBERSHIP_ROLE } from '@app/config/constants';
import {
  getCollectionTypeById,
  getCollectionMembershipsById,
  getCollectionMembershipRoleById,
  getCollectionNameById,
} from '@app/store/collections/selectors';
import type { Collection } from '@app/types/collections';
import type { AnimationType } from '@component/Animation';
import Animation from '@component/Animation';
import PopoverAddContent from '@component/PopoverAddContent';

type Props = {
  collectionId?: Collection['id'];
};

type Content = {
  title: string;
  description: string;
  animation?: AnimationType;
  buttonLabel?: string;
};

export const EmptyState = ({ collectionId }: Props) => {
  const { t } = useTranslation();

  const type = useSelector(getCollectionTypeById({ id: collectionId }));
  const collectionName = useSelector(
    getCollectionNameById({ id: collectionId })
  );
  const membershipRole = useSelector(
    getCollectionMembershipRoleById({ id: collectionId })
  );
  const memberships = useSelector(
    getCollectionMembershipsById({ id: collectionId })
  );

  const emptyStateType = useMemo(
    () =>
      !type
        ? 'allItems'
        : type === COLLECTION_TYPE.UNSORTED
        ? 'sortLater'
        : type === COLLECTION_TYPE.SHARED &&
          membershipRole === MEMBERSHIP_ROLE.FOLLOWER
        ? 'sharedCollection'
        : 'collection',
    [type, membershipRole]
  );

  const collectionOwnerName = useMemo(() => {
    const owner = memberships?.find(
      (membership) => membership.role === MEMBERSHIP_ROLE.OWNER
    );
    return owner?.name;
  }, [memberships]);

  let content: Content = {
    title: '',
    description: '',
  };

  switch (emptyStateType) {
    case 'collection':
      content = {
        title: t('empty_state.collection.title'),
        description:
          collectionName !== undefined
            ? t('empty_state.collection.description', {
                name: collectionName,
              })
            : t('empty_state.collection.description_no_name'),
        animation: 'CollectionEmpty',
        buttonLabel: t('empty_state.collection.button'),
      };
      break;

    case 'allItems':
      content = {
        title: t('empty_state.all_items.title'),
        description: t('empty_state.all_items.description'),
        animation: 'AllItemsEmpty',
        buttonLabel: t('empty_state.all_items.button'),
      };
      break;

    case 'sortLater':
      content = {
        title: t('empty_state.sort_later.title'),
        description: t('empty_state.sort_later.description'),
        animation: 'SortLaterEmpty',
        buttonLabel: t('empty_state.sort_later.button'),
      };
      break;

    default:
      content = {
        title: t('empty_state.shared_collection.title'),
        description:
          collectionOwnerName !== undefined
            ? t('empty_state.shared_collection.description', {
                name: collectionOwnerName,
              })
            : t('empty_state.shared_collection.description_no_name'),
        animation: 'CollectionEmpty',
      };
      break;
  }

  return (
    <div className="flex flex-col h-full items-center justify-center">
      {content.animation !== undefined && (
        <Animation className="w-36 text-center" type={content.animation} />
      )}
      {content.title && (
        <h3
          className="w-[21rem] ts-l1 mt-5 text-center"
          data-test-id="collection-empty-title"
        >
          {content.title}
        </h3>
      )}
      {content.description && (
        <p
          className="w-[21rem] ts-b2 mt-2 text-center"
          data-test-id="collection-empty-description"
        >
          {content.description}
        </p>
      )}
      {content.buttonLabel && (
        <div className="mt-5">
          <PopoverAddContent
            isAllItems={emptyStateType === 'allItems'}
            collectionId={collectionId}
            label={content.buttonLabel}
          />
        </div>
      )}
    </div>
  );
};
