import classNames from 'classnames';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import type { Collection } from '@app/types/collections';
import type { Item } from '@app/types/items';
import ItemActions from '@component/ItemActions';
import { useAction } from '@hook/useAction';

export type Props = {
  children: ReactNode;
  selectedItems: Item['id'][];
  isAllItemsSelected?: boolean;
  onSelectAll?: () => void;
  onClearSelection: () => void;
  collectionId?: Collection['id'];
};

const CollectionToolbarSelectedItemsActions = ({
  children,
  selectedItems,
  isAllItemsSelected,
  onSelectAll,
  onClearSelection,
  collectionId,
}: Props) => {
  const action = useAction();
  const { t } = useTranslation();
  const numberOfItemsSelected = selectedItems.length;

  if (numberOfItemsSelected === 0) {
    return <>{children}</>;
  }

  return (
    <div
      className="flex items-center"
      data-test-id="collection-toolbar-selected-items-actions"
    >
      <span
        className="ts-l3 mr-3"
        data-test-id="collection-toolbar-selected-items-count"
      >
        {t('label.item_selected', {
          count: numberOfItemsSelected,
        })}
      </span>
      {onSelectAll !== undefined && (
        <button
          className={action.secondarySmallOnGray}
          data-test-id="collection-toolbar-select-all-button"
          onClick={onSelectAll}
          disabled={isAllItemsSelected}
        >
          {t('button:select_all')}
        </button>
      )}
      <button
        className={classNames(action.secondarySmallOnGray, 'mr-1')}
        onClick={onClearSelection}
        data-test-id="collection-toolbar-selected-items-cancel-button"
      >
        {t('button:cancel')}
      </button>
      <ItemActions collectionId={collectionId} itemIds={selectedItems} />
    </div>
  );
};

export default CollectionToolbarSelectedItemsActions;
