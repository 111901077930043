import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useParams } from 'react-router-dom';

import { ROUTES } from '@app/config/constants';
import {
  getCollectionById,
  getPreviousItemByCollectionIdAndItemId,
  getNextItemByCollectionIdAndItemId,
} from '@app/store/collections/selectors';
import { getItemByItemId } from '@app/store/items/selectors';
import { getIsFetchingInitialData } from '@app/store/pending/selectors';
import EmbeddedItemDetail from '@component/EmbeddedItemDetail';
import ErrorState from '@component/ErrorState';
import ItemDetail from '@component/ItemDetail';
import OpenInApp from '@component/OpenInApp';
import OpenInCollect from '@component/OpenInCollect';
import PublicCollectionHeader from '@component/PublicCollectionHeader';

enum Type {
  public = 'public',
  embed = 'embed',
}

export const PublicItemPage = ({ type }: { type: Type }) => {
  const { collectionId, itemId } = useParams();
  const collection = useSelector(getCollectionById({ id: collectionId }));
  const item = useSelector(getItemByItemId({ id: itemId }));
  const previousItem = useSelector(
    getPreviousItemByCollectionIdAndItemId({ id: collectionId, itemId })
  );
  const nextItem = useSelector(
    getNextItemByCollectionIdAndItemId({ id: collectionId, itemId })
  );
  const collectionUrl = useMemo(
    () =>
      generatePath(
        type === Type.public ? ROUTES.COLLECTION : ROUTES.EMBED_COLLECTION,
        { collectionId: collectionId ?? null }
      ),
    [collectionId, type]
  );
  const isFetchingInitialData = useSelector(getIsFetchingInitialData);

  // not found
  if (
    !isFetchingInitialData &&
    (collection === undefined ||
      item === undefined ||
      collectionId === undefined ||
      itemId === undefined)
  ) {
    return (
      <div className="flex flex-col h-screen w-screen">
        {type === Type.public && <PublicCollectionHeader />}
        <ErrorState
          type={
            type === Type.public
              ? ErrorState.type.itemNotFound
              : ErrorState.type.embedNotFound
          }
          className="px-4"
        />
      </div>
    );
  }

  return type === Type.public ? (
    <>
      <ItemDetail
        collection={collection}
        item={item}
        previousItem={previousItem}
        nextItem={nextItem}
        closeUrl={collectionUrl}
        isPublic
      />
      <OpenInApp collectionId={collectionId} />
    </>
  ) : (
    <>
      <EmbeddedItemDetail
        item={item}
        nextItem={nextItem}
        previousItem={previousItem}
        closeUrl={collectionUrl}
      />
      <OpenInCollect collectionId={collectionId} itemId={itemId} />
    </>
  );
};

PublicItemPage.type = Type;
