import classnames from 'classnames';

import { getAvatarColor } from '@app/helper/ui';
import Icon from '@component/Icon';

export type Props = {
  id?: string;
  name?: string;
  size?: 'small' | 'medium' | 'large';
  isNumber?: boolean;
};

export const Avatar = ({
  id,
  name,
  size = 'large',
  isNumber = false,
}: Props) => {
  const color = id ? getAvatarColor({ publicIdentifier: id }) : '#FFBE06';
  const style = isNumber
    ? {}
    : {
        backgroundColor: color,
      };

  const userName = name ? name : 'Unknown';
  return (
    <div
      className={classnames(
        'flex shrink-0 items-center justify-center text-center rounded-full text-opacity-80 capitalize',
        {
          'w-6 h-6 ts-l7': size === 'small',
          'w-8 h-8 ts-l3': size === 'medium',
          'w-10 h-10 ts-l1': size === 'large',
          'bg-white dark:bg-gray-80 text-gray-80 dark:text-white': isNumber,
          'text-white': !isNumber,
        }
      )}
      style={style}
      data-test-id="user-avatar-icon"
    >
      {name ? userName.slice(0, 2) : <Icon type="User" />}
    </div>
  );
};
