import { memo } from 'react';

import type { Collection } from '@app/types/collections';
import type { Item } from '@app/types/items';

import { UploadStatusCollection } from './UploadStatusCollection';
import { UploadStatusItem } from './UploadStatusItem';

export type Props = {
  collectionId?: Collection['id'];
  itemId?: Item['id'];
};

const UploadStatusWrapper = ({ collectionId, itemId }: Props) => {
  if (collectionId)
    return (
      <UploadStatusCollection
        collectionId={collectionId}
        data-test-id="upload-status-collection"
      />
    );

  if (itemId)
    return (
      <UploadStatusItem itemId={itemId} data-test-id="upload-status-item" />
    );

  return null;
};

export const UploadStatus = memo(UploadStatusWrapper);
