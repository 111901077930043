import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { User } from '@app/types/user';

import type { UserState } from './types';

export const initialState: UserState = {
  id: '',
  name: '',
  email: '',
  emailVerified: false,
  isLoggedIn: false,
  isLoading: true,
  usage: {
    bytesUsed: 0,
    bytesLimit: 0,
    bytesGraced: 0,
  },
  teams: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setAuthData: (state, action: PayloadAction<User>) => {
      return {
        ...state,
        ...action.payload,
        isLoggedIn: true,
        deviceToken: action.payload.deviceToken ?? state.deviceToken,
        unsortedId: action.payload.unsortedId ?? state.unsortedId,
      };
    },
    updateUsage: (state, action: PayloadAction<UserState['usage']>) => {
      state.usage = action.payload;
    },
    setTeams: (state, action: PayloadAction<UserState['teams']>) => {
      state.teams = action.payload;
    },
  },
});

export default userSlice.reducer;
