import { flattenDeep } from 'lodash';

import {
  MODAL_CONFIRM_TYPE,
  MODAL_TYPE,
  ITEM_TYPE,
} from '@app/config/constants';
import type { GetToken, GetUser } from '@app/modules/auth0';
import type { AppThunk } from '@app/store';
import { getAllSharedCollections } from '@app/store/collections/selectors';
import { getItemsByItemIds } from '@app/store/items/selectors';
import { openModal } from '@app/store/modal/actions';
import { setPending, resetPending } from '@app/store/pending/actions';
import { userSlice } from '@app/store/user';
import { makeUser } from '@app/transformers/user';
import type { FileItem } from '@app/types/items';
import { trackProps } from '@module/analytics';
import API from '@module/api';
import * as auth from '@module/auth0';
import Monitor from '@module/Monitor';

import {
  getUser,
  getUserCanUpgrade,
  getUserHasSubscription,
} from './selectors';

export const { updateUsage, setAuthLoading, setAuthData, setTeams } =
  userSlice.actions;

export function setInitialUserData({
  getUser,
  getToken,
}: {
  getUser: GetUser;
  getToken: GetToken;
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    // Configure API to use token
    const { deviceToken, unsortedId } = await API.configure({
      getToken,
      onAuthError: (error) => {
        dispatch(
          openModal({
            type: MODAL_TYPE.CONFIRM,
            config: {
              type: MODAL_CONFIRM_TYPE.LOGOUT_FORCED,
              preventDismiss: true,
            },
          })
        );
        return error;
      },
    });

    // Prime the initial user
    await dispatch(fetchUser());

    const state = getState();
    const { user: stateUser } = state;
    const authUser = await getUser();
    const userCanUpgrade = getUserCanUpgrade(state);
    const userHasSubscription = getUserHasSubscription(state);

    if (userCanUpgrade) {
      try {
        const unlockedSubscription = await API.user.unlockProPass();
        if (unlockedSubscription.tier !== stateUser.subscription?.tier) {
          await dispatch(fetchUser());
        }
      } catch (e) {
        // ignore error because it just means that the
        // user has not unlocked the pro subscription
      }
    }

    dispatch(
      setAuthData({
        ...stateUser,
        email: authUser?.email ?? stateUser.email,
        name: authUser?.name ?? stateUser.name,
        emailVerified: authUser?.email_verified ?? stateUser.emailVerified,
        unsortedId,
        deviceToken,
      })
    );

    // Analytics
    trackProps({
      id: stateUser.id,
      auth0id: authUser?.sub,
      is_pro: userHasSubscription,
      email_verified: authUser?.email_verified ?? stateUser.emailVerified,
      has_pro_subscription: Boolean(stateUser.subscription?.status),
      subscription_type: stateUser.subscription?.origin ?? '',
    });
  };
}

export function fetchUser(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    const pendingId = 'fetchUser';
    dispatch(setPending(pendingId));

    try {
      const userFromApi = await API.user.fetch();
      dispatch(setAuthData(makeUser(userFromApi)));

      await Promise.all([dispatch(fetchUsage()), dispatch(fetchTeams())]);
    } catch (error) {
      Monitor.error(error);
    }

    dispatch(resetPending(pendingId));
  };
}

export function fetchUsage(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const pendingId = 'fetchUsage';
    dispatch(setPending(pendingId));

    const state = getState();
    const user = getUser(state);

    try {
      const { bytesUsed, bytesLimit, bytesGraced } =
        await API.user.fetchUsage();

      dispatch(updateUsage({ bytesUsed, bytesLimit, bytesGraced }));

      const collections = getAllSharedCollections(state);
      const sharedCollectionItems = getItemsByItemIds({
        ids: flattenDeep(collections.map((collection) => collection.items)),
      })(state);

      const sharedItemsSize = sharedCollectionItems
        .filter(
          (item) => item.type === ITEM_TYPE.FILE && item.owner?.id === user.id
        )
        .reduce((size, item) => size + ((item as FileItem).size ?? 0), 0);

      trackProps({
        cloud_kb: bytesUsed / 1000, // private content uploaded to cloud storage in kilobytes
        shared_kb: sharedItemsSize / 1000, // shared content uploaded to cloud storage in kilobytes
      });
    } catch (error) {
      Monitor.error(error);
    }

    dispatch(resetPending(pendingId));
  };
}

export function fetchTeams(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    const pendingId = 'fetchTeams';
    dispatch(setPending(pendingId));

    try {
      const teams = await API.teams.fetchAll();
      dispatch(setTeams(teams));
    } catch (error) {
      Monitor.error(error);
    }

    dispatch(resetPending(pendingId));
  };
}

export function signUp({
  language = 'en',
  search,
}: {
  language?: string;
  search?: string;
}): AppThunk<Promise<void>> {
  return async () => {
    try {
      await auth.signUp({ language, search });
    } catch (error) {
      //
    }
  };
}

export function login({
  language = 'en',
  search,
}: {
  language?: string;
  search?: string;
}): AppThunk<Promise<void>> {
  return async () => {
    try {
      await auth.login({ language, search });
    } catch (error) {
      //
    }
  };
}

export function logout(): AppThunk<Promise<void>> {
  return async () => {
    try {
      // Notify the API that we've signed out
      await API.user.logout();
    } catch (error) {
      // we allow this call to fail because the API could have forced us to logout
      // due to invalid credentials.
    }

    try {
      // Notify Auth0 that we've signed out
      auth.logout();
    } catch (error) {
      //
    }
  };
}
