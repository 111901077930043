import classNames from 'classnames';
import type { ButtonHTMLAttributes } from 'react';

import type { ListItemContentProps } from '@component/ListItemContent';
import ListItemContent from '@component/ListItemContent';
type ListItemProps = ButtonHTMLAttributes<HTMLButtonElement> &
  ListItemContentProps;
export const ListItem = ({
  children,
  className,
  icon,
  iconClassName,
  size,
  ...props
}: ListItemProps) => {
  return (
    <button
      {...props}
      className={classNames(
        'w-full rounded-xl hover:bg-gray-20 active:bg-gray-20 dark:hover:bg-black dark:hover:bg-opacity-70 dark:active:bg-black dark:active:bg-opacity-70 focus:outline-none focus-visible:ring-2 ring-inset focus-visible:ring-blue',
        className
      )}
    >
      <ListItemContent icon={icon} iconClassName={iconClassName} size={size}>
        {children}
      </ListItemContent>
    </button>
  );
};
