import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { removeCollection } from '@app/store/collections/action-creators';
import type { RemoveCollectionPayload } from '@app/store/collections/types';
import { removeItems } from '@app/store/items/action-creators';
import type { FileItem, Item } from '@app/types/items';
import { isFileItem } from '@app/types/items';

import type {
  RemoveItemsPayload,
  ItemsState,
  AddItemsPayload,
  UpdateItemPayload,
  MoveItemPayload,
  UpdateCaptionPayload,
  RemoveCaptionPayload,
  UpdateDetailPayload,
} from './types';

export const initialState: ItemsState = {};

export const itemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    addItems: (state, action: PayloadAction<AddItemsPayload>) => {
      const { payload } = action;
      const { items, collectionId } = payload;

      const itemEntries = items.reduce(
        (newItemEntries, item) => ({
          ...newItemEntries,
          [item.id]: {
            ...item,
            collectionId,
          },
        }),
        {}
      );

      return {
        ...state,
        ...itemEntries,
      };
    },
    updateItem: (
      state,
      { payload: { itemId, item } }: PayloadAction<UpdateItemPayload>
    ) => {
      // Item not found, so nothing to update
      if (state[itemId] === undefined) {
        return;
      }

      // Merge the two objects in to a new Item
      const updatedItem = { ...state[itemId], ...item } as Item;

      // The item has a new id so we remove the old
      if (item.id !== undefined && item.id !== itemId) {
        delete state[itemId];
      }

      // Add item back to state
      state[item.id ?? itemId] = updatedItem;
    },
    moveItem: (state, action: PayloadAction<MoveItemPayload>) => {
      const { payload } = action;
      const { itemId, newCollectionId } = payload;

      return {
        ...state,
        [itemId]: {
          ...state[itemId],
          collectionId: newCollectionId,
        },
      };
    },
    updateCaption: (state, action: PayloadAction<UpdateCaptionPayload>) => {
      const { payload } = action;
      const { itemId, caption } = payload;

      if (!state[itemId]) {
        return state;
      }

      return {
        ...state,
        [itemId]: {
          ...state[itemId],
          caption,
        },
      };
    },
    removeCaption: (state, action: PayloadAction<RemoveCaptionPayload>) => {
      const { payload } = action;
      const { itemId } = payload;

      if (!state[itemId]) {
        return state;
      }

      delete state[itemId].caption;
    },
    updateDetail: (state, action: PayloadAction<UpdateDetailPayload>) => {
      const {
        payload: { itemId, detailUrl },
      } = action;

      if (!state[itemId] || !isFileItem(state[itemId])) {
        return state;
      }

      (state[itemId] as FileItem).detailUrl = detailUrl;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(removeCollection, removeItems),
      (
        state: ItemsState,
        action: PayloadAction<RemoveItemsPayload | RemoveCollectionPayload>
      ) => {
        const {
          payload: { itemIds },
        } = action;

        for (const itemId of itemIds) {
          delete state[itemId];
        }
      }
    );
  },
});

export default itemsSlice.reducer;
