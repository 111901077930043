import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getAllItems } from '@app/store/items/selectors';
import { getAllOwnedCollectionsWithoutUnsorted } from '@app/store/selectors';
import { getUserIsLoggedIn } from '@app/store/user/selectors';
import { trackProps } from '@module/analytics';

type Stats = {
  collectionCount: number;
  privateCollectionCount: number;
  contentCount: number;
};

export function useTrackStats() {
  const isLoggedIn = useSelector(getUserIsLoggedIn);
  const collections = useSelector(getAllOwnedCollectionsWithoutUnsorted);
  const items = useSelector(getAllItems);

  const [stats, setStats] = useState<Stats | null>(null);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    const newStats = collections.reduce(
      (stats, { type }) => {
        return {
          ...stats,
          collectionCount: (stats.collectionCount += 1),
          privateCollectionCount:
            type === 'private'
              ? (stats.privateCollectionCount += 1)
              : stats.privateCollectionCount,
        };
      },
      {
        collectionCount: 0,
        privateCollectionCount: 0,
        contentCount: items.length,
      }
    );

    if (!isEqual(stats, newStats)) {
      setStats(newStats);
    }
  }, [isLoggedIn, stats, collections, items]);

  useEffect(() => {
    if (stats !== null) {
      trackProps({
        board_count: stats.collectionCount,
        private_board_count: stats.privateCollectionCount,
        content_count: stats.contentCount,
      });
    }
  }, [stats]);

  return stats;
}
