import type { AxiosResponse } from 'axios';
import { differenceBy, intersectionBy } from 'lodash';
import { generatePath } from 'react-router-dom';
import { batchActions } from 'redux-batched-actions';

import {
  ROUTES,
  COLLECTION_TYPE,
  COLLECTION_STATE,
  HTTP_STATUS_CODE,
} from '@app/config/constants';
import type { DiffOperations } from '@app/modules/api';
import { itemsSlice } from '@app/store/items';
import { fetchItems } from '@app/store/items/actions';
import { setNotification } from '@app/store/notification/actions';
import { setPending, resetPending } from '@app/store/pending/actions';
import { fetchUsage } from '@app/store/user/actions';
import { makeCollection, parseUpdatedAt } from '@app/transformers/collection';
import { makeItem } from '@app/transformers/item';
import type { Collection } from '@app/types/collections';
import { NOTIFICATION_TYPE } from '@component/Notification/types';
import { track } from '@module/analytics';
import API from '@module/api';
import Monitor from '@module/Monitor';
import { push } from '@module/ReduxRouter';

import type { AppThunk } from '..';
import { addItems, removeItems, updateItem } from '../items/actions';
import { getUser } from '../user/selectors';

import {
  addCollection,
  removeCollection,
  editCollection,
  addCollectionMemberships,
  removeCollectionMemberships,
} from './action-creators';
import {
  getAllOwnedCollections,
  getCollectionById,
  getCollectionShareUrlByIdAndType,
  getSortedItemIdsByCollectionId,
} from './selectors';
export { addCollection, removeCollection, editCollection };

function getBatchedOperationsFromDiff(
  id: Collection['id'],
  operations: DiffOperations
) {
  const batchedOperations = [];

  if (operations.collection_updated !== undefined) {
    batchedOperations.push(
      editCollection({
        collectionId: id,
        collection: makeCollection(
          operations.collection_updated[0],
          undefined,
          true
        ),
      })
    );
  }

  if (operations.items_deleted !== undefined) {
    batchedOperations.push(
      removeItems({
        collectionId: id,
        itemIds: operations.items_deleted,
      })
    );
  }

  if (operations.item_inserted !== undefined) {
    batchedOperations.push(
      ...operations.item_inserted.map((item) =>
        updateItem({
          itemId: item.id,
          item: makeItem(item),
        })
      )
    );
  }

  const itemsAdded = [
    ...(operations.urls_added ?? []),
    ...(operations.files_added ?? []),
  ];
  if (itemsAdded.length > 0) {
    batchedOperations.push(
      addItems({
        collectionId: id,
        items: itemsAdded.map((item) => makeItem(item)),
      })
    );
  }

  if (operations.memberships_removed !== undefined) {
    batchedOperations.push(
      removeCollectionMemberships({
        collectionId: id,
        membershipIds: operations.memberships_removed,
      })
    );
  }

  if (operations.memberships_added !== undefined) {
    batchedOperations.push(
      addCollectionMemberships({
        collectionId: id,
        memberships: operations.memberships_added,
      })
    );
  }

  const captionsCreatedOrUpdated = [
    ...(operations.caption_created ?? []),
    ...(operations.caption_updated ?? []),
  ];
  if (captionsCreatedOrUpdated.length > 0) {
    batchedOperations.push(
      ...captionsCreatedOrUpdated.map((apiCaption) =>
        itemsSlice.actions.updateCaption({
          itemId: apiCaption.id,
          caption: apiCaption.caption,
        })
      )
    );
  }

  if (operations.caption_deleted !== undefined) {
    batchedOperations.push(
      ...operations.caption_deleted.map((itemId) =>
        itemsSlice.actions.removeCaption({
          itemId,
        })
      )
    );
  }

  if (operations.collection_type_updated !== undefined) {
    batchedOperations.push(
      editCollection({
        collectionId: id,
        collection: makeCollection(operations.collection_type_updated[0]),
      })
    );
  }

  return batchedOperations;
}

export function fetchDiff(id: string): AppThunk<Promise<void>> {
  const pendingId = 'fetchDiff';
  return async (dispatch, getState) => {
    dispatch(setPending(pendingId));

    const state = getState();
    const collection = getCollectionById({ id })(state);

    if (collection === undefined) {
      return;
    }

    try {
      const { version, state, operations } = await API.collections.fetchDiff({
        collectionId: collection.id,
        collectionVersion: collection.version,
      });

      if (state !== COLLECTION_STATE.DOWNLOADABLE) {
        dispatch(resetPending(pendingId));
        return;
      }

      if (version !== collection.version) {
        dispatch(
          editCollection({
            collectionId: id,
            collection: {
              version,
              updatedAt: parseUpdatedAt(version),
            },
          })
        );
      }

      const batchedOperations = getBatchedOperationsFromDiff(id, operations);

      if (batchedOperations.length) {
        dispatch(batchActions(batchedOperations));
      }
    } catch (error) {
      dispatch(resetPending(pendingId));
      switch ((error as AxiosResponse).status) {
        case HTTP_STATUS_CODE.NOT_FOUND:
          dispatch(push(ROUTES.HOME));
          dispatch(deleteLocalCollection(id));
          break;

        case HTTP_STATUS_CODE.UNPROCESSABLE_ENTITY:
          void dispatch(fetchCollection(id));
          break;

        default:
          Monitor.error(error);
          throw error;
      }
    }
    dispatch(resetPending(pendingId));
  };
}

function deleteLocalCollection(id: string): AppThunk {
  return (dispatch, getState) => {
    const state = getState();
    const collectionItemIds = getSortedItemIdsByCollectionId({ id })(state);
    dispatch(
      removeCollection({
        collectionId: id,
        itemIds: collectionItemIds,
      })
    );
  };
}

export function deleteCollection(id: string): AppThunk<Promise<void>> {
  const pendingId = 'deleteCollection';

  return async (dispatch, getState) => {
    dispatch(setPending(pendingId));

    const state = getState();
    const collection = getCollectionById({ id })(state);

    if (collection === undefined) {
      dispatch(resetPending(pendingId));
      return;
    }

    try {
      await API.collections.remove({ collectionId: id });

      track('bucket_deleted', {
        content_count: collection.itemCount,
        members_count: collection.memberships.length,
        public: collection.type !== COLLECTION_TYPE.PRIVATE,
      });

      dispatch(push(ROUTES.HOME));

      // We only delete local collections if the api request has not thrown an error
      dispatch(deleteLocalCollection(id));

      dispatch(
        setNotification({
          type: NOTIFICATION_TYPE.SUCCESS_MESSAGE_BOARD_DELETED,
        })
      );

      await dispatch(fetchUsage());
    } catch (error) {
      dispatch(setNotification({ type: NOTIFICATION_TYPE.ERROR }));
      Monitor.error(error);
    }

    dispatch(resetPending(pendingId));
  };
}

export function leaveCollection(id: string): AppThunk<Promise<void>> {
  const pendingId = 'leaveCollection';

  return async (dispatch, getState) => {
    dispatch(setPending(pendingId));

    try {
      await API.collections.leave({ collectionId: id });

      dispatch(push(ROUTES.HOME));

      dispatch(deleteLocalCollection(id));

      dispatch(
        setNotification({ type: NOTIFICATION_TYPE.SUCCESS_MESSAGE_BOARD_LEFT })
      );
    } catch (error) {
      dispatch(setNotification({ type: NOTIFICATION_TYPE.ERROR }));
      Monitor.error(error);
    }

    dispatch(resetPending(pendingId));
  };
}

export function joinCollection(
  id: string,
  token?: string
): AppThunk<Promise<void>> {
  const pendingId = 'joinCollection';

  return async (dispatch, getState) => {
    dispatch(setPending(pendingId));

    try {
      const state = getState();
      const user = getUser(state);
      const joinedCollection = await API.collections.join({
        collectionId: id,
        token,
        name: user.name,
      });

      const collection = makeCollection(joinedCollection, user.id);

      dispatch(
        addCollection({
          collection,
        })
      );

      void dispatch(
        fetchItems(
          {
            collectionId: collection.id,
          },
          collection.itemCount
        )
      );

      const path = generatePath(ROUTES.COLLECTION, {
        collectionId: collection.id,
      });
      dispatch(push(path));
    } catch (error) {
      switch ((error as AxiosResponse).status) {
        case HTTP_STATUS_CODE.CONFLICT:
          {
            const path = generatePath(ROUTES.COLLECTION, {
              collectionId: id,
            });
            dispatch(push(path));
          }
          break;

        default:
          dispatch(setNotification({ type: NOTIFICATION_TYPE.ERROR }));
          Monitor.error(error);
          break;
      }
    }

    dispatch(resetPending(pendingId));
  };
}

export function createCollection(
  name: string,
  description: string
): AppThunk<Promise<Collection>> {
  const pendingId = 'createCollection';
  return async (dispatch, getState) => {
    const state = getState();
    const user = getUser(state);

    dispatch(setPending(pendingId));

    try {
      const collectionFromApi = await API.collections.save({
        name,
        description,
        type: COLLECTION_TYPE.PRIVATE,
      });

      const collection = makeCollection(collectionFromApi, user.id);

      dispatch(
        addCollection({
          collection,
        })
      );

      dispatch(resetPending(pendingId));
      return collection;
    } catch (error) {
      Monitor.error(error);
      dispatch(setNotification({ type: NOTIFICATION_TYPE.ERROR }));
      dispatch(resetPending(pendingId));
      throw error;
    }
  };
}

export function updateCollection(
  collectionId: string,
  name: string,
  description: string
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      await API.collections.update({
        collectionId,
        name,
        description,
      });

      dispatch(
        editCollection({
          collectionId,
          collection: {
            name,
            description,
          },
        })
      );
    } catch (error) {
      Monitor.error(error);
      dispatch(setNotification({ type: NOTIFICATION_TYPE.ERROR }));
    }
  };
}

export function createSharedCollectionUrl({
  id,
  type,
}: {
  id: string;
  type?: COLLECTION_TYPE;
}): AppThunk<Promise<void | string>> {
  const pendingId = 'createSharedCollectionUrl';

  return async (dispatch, getState) => {
    const state = getState();
    const user = getUser(state);

    if (type === undefined) {
      return;
    }

    const shareUrl = getCollectionShareUrlByIdAndType({ id, type })(state);

    if (shareUrl !== undefined) {
      return shareUrl;
    }

    dispatch(setPending(pendingId));
    try {
      const response = await API.collections.updateType({
        collectionId: id,
        type,
      });
      const updatedCollection = makeCollection(response, user.id);
      dispatch(
        editCollection({
          collectionId: id,
          collection: {
            type: updatedCollection.type,
            updatedAt: updatedCollection.updatedAt,
            version: updatedCollection.version,
            shortenedUrl: updatedCollection.shortenedUrl,
            inviteUrl: updatedCollection.inviteUrl,
            url: updatedCollection.url,
          },
        })
      );
    } catch (error) {
      if ((error as AxiosResponse).status === 409) {
        // conflict - collection already has desired type
        // so update collection in store
        await dispatch(fetchCollection(id));
      } else {
        Monitor.error(error);
      }
    }

    dispatch(resetPending(pendingId));
    // need to use getState() here because the store has been updated in the meantime
    return getCollectionShareUrlByIdAndType({ id, type })(getState());
  };
}

export function createEmbedCollectionUrl({
  id,
}: {
  id: string;
}): AppThunk<Promise<void | string>> {
  return async (dispatch, getState) => {
    await dispatch(
      createSharedCollectionUrl({ id, type: COLLECTION_TYPE.SHARED })
    );
    const relativeUrl = generatePath(ROUTES.COLLECTION, {
      collectionId: id,
    });
    const absoluteUrl = new URL(relativeUrl, window.location.href);
    return absoluteUrl.toString();
  };
}

function fetchCollection(
  id: string
): AppThunk<Promise<void | Partial<Collection>>> {
  const pendingId = 'fetchCollection';

  return async (dispatch, getState) => {
    const state = getState();
    const user = getUser(state);
    dispatch(setPending(pendingId));

    try {
      const collectionFromApi = await API.collections.fetchSingle({
        collectionId: id,
      });
      const transformedCollection = makeCollection(collectionFromApi, user.id);
      dispatch(
        editCollection({
          collectionId: id,
          collection: transformedCollection,
        })
      );
      dispatch(resetPending(pendingId));
      return transformedCollection;
    } catch (error) {
      dispatch(resetPending(pendingId));
      Monitor.error(error);
    }
  };
}

export function fetchCollectionsDiff(): AppThunk<Promise<void>> {
  const pendingId = 'fetchCollectionsDiff';

  return async (dispatch, getState) => {
    dispatch(setPending(pendingId));
    const state = getState();
    const user = getUser(state);
    const collections = getAllOwnedCollections(state);

    try {
      const response = await API.collections.fetchAll();
      const remoteCollections = response.map((collection) =>
        makeCollection(collection, user.id)
      );

      const deletedCollections = differenceBy(
        collections,
        remoteCollections,
        'id'
      );

      const addedCollections = differenceBy(
        remoteCollections,
        collections,
        'id'
      );

      const existingRemoteCollections = intersectionBy(
        remoteCollections,
        collections,
        'id'
      );

      const updatedCollections = existingRemoteCollections.filter(
        (remoteCollection) =>
          state.collections[remoteCollection.id].version !==
          remoteCollection.version
      );

      if (deletedCollections) {
        deletedCollections.forEach((collection) => {
          const collectionItemIds = collection.items;

          dispatch(
            removeCollection({
              collectionId: collection.id,
              itemIds: collectionItemIds,
            })
          );
        });
      }

      if (addedCollections) {
        addedCollections.forEach((collection) => {
          dispatch(
            addCollection({
              collection,
            })
          );
          void dispatch(
            fetchItems(
              {
                collectionId: collection.id,
              },
              collection.items.length
            )
          );
        });
      }

      if (updatedCollections) {
        updatedCollections.forEach((collection) => {
          void dispatch(fetchDiff(collection.id));
        });
      }
      dispatch(resetPending(pendingId));
    } catch (error) {
      dispatch(resetPending(pendingId));
      Monitor.error(error);
      throw error;
    }
  };
}

export function fetchCollectionItems(
  collectionId: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      collections: {
        [collectionId]: { itemCount },
      },
    } = getState();

    try {
      const itemsFromApi = await API.items.fetchPerCollection({
        collectionId,
        totalItems: itemCount,
      });
      dispatch(addItems({ collectionId, items: itemsFromApi.map(makeItem) }));
    } catch (error) {
      Monitor.error(error);
    }
  };
}
