import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateItem } from '@app/store/items/actions';
import { getDetailByItemId, getItemByItemId } from '@app/store/items/selectors';
import { getDetailUrlExpiryDate } from '@app/transformers/item';
import type { Item } from '@app/types/items';
import API, { isApiError } from '@module/api';
import Monitor from '@module/Monitor';

const preloadImage = async (src: string) =>
  new Promise<boolean>((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve(true);
    };
    img.onerror = () => {
      resolve(false);
    };
    img.src = src;
  });

const wait = async (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export function useItemDetailUrl(id?: Item['id']) {
  const dispatch = useDispatch();

  const detail = useSelector(getDetailByItemId({ id }));
  const item = useSelector(getItemByItemId({ id }));
  const [state, setState] = useState<{
    id?: Item['id'];
    isLoading: boolean;
    url?: string;
  }>({
    id,
    isLoading: true,
  });

  const fetchDetailUrl = useCallback(async () => {
    if (item === undefined) {
      return;
    }

    try {
      const url = await API.items.fetchDetailUrl({
        collectionId: item.collectionId,
        itemId: item.id,
        size: 'large',
      });

      if (url === null) {
        setState({ isLoading: false, id: item.id });
        return;
      }

      if (detail.type === 'image') {
        const success = await preloadImage(url);

        // We retry once on a failure
        if (!success) {
          await wait(500);
          await preloadImage(url);
        }
      }

      dispatch(
        updateItem({
          itemId: item.id,
          item: {
            detailUrl: url,
            currentDetailUrlExpiresAt: getDetailUrlExpiryDate(url),
          },
        })
      );
    } catch (error) {
      setState({ isLoading: false, id: item.id });
      if (isApiError(error) && error.status === 404) {
        return;
      }
      Monitor.error(error);
    }
  }, [dispatch, item, detail]);

  useEffect(() => {
    setState({ isLoading: id !== undefined, id });
  }, [id]);

  useEffect(() => {
    if (detail.url !== undefined) {
      setState((state) => ({
        isLoading: false,
        url: detail.url,
        id: state.id,
      }));
      return;
    }

    if (detail.type === 'web' || detail.type === 'unsupported') {
      setState((state) => ({
        isLoading: false,
        id: state.id,
      }));
      return;
    }

    void fetchDetailUrl();
  }, [detail, fetchDetailUrl]);

  if (id !== state.id) {
    return {
      isLoading: id !== undefined,
    };
  }

  return {
    isLoading: state.isLoading,
    url: state.url,
  };
}
