import classNames from 'classnames';
import { useEffect, useCallback, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { ROUTES, URL_SEARCH_PARAMS } from '@app/config/constants';
import type { Collection } from '@app/types/collections';
import type { Item } from '@app/types/items';
import ItemDetailArrowNavigation from '@component/ItemDetailArrowNavigation';
import ItemDetailToolbar from '@component/ItemDetailToolbar';
import { useCollectionName } from '@hook/useCollectionName';

import ItemDetailLoader from './ItemDetailLoader';

type Props = {
  collection?: Collection;
  item?: Item;
  closeUrl: string;
  nextItem?: Item;
  previousItem?: Item;
  isLoading?: boolean;
  isPublic?: boolean;
  isFromAll?: boolean;
};

const ItemDetail = ({
  collection,
  item,
  closeUrl,
  nextItem,
  previousItem,
  isLoading,
  isPublic,
  isFromAll,
}: Props) => {
  const navigate = useNavigate();

  const collectionName = useCollectionName(collection);

  const goToNextPage = useCallback(
    () =>
      nextItem !== undefined &&
      navigate(
        `${generatePath(ROUTES.ITEM, {
          collectionId: nextItem.collectionId,
          itemId: nextItem.id,
        })}${isFromAll ? `?${URL_SEARCH_PARAMS.fromAll}` : ''}`
      ),
    [navigate, nextItem, isFromAll]
  );

  const goToPreviousPage = useCallback(
    () =>
      previousItem !== undefined &&
      navigate(
        `${generatePath(ROUTES.ITEM, {
          collectionId: previousItem.collectionId,
          itemId: previousItem.id,
        })}${isFromAll ? `?${URL_SEARCH_PARAMS.fromAll}` : ''}`
      ),
    [navigate, previousItem, isFromAll]
  );

  const goToBoardView = useCallback(
    () => navigate(closeUrl),
    [navigate, closeUrl]
  );

  const [focusMode, setFocusMode] = useState(false);

  const handleDOMClick = useCallback(() => {
    if (focusMode) {
      setFocusMode(false);
    }
  }, [focusMode, setFocusMode]);

  const handleImageClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (isPublic) {
        return;
      }
      setFocusMode(!focusMode);
    },
    [focusMode, isPublic]
  );

  useEffect(() => {
    document.addEventListener('click', handleDOMClick);
    return () => {
      document.removeEventListener('click', handleDOMClick);
    };
  }, [handleDOMClick]);

  return (
    <div className="relative w-full h-screen overflow-hidden flex flex-col">
      <div
        className={classNames('shrink-0 grow-0 transition-all duration-150', {
          'h-0': focusMode,
          'h-16': !focusMode,
        })}
      >
        <ItemDetailToolbar
          item={item}
          collectionName={collectionName}
          onClose={goToBoardView}
        />
      </div>
      <ItemDetailArrowNavigation
        hasNextItem={nextItem !== undefined}
        hasPrevItem={previousItem !== undefined}
        onNextClick={goToNextPage}
        onPrevClick={goToPreviousPage}
        onCancel={goToBoardView}
        hidden={focusMode}
      />
      <div className="flex-1 h-0">
        <ItemDetailLoader
          item={item}
          isPublic={isPublic}
          handleImageClick={handleImageClick}
          focusMode={focusMode}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default ItemDetail;
