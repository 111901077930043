import type { ReactNode } from 'react';
import type { NavLinkProps } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import type { Item } from '@app/types/items';
import { isFileItem } from '@app/types/items';

type Props = {
  children: ReactNode;
  item: Item;
  className: string;
  to: NavLinkProps['to'];
};

export const ItemLinkWrapper = ({ children, item, className, to }: Props) =>
  isFileItem(item) ? (
    <NavLink
      to={to}
      title={item.meta.title}
      className={className}
      draggable="false"
    >
      {children}
    </NavLink>
  ) : (
    <a
      href={item?.url ?? '#'}
      target="_blank"
      rel="noopener noreferrer"
      title={item.meta.title}
      className={className}
      draggable="false"
    >
      {children}
    </a>
  );
