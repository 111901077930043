import classNames from 'classnames';
import type { ReactNode } from 'react';
import { useMemo } from 'react';

import Icon from '@component/Icon';

export type Props = {
  variant: 'info' | 'danger';
  className?: string;
  children: ReactNode;
};

export const Alert = ({ variant, className, children }: Props) => {
  const alertClassNames = useMemo<string>(() => {
    return classNames(
      // External
      className,
      // Base
      'bg-white dark:bg-gray-90 p-4 ts-l3 flex items-center rounded-[20px]',
      // Variants
      {
        'text-black dark:text-white': variant === 'info',
        'text-red-60 dark:text-red-55': variant === 'danger',
      }
    );
  }, [variant, className]);

  let alertIcon = null;
  switch (variant) {
    case 'info':
    case 'danger':
      alertIcon = <Icon type="Warning" className="fill-current mr-2 w-6 h-6" />;
      break;
  }

  return (
    <div className={alertClassNames}>
      {alertIcon} {children}
    </div>
  );
};
