import './polyfills';

import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import Router from '@app/router';
import store, { persistor } from '@app/store';
import ErrorState from '@component/ErrorState';
import { trackPageView } from '@module/analytics';
import { Provider as I18nProvider } from '@module/i18n';
import { ErrorBoundary } from '@module/Monitor';
import { ReduxRouter, history } from '@module/ReduxRouter';

import './styles/app.scss';

// eslint-disable-next-line no-console
console.log(`
  Collect - by WeTransfer - v: ${process.env.REACT_APP_GIT_REF ?? 'N.A.'}
\n`);

ReactDOM.render(
  <StrictMode>
    <I18nProvider>
      <ReduxProvider store={store}>
        <PersistGate persistor={persistor}>
          <ReduxRouter
            history={history}
            store={store}
            onLocationChange={trackPageView}
          >
            <ErrorBoundary
              error={<ErrorState type={ErrorState.type.general} />}
            >
              <Router />
            </ErrorBoundary>
          </ReduxRouter>
        </PersistGate>
      </ReduxProvider>
    </I18nProvider>
  </StrictMode>,
  document.getElementById('root')
);
