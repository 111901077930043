import classNames from 'classnames';
import type { ButtonHTMLAttributes } from 'react';

import type { ListItemContentProps } from '@component/ListItemContent';
import ListItemContent from '@component/ListItemContent';
type ListItemSidebarProps = ButtonHTMLAttributes<HTMLButtonElement> &
  ListItemContentProps;
export const ListItemSidebar = ({
  className,
  iconClassName,
  ...props
}: ListItemSidebarProps) => {
  return (
    <button
      {...props}
      className={classNames(
        'w-full rounded-xl block hover:bg-white transition-colors dark:hover:bg-gray-90 active:bg-white dark:active:bg-gray-90 focus:outline-none focus-visible:ring-2 ring-inset focus-visible:ring-blue',
        className
      )}
    >
      <ListItemContent {...props} iconClassName={iconClassName} />
    </button>
  );
};
