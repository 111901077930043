import type { Locale } from 'date-fns';
import formatDate from 'date-fns/format';
import en from 'date-fns/locale/en-GB';
import type { FormatFunction } from 'i18next';
import i18n, { use } from 'i18next';
import type { ReactNode } from 'react';
import { initReactI18next, I18nextProvider } from 'react-i18next';

import buttonEN from '@app/locales/en/button.json';
import generalEN from '@app/locales/en/general.json';
import modalEN from '@app/locales/en/modal.json';
import notificationEN from '@app/locales/en/notification.json';

const dateLocales: Record<string, Locale> = {
  en,
};

const resources = {
  en: {
    // Namespaces
    general: generalEN,
    notification: notificationEN,
    button: buttonEN,
    modal: modalEN,
  },
};

const formatFunction: FormatFunction = (
  value: number | Date | string,
  format?: string,
  lng?: string
) => {
  if (value instanceof Date)
    return formatDate(value, format ?? '', {
      locale: dateLocales[lng ?? 'en'],
    });

  if (typeof value === 'number' && format === 'gigabytes') {
    let gigaBytes = value / Math.pow(1024, 3); // convert to GB
    gigaBytes = Math.round(gigaBytes * 100) / 100; // up to two decimal places
    return gigaBytes.toString();
  }

  return value as string;
};

void use(initReactI18next).init({
  // we init with resources
  resources,
  ns: ['general', 'notification', 'button', 'modal'],
  defaultNS: 'general',
  lng: 'en',
  fallbackLng: 'en',
  debug: false,
  interpolation: {
    format: formatFunction as (
      value: number | Date | string,
      format?: string,
      lng?: string
    ) => string,
  },
});

export const Provider = ({ children }: { children: ReactNode }) => (
  <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
);
