import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  ITEM_UPLOAD_STATUS,
  COLLECTION_UPLOAD_STATUS,
} from '@app/config/constants';
import { getUploadStatusByItemId } from '@app/store/items/selectors';
import { getItemUploadProgressById } from '@app/store/selectors';
import type { Item } from '@app/types/items';
import Tooltip from '@component/Tooltip';

import { UploadStatusIcon } from './UploadStatusIcon';

type Props = {
  itemId: Item['id'];
};

export const UploadStatusItem = ({ itemId }: Props) => {
  const { t } = useTranslation();

  const progress = useSelector(getItemUploadProgressById({ id: itemId }));

  const uploadStatus = useSelector(
    getUploadStatusByItemId({
      id: itemId,
    })
  );

  const wrapperClasses =
    'text-white inline-flex rounded-full justify-center items-center bg-black bg-opacity-30 h-5 w-5';

  if (
    uploadStatus === ITEM_UPLOAD_STATUS.QUEUED ||
    uploadStatus === ITEM_UPLOAD_STATUS.UPLOADING
  )
    return (
      <Tooltip
        label={t('upload_status.uploading_item', {
          percent: progress !== undefined ? progress : 0,
        })}
      >
        <span className={wrapperClasses}>
          <UploadStatusIcon uploadStatus={COLLECTION_UPLOAD_STATUS.UPLOADING} />
        </span>
      </Tooltip>
    );

  if (uploadStatus === ITEM_UPLOAD_STATUS.FAILED)
    return (
      <Tooltip
        label={
          <span className="text-red dark:text-red-55">
            {t('upload_status.failed')}
          </span>
        }
      >
        <span className={wrapperClasses}>
          <UploadStatusIcon uploadStatus={COLLECTION_UPLOAD_STATUS.FAILED} />
        </span>
      </Tooltip>
    );

  return null;
};
