import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './Loader.module.scss';

enum LOADER_TYPE {
  BAR = 'BAR',
  DOTS = 'DOTS',
  LOGO = 'LOGO',
}

export type Props = {
  type?: LOADER_TYPE;
  className?: string;
  hasColor?: boolean;
};

export const Loader = ({ className, type, hasColor = true }: Props) => {
  const { t } = useTranslation();
  switch (type) {
    case LOADER_TYPE.BAR: {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 229 17"
          className={className}
        >
          <title>{t('loading')}</title>
          <rect
            width="229"
            height="17"
            rx="8.5"
            className="fill-current text-black opacity-5 dark:text-white dark:opacity-10"
          />
          <mask
            id="LoaderTrackMask"
            width="229"
            height="17"
            x="0"
            y="0"
            maskUnits="userSpaceOnUse"
          >
            <rect width="229" height="17" fill="white" rx="8.5" />
          </mask>
          <g mask="url(#LoaderTrackMask)">
            <rect
              x="-116"
              width="116"
              height="17"
              rx="8.5"
              className={classNames(
                styles.Bar,
                'fill-current',
                hasColor ? [styles.BarColored] : 'text-gray-50 opacity-70'
              )}
            />
          </g>
        </svg>
      );
    }
    case LOADER_TYPE.DOTS: {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 229 56"
          className={className}
        >
          <title>{t('loading')}</title>
          <circle
            cx="201"
            cy="28"
            r="28"
            className={classNames(
              styles.Left,
              'fill-current',
              hasColor ? [styles.LeftColored] : 'text-gray-50 opacity-70'
            )}
          />
          <circle
            cx="114"
            cy="28"
            r="28"
            className={classNames(
              styles.Center,
              'fill-current',
              hasColor ? [styles.CenterColored] : 'text-gray-50 opacity-70'
            )}
          />
          <circle
            cx="28"
            cy="28"
            r="28"
            className={classNames(
              styles.Right,
              'fill-current',
              hasColor ? [styles.RightColored] : 'text-gray-50 opacity-70'
            )}
          />
        </svg>
      );
    }
    case LOADER_TYPE.LOGO:
    default: {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 229 270"
          className={className}
        >
          <title>{t('loading')}</title>
          <path
            className={classNames(
              styles.BottomRight,
              'fill-current',
              hasColor ? [styles.BottomRightColored] : 'text-gray-50 opacity-70'
            )}
            d="M156 233c30 0 39-29 39-59s-10-59-39-59c-30 0-40 29-40 59s9 59 40 59z"
          />
          <path
            className={classNames(
              styles.BottomLeft,
              'fill-current',
              hasColor ? [styles.BottomLeftColored] : 'text-gray-50 opacity-100'
            )}
            d="M102 197c0 21-13 36-36 36s-37-16-37-36 14-34 36-34c21 0 37 11 37 34z"
          />
          <path
            className={classNames(
              styles.TopRight,
              'fill-current',
              hasColor ? [styles.TopRightColored] : 'text-gray-50 opacity-50'
            )}
            d="M186 43c11 12 10 30 7 45-3 13-12 19-24 15-16-6-43-29-51-43-4-7-4-20 11-24 13-4 47-6 57 7z"
          />
          <path
            className={classNames(
              styles.TopLeft,
              'fill-current',
              hasColor ? [styles.TopLeftColored] : 'text-gray-50 opacity-30'
            )}
            d="M65 149c-36 0-38-25-38-56 0-30 2-60 38-60 37 0 39 30 39 60 0 33-2 56-39 56z"
          />
        </svg>
      );
    }
  }
};
Loader.type = LOADER_TYPE;
