import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getUser, getUserHasSubscription } from '@app/store/user/selectors';
import type { SurveyModalProps } from '@app/types/modal';
import Animation from '@component/Animation';
import { useAction } from '@hook/useAction';

export const SurveyModal = ({ closeModal }: SurveyModalProps) => {
  const action = useAction();
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const userHasSubscription = useSelector(getUserHasSubscription);
  const userType = userHasSubscription ? 'p' : 'f';
  const surveyUrl = process.env.REACT_APP_SURVEY_LINK
    ? `${process.env.REACT_APP_SURVEY_LINK}#p=w&s=${userType}&u=${user.id}`
    : 'https://wetransfer.com/404';

  const handleClick = useCallback(() => closeModal(), [closeModal]);

  return (
    <>
      <div className="-mt-4 -ml-4 w-80">
        <Animation type="Survey" />
      </div>
      <Dialog.Title className="ts-h3 mb-3 mt-3 text-black dark:text-white">
        {t('modal:survey.title', 'Hey there \u{1F44B}')}
      </Dialog.Title>
      <Dialog.Description className="ts-b2 text-black dark:text-white">
        {t(
          'modal:survey.description',
          'Now that you’ve spent a bit of time using Collect, we’d love to know how you think we can improve. Do you have 3 minutes to help us out?'
        )}
      </Dialog.Description>
      <div className="flex justify-end mt-3 ml-auto">
        <button
          className={classNames(action.secondary, 'ml-1')}
          onClick={handleClick}
        >
          {t('modal:survey.reject', 'No, thanks')}
        </button>
        <a
          className={classNames(action.primary, 'ml-1')}
          href={surveyUrl}
          target="_blank"
          rel="noreferrer"
          onClick={handleClick}
        >
          {t('modal:survey.accept', 'Sure thing')}
        </a>
      </div>
    </>
  );
};
