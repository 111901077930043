import classNames from 'classnames';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useMatch } from 'react-router-dom';

import type { FILE_TYPE } from '@app/config/constants';
import { ROUTES, URL_SEARCH_PARAMS } from '@app/config/constants';
import { useMatchRoutes } from '@app/hooks/useMatchRoutes';
import { getItemByItemId } from '@app/store/items/selectors';
import { getCollectionByItemId } from '@app/store/selectors';
import type { Item as ItemType } from '@app/types/items';

import ItemContent from './ItemContent';
import { ItemLinkWrapper } from './ItemLinkWrapper';

export type Props = {
  id: ItemType['id'];
  className?: string;
  hasImage?: boolean;
  onImageLoadingError?: (e: string | Event) => void;
  children?: ReactNode;
};

const Item = ({
  id,
  hasImage,
  className,
  onImageLoadingError,
  children,
}: Props) => {
  const item = useSelector(getItemByItemId({ id }));
  const collection = useSelector(getCollectionByItemId({ id }));
  const isFromAll = useMatch(ROUTES.HOME);
  const isEmbedded = useMatchRoutes('embedded');

  const fileType: FILE_TYPE | 'web' = useMemo(
    () => (item !== undefined && 'fileType' in item ? item.fileType : 'web'),
    [item]
  );

  const linkTo = useMemo(
    () => ({
      pathname: generatePath(isEmbedded ? ROUTES.EMBED_ITEM : ROUTES.ITEM, {
        collectionId: collection?.id ?? null,
        itemId: item?.id ?? null,
      }),
      search: isFromAll ? `?${URL_SEARCH_PARAMS.fromAll}` : undefined,
    }),
    [collection?.id, item?.id, isEmbedded, isFromAll]
  );

  if (item === undefined || collection === undefined) {
    return null;
  }

  return (
    <ItemLinkWrapper
      item={item}
      to={linkTo}
      className={classNames(
        className,
        'flex flex-col w-full h-full',
        !hasImage && {
          'bg-yellow-50 text-yellow-70 dark:text-yellow-70':
            fileType === 'document',
          'bg-purple-50 text-purple-70 dark:text-purple-70':
            fileType === 'audio',
          'bg-red-40 text-red-55 dark:text-red-55': fileType === 'unsupported',
          'bg-green-55 text-green-80 dark:text-green-80': fileType === 'web',
          'bg-orange-40 text-orange-80 dark:text-orange-80':
            fileType === 'image',
        }
      )}
    >
      <div className="relative z-auto flex-1 block overflow-hidden text-current dark:text-current">
        <ItemContent
          itemId={item.id}
          fileType={fileType}
          handleError={onImageLoadingError}
        />
      </div>
      {children}
    </ItemLinkWrapper>
  );
};

export default Item;
