import classNames from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MODAL_CONFIRM_TYPE, MODAL_TYPE } from '@app/config/constants';
import { openModal } from '@app/store/modal/actions';
import {
  getCanDeleteCollection,
  getCanEditCollection,
  getCanLeaveCollection,
  getCanReportCollection,
} from '@app/store/selectors';
import type { Collection } from '@app/types/collections';
import Icon from '@component/Icon';
import ListItem from '@component/ListItem';
import Popover from '@component/Popover';
import PopoverAddContent from '@component/PopoverAddContent';
import PopoverMemberships from '@component/PopoverMemberships';
import PopoverShareCollection from '@component/PopoverShareCollection';
import { useToolbarAction } from '@hook/useToolbarAction';

import ListItemLink from '../ListItemLink';

type Props = {
  collection: Collection;
};

export const CollectionDetailActions = ({ collection }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toolbarAction = useToolbarAction();

  const canEditCollection = useSelector(
    getCanEditCollection({ id: collection.id })
  );
  const canDeleteCollection = useSelector(
    getCanDeleteCollection({ id: collection.id })
  );
  const canLeaveCollection = useSelector(
    getCanLeaveCollection({ id: collection.id })
  );
  const canReportCollection = useSelector(
    getCanReportCollection({ id: collection.id })
  );

  const handleDeleteBoard = useCallback(() => {
    dispatch(
      openModal({
        type: MODAL_TYPE.CONFIRM,
        config: {
          type: MODAL_CONFIRM_TYPE.DELETE_BOARD,
          collectionId: collection.id,
        },
      })
    );
  }, [dispatch, collection.id]);

  const handleLeaveBoard = useCallback(() => {
    dispatch(
      openModal({
        type: MODAL_TYPE.CONFIRM,
        config: {
          type: MODAL_CONFIRM_TYPE.LEAVE_BOARD,
          collectionId: collection.id,
        },
      })
    );
  }, [dispatch, collection.id]);

  const handleEditBoard = useCallback(() => {
    dispatch(
      openModal({
        type: MODAL_TYPE.COLLECTION,
        config: {
          collectionId: collection.id,
        },
      })
    );
  }, [dispatch, collection.id]);

  const reportItem = () => {
    const baseUrl = process.env.REACT_APP_CONTENT_MODERATION_URL ?? '';
    const paramUrl = process.env.REACT_APP_CONTENT_MODERATION_PARAM ?? '';
    const encodedParamUrl = encodeURIComponent(paramUrl + collection.id);
    const reportUrl = baseUrl + '?productUrl=' + encodedParamUrl;

    return (
      <ListItemLink href={reportUrl} data-test-id="cta-report-board">
        {t('button:report_board')}
      </ListItemLink>
    );
  };
  return (
    <>
      <span className="mr-3">
        <PopoverMemberships memberships={collection.memberships} />
      </span>
      <span className="mr-4">
        <PopoverShareCollection collection={collection} />
      </span>
      <span className="mr-2 h-7">
        <PopoverAddContent collectionId={collection.id} />
      </span>
      <span>
        <Popover
          trigger={<Icon type="Dots" data-test-id="collection-actions" />}
          buttonClassName={classNames(toolbarAction, 'px-3 py-1')}
        >
          <div>
            {canEditCollection && (
              <ListItem onClick={handleEditBoard} data-test-id="cta-edit-board">
                {t('button:edit_board')}
              </ListItem>
            )}
            {canLeaveCollection && (
              <ListItem
                onClick={handleLeaveBoard}
                data-test-id="cta-leave-board"
              >
                {t('button:leave_board')}
              </ListItem>
            )}
            {canReportCollection && reportItem()}
            {canDeleteCollection && (
              <ListItem
                onClick={handleDeleteBoard}
                data-test-id="cta-delete-board"
              >
                <span className="text-red-60">{t('button:delete_board')}</span>
              </ListItem>
            )}
          </div>
        </Popover>
      </span>
    </>
  );
};
