import classNames from 'classnames';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import type { AppDispatch } from '@app/store';
import { login, signUp } from '@app/store/user/actions';
import type { Collection } from '@app/types/collections';
import Icon from '@component/Icon';
import { useAction } from '@hook/useAction';
import { useSearchParams } from '@hook/useSearchParams';

type Props = {
  collectionId?: Collection['id'];
};

export const PublicCollectionHeader = ({ collectionId }: Props) => {
  const action = useAction();

  const {
    i18n: { language },
  } = useTranslation();
  const {
    params: { token },
  } = useSearchParams();

  const dispatch = useDispatch<AppDispatch>();
  const handleLogin = useCallback(() => {
    void dispatch(
      login({
        language,
        search:
          collectionId !== undefined
            ? `return_to=/${collectionId}${
                token !== undefined ? `&invite_token=${token}` : ''
              }`
            : '',
      })
    );
  }, [dispatch, language, token, collectionId]);
  const handleSignUp = useCallback(() => {
    void dispatch(
      signUp({
        language,
        search:
          collectionId !== undefined
            ? `return_to=/${collectionId}${
                token !== undefined ? `&invite_token=${token}` : ''
              }`
            : '',
      })
    );
  }, [dispatch, language, token, collectionId]);

  return (
    <header className="flex items-center justify-between p-4">
      <a
        title="We"
        href="https://about.wetransfer.com"
        rel="nofollow noreferrer"
        data-test-id="we-logo"
      >
        <Icon type="Logo" />
      </a>
      <a
        className="absolute left-1/2 transform -translate-x-1/2"
        data-test-id="collect-logo"
        title="Collect"
        href="https://collect.bywetransfer.com"
        rel="nofollow noreferrer"
      >
        <Icon type="Collect" />
      </a>
      <div>
        <a
          href="https://wetransfer.zendesk.com/hc/en-us/sections/360000255366-Our-Collect-apps"
          title="Help"
          className={classNames(
            action.tertiarySmall,
            'mr-5 hidden sm:inline-flex'
          )}
        >
          <Trans i18nKey="label.help">Help</Trans>
        </a>

        <button
          onClick={handleLogin}
          title="Log in"
          className={classNames(
            action.tertiarySmall,
            'mr-5 hidden sm:inline-flex'
          )}
        >
          <Trans i18nKey="public.label.login">Login</Trans>
        </button>
        <button
          onClick={handleSignUp}
          title="Create account"
          className={classNames(action.primarySmall, 'hidden sm:inline-flex')}
          data-test-id="create-account-button"
        >
          <Trans i18nKey="public.label.create_account">Create account</Trans>
        </button>
      </div>
    </header>
  );
};
