import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { COLLECTION_TYPE, MODAL_TYPE, ROUTES } from '@app/config/constants';
import { useSearchParams } from '@app/hooks/useSearchParams';
import type { AppDispatch } from '@app/store';
import { fetchCollectionItems } from '@app/store/collections/actions';
import {
  getCollectionById,
  getCollectionMembershipsById,
  getSortedItemIdsByCollectionId,
} from '@app/store/collections/selectors';
import { openModal } from '@app/store/modal/actions';
import { getIsFetchingInitialData } from '@app/store/pending/selectors';
import { getUserId } from '@app/store/user/selectors';
import StorageAlert from '@component/Alert/Storage';
import CollectionDetailActions from '@component/CollectionDetailActions';
import CollectionToolbar from '@component/CollectionToolbar';
import Dropzone from '@component/Dropzone';
import EmptyState from '@component/EmptyState';
import ErrorState from '@component/ErrorState';
import Grid from '@component/Grid';
import { useSelectedItems } from '@hook/useSelectedItems';

const CollectionPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { collectionId } = useParams();
  const { params } = useSearchParams();
  const isFetchingInitialData = useSelector(getIsFetchingInitialData);
  const collection = useSelector(getCollectionById({ id: collectionId }));
  const itemIds = useSelector(
    getSortedItemIdsByCollectionId({
      id: collectionId,
    })
  );
  const memberships = useSelector(
    getCollectionMembershipsById({ id: collectionId })
  );
  const userId = useSelector(getUserId);

  const fetchItems = useCallback(
    (collectionId: string) => {
      return dispatch(fetchCollectionItems(collectionId));
    },
    [dispatch]
  );

  const {
    selectedItems,
    selectAll,
    selectItem,
    clearSelection,
    resetSelectedItems,
    isAllItemsSelected,
  } = useSelectedItems(itemIds);

  const token = useMemo(() => params.invite_token ?? params.token, [params]);
  const isPublicBoard = useMemo(
    () =>
      memberships?.find((membership) => membership.id === userId) === undefined,
    [memberships, userId]
  );
  const isLoading = useMemo(
    () =>
      isFetchingInitialData ||
      (collection !== undefined && itemIds.length < collection.itemCount),
    [isFetchingInitialData, itemIds.length, collection]
  );

  const isEmpty = useMemo(
    () => !isLoading && itemIds.length === 0,
    [isLoading, itemIds]
  );

  // Init
  useEffect(() => {
    resetSelectedItems();
  }, [resetSelectedItems]);

  // Load items
  useEffect(() => {
    if (
      isFetchingInitialData ||
      collection === undefined ||
      collection.itemCount === itemIds.length
    ) {
      return;
    }

    void dispatch(fetchCollectionItems(collection.id));
  }, [isFetchingInitialData, fetchItems, itemIds.length, collection, dispatch]);

  // Decide if we should show the Join Board modal
  useEffect(() => {
    if (
      isLoading ||
      collectionId === undefined ||
      memberships === undefined ||
      !isPublicBoard
    ) {
      return;
    }

    dispatch(
      openModal({
        type: MODAL_TYPE.JOIN_BOARD,
        config: {
          collectionId,
          collaborativeToken: token,
          redirectOnCloseUrl: ROUTES.HOME,
        },
      })
    );
  }, [collectionId, memberships, token, dispatch, isLoading, isPublicBoard]);

  if (collection === undefined && !isFetchingInitialData && !isLoading) {
    return <ErrorState type={ErrorState.type.collectionNotFound} />;
  }

  return (
    <div className="grow px-4 flex flex-col">
      <CollectionToolbar
        title={collection?.name || ''}
        description={collection?.description}
        isCollectionDetail
        collectionId={collectionId}
        selectedItems={selectedItems}
        isAllItemsSelected={isAllItemsSelected}
        onSelectAll={selectAll}
        onClearSelection={clearSelection}
      >
        {collection && <CollectionDetailActions collection={collection} />}
      </CollectionToolbar>
      {collection?.type === COLLECTION_TYPE.PRIVATE && (
        <StorageAlert className="mt-0 mx-4 mb-4" />
      )}
      <Dropzone
        collectionId={collection?.id}
        isEmpty={isEmpty}
        hasDropzoneTarget
      >
        {isEmpty ? (
          <EmptyState collectionId={collection?.id} />
        ) : (
          <div className="mx-4 my-0 h-full">
            <Grid
              itemIds={itemIds}
              isLoading={isLoading}
              selectedItems={selectedItems}
              onSelect={selectItem}
              showItemCaption
            />
          </div>
        )}
      </Dropzone>
    </div>
  );
};

export default CollectionPage;
