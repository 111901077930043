import { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MODAL_MOVE_TYPE, MODAL_TYPE } from '@app/config/constants';
import type { AppDispatch } from '@app/store';
import { addFileItems } from '@app/store/items/actions';
import { openModal } from '@app/store/modal/actions';
import { getCanEditCollection } from '@app/store/selectors';
import type { Collection } from '@app/types/collections';
import Icon from '@component/Icon';
import ListItem from '@component/ListItem';
import Popover from '@component/Popover';
import { useAction } from '@hook/useAction';

type Props = {
  collectionId?: Collection['id'];
  label?: string;
  hasCreateCollectionOption?: boolean;
  isAllItems?: boolean;
};

export const PopoverAddContent = ({
  collectionId,
  label,
  hasCreateCollectionOption,
  isAllItems,
}: Props) => {
  const [inputRef, setInputRef] = useState<HTMLInputElement>();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const action = useAction();

  const canAddContent =
    useSelector(getCanEditCollection({ id: collectionId })) || isAllItems;

  const handleSelectedFiles = useCallback(
    (event: Event) => {
      const files = (event.currentTarget as HTMLInputElement).files;
      if (!files || !files.length) {
        return;
      }

      if (collectionId) {
        void dispatch(addFileItems(files, collectionId));
        return;
      }

      dispatch(
        openModal({
          type: MODAL_TYPE.MOVE_ITEMS,
          config: {
            type: MODAL_MOVE_TYPE.ADD_FILES,
            files,
          },
        })
      );
    },
    [collectionId, dispatch]
  );

  const handleAddFileClick = useCallback(() => inputRef?.click(), [inputRef]);

  const handleAddLinkClick = useCallback(() => {
    dispatch(
      openModal({
        type: MODAL_TYPE.ADD_LINK,
        config: {
          collectionId,
        },
      })
    );
  }, [collectionId, dispatch]);

  const handleCreateCollectionClick = useCallback(() => {
    dispatch(openModal({ type: MODAL_TYPE.COLLECTION }));
  }, [dispatch]);

  const updateInputRef = useCallback((input: HTMLInputElement) => {
    setInputRef(input);
  }, []);

  useEffect(() => {
    if (inputRef) {
      inputRef.addEventListener('change', handleSelectedFiles, false);
    }
    return function cleanup() {
      if (inputRef) {
        inputRef.removeEventListener('change', handleSelectedFiles);
      }
    };
  }, [collectionId, handleSelectedFiles, inputRef]);

  return (
    <Popover
      disabled={!canAddContent}
      buttonClassName={
        label
          ? action.addContent
          : 'w-7 h-7 text-orange hover:text-orange-65 active:text-orange-70 disabled:text-gray-40 dark:disabled:text-gray-60'
      }
      trigger={
        label ? (
          <span className="flex items-center">
            <Icon type="Create" className="fill-current mr-1" />
            <span>{label}</span>
          </span>
        ) : (
          <Icon type="Plus" className="fill-current" data-test-id="cta-add" />
        )
      }
    >
      <>
        <ListItem
          icon="File"
          iconClassName="text-orange"
          onClick={handleAddFileClick}
          data-test-id="cta-add-file"
        >
          {t('button:add_file')}
        </ListItem>
        <ListItem
          icon="Url"
          iconClassName="text-orange"
          onClick={handleAddLinkClick}
          data-test-id="cta-add-link"
        >
          {t('button:add_link')}
        </ListItem>
        <input
          ref={updateInputRef}
          hidden
          type="file"
          multiple
          data-test-id="input-file"
        />
        {hasCreateCollectionOption && (
          <ListItem
            icon="Add"
            iconClassName="text-orange"
            data-test-id="cta-create-new-board"
            onClick={handleCreateCollectionClick}
          >
            {t('button:create_new_board')}
          </ListItem>
        )}
      </>
    </Popover>
  );
};
