import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { Item } from '@app/types/items';
import type { FileUpload, NumberedFilePart } from '@app/types/uploads';

import type { UploaderState } from './types';

function calculateProgress(files: UploaderState['files']) {
  return Object.values(files).reduce((count, file) => {
    if (!file.parts) {
      return 0;
    }

    const parts = Object.values(file.parts);
    const isFileUploaded =
      parts && parts.every((part) => part.progress / part.total === 1);
    const fileProgress = isFileUploaded ? 1 : 0;
    return count + fileProgress;
  }, 0);
}

export const initialState: UploaderState = {
  isUploading: false,
  files: {},
  total: 0,
  progress: 0,
};

export const uploaderSlice = createSlice({
  name: 'uploader',
  initialState,
  reducers: {
    queueFiles: (state, action: PayloadAction<Record<string, FileUpload>>) => {
      state.isUploading = true;
      state.files = {
        ...state.files,
        ...action.payload,
      };
      state.total = Object.keys(state.files).length;
    },
    updateFile: (
      state,
      action: PayloadAction<Partial<FileUpload> & Pick<FileUpload, 'id'>>
    ) => {
      const file = action.payload;
      state.files[file.id] = {
        ...state.files[file.id],
        ...action.payload,
      };
    },
    updateFileParts: (
      state,
      action: PayloadAction<{
        id: FileUpload['id'];
        expiresAt: Date;
        parts: NumberedFilePart[];
      }>
    ) => {
      const { id, expiresAt, parts } = action.payload;

      const newParts: FileUpload['parts'] = {};
      parts.forEach((part) => {
        newParts[part.part_number] = {
          ...state.files[id].parts[part.part_number],
          url: part.url,
          uploadExpires: expiresAt,
        };
      });

      state.files[id].parts = newParts;
    },
    deleteUpload: (state, action: PayloadAction<Item['id']>) => {
      const files = {
        ...state.files,
      };

      delete files[action.payload];

      return {
        ...state,
        isUploading: true,
        progress: calculateProgress(files),
        total: Object.keys(files).length,
        files: {
          ...files,
        },
      };
    },
    resetUploadPart: (
      state,
      action: PayloadAction<{
        id: Item['id'];
        partId: number;
        url: string;
        expiresAt: Date;
      }>
    ) => {
      const { payload } = action;

      return {
        ...state,
        isUploading: true,
        files: {
          ...state.files,
          [payload.id]: {
            ...state.files[payload.id],
            parts: {
              ...state.files[payload.id].parts,
              [payload.partId]: {
                ...state.files[payload.id].parts[payload.partId],
                url: payload.url,
                uploadExpires: payload.expiresAt,
                complete: false,
              },
            },
          },
        },
      };
    },
    setUploadProgress: (
      state,
      action: PayloadAction<{
        id: Item['id'];
        partNumber: number;
        bytesUploaded: number;
        bytesTotal: number;
      }>
    ) => {
      const { payload } = action;

      const files = {
        ...state.files,
        [payload.id]: {
          ...state.files[payload.id],
          parts: {
            ...state.files[payload.id].parts,
            [payload.partNumber]: {
              ...state.files[payload.id].parts[payload.partNumber],
              progress: payload.bytesUploaded,
              total: payload.bytesTotal,
              complete: payload.bytesUploaded === payload.bytesTotal,
            },
          },
        },
      };

      return {
        ...state,
        isUploading: true,
        files,
        progress: calculateProgress(files),
        total: Object.keys(files).length,
      };
    },
    setUploadComplete: () => {
      return {
        ...initialState,
      };
    },
  },
});

export default uploaderSlice.reducer;
