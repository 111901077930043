import { throttle } from 'lodash';
import type { RefObject } from 'react';
import { useCallback, useEffect, useState } from 'react';

export function useScrollListener(
  listenerElement: EventTarget,
  ref: RefObject<HTMLElement>,
  minScrollAmount: number
) {
  const [hasScrolled, setHasScrolled] = useState(false);

  const handleScrollEvent = useCallback(() => {
    const scrollPosition =
      ref.current === null
        ? 0
        : ref.current.getBoundingClientRect().top + ref.current.offsetHeight;

    if (scrollPosition < minScrollAmount && !hasScrolled) {
      setHasScrolled(true);
    } else if (scrollPosition > minScrollAmount && hasScrolled) {
      setHasScrolled(false);
    }
  }, [ref, hasScrolled, minScrollAmount]);

  useEffect(() => {
    const currentElement = listenerElement;
    if (currentElement) {
      currentElement.addEventListener(
        'scroll',
        throttle(handleScrollEvent, 100)
      );
    }
    return () => {
      currentElement?.removeEventListener('scroll', handleScrollEvent);
    };
  }, [hasScrolled, listenerElement, minScrollAmount, handleScrollEvent]);

  return [hasScrolled];
}
