import type { AxiosProgressEvent } from 'axios';

import { UPLOAD_MAX_CHUNKS_PER_REQUEST } from '@app/config/constants';
import type { Item } from '@app/types/items';
import type { FilePart, NumberedFilePart } from '@app/types/uploads';

import { request } from '../helpers';
import type { APIInstances } from '../types';

const createSection = ({ api, publicApi }: APIInstances) => ({
  fetchUploadInfo: async ({
    fileId,
    numberOfParts,
  }: {
    fileId: string;
    numberOfParts: number;
  }) => {
    const requestUploadUrlPages = Math.ceil(
      numberOfParts / UPLOAD_MAX_CHUNKS_PER_REQUEST
    );

    let parts: NumberedFilePart[] = [];
    let uploadExpiresAt = 0;
    for (let index = 0; index < requestUploadUrlPages; index++) {
      const remainingParts =
        numberOfParts - index * UPLOAD_MAX_CHUNKS_PER_REQUEST;

      const uploads = await request<
        {
          failed: {
            id: string;
            error: string;
          }[];
          succeeded: {
            id: string;
            upload_id: string;
            total_parts: number;
            upload_expires_at: number;
            numbered_parts: NumberedFilePart[];
          }[];
        },
        { uploadExpiresAt: number; parts: NumberedFilePart[] }[]
      >({
        call: api.post(`/v4/uploads`, {
          items: [
            {
              file_id: fileId,
              offset: index * UPLOAD_MAX_CHUNKS_PER_REQUEST,
              limit:
                remainingParts < UPLOAD_MAX_CHUNKS_PER_REQUEST
                  ? remainingParts
                  : UPLOAD_MAX_CHUNKS_PER_REQUEST,
            },
          ],
        }),
        transform: ({ succeeded }) =>
          succeeded.map((upload) => ({
            uploadExpiresAt: upload.upload_expires_at,
            parts: upload.numbered_parts,
          })),
      });

      if (uploads.length > 0) {
        uploadExpiresAt = uploads[0].uploadExpiresAt;
        parts = parts.concat(uploads[0].parts);
      }
    }

    return {
      parts,
      numberOfParts,
      uploadExpiryDate: new Date(uploadExpiresAt * 1000),
    };
  },

  fetchUploadUrl: async ({
    itemId,
    partId,
  }: {
    itemId: Item['id'];
    partId: FilePart['id'];
  }) =>
    request<
      {
        succeeded: {
          upload_expires_at: number;
          numbered_parts: NumberedFilePart[];
        }[];
      },
      { url: string; expiresAt: Date }
    >({
      call: api.post(`/v4/uploads`, {
        items: [
          {
            file_id: itemId,
            offset: partId - 1,
            limit: 1,
          },
        ],
      }),
      transform: ({ succeeded }) => {
        if (
          succeeded.length === 0 ||
          succeeded[0].numbered_parts.length === 0
        ) {
          throw new Error('No upload part found');
        }
        return {
          url: succeeded[0].numbered_parts[0].url,
          expiresAt: new Date(succeeded[0].upload_expires_at * 1000),
        };
      },
    }),

  uploadFilePart: async ({
    chunk,
    url,
    onProgress,
  }: {
    url: FilePart['url'];
    chunk: FilePart['chunk'];
    onProgress: (progress: AxiosProgressEvent) => void | Promise<void>;
  }) =>
    request<{
      ok: boolean;
      message: string;
    }>({
      call: publicApi.put(url, chunk, {
        onUploadProgress: (progress: AxiosProgressEvent) => {
          if (onProgress) {
            void onProgress(progress);
          }
        },
      }),
    }),

  completeFileUpload: async ({ fileId }: { fileId: string }) =>
    request<{
      ok: boolean;
      message: string;
    }>({
      call: api.post(`/v1/files/${fileId}/uploads/complete`),
      assert: ({ data }) => data.ok,
    }),
});

export default createSection;
