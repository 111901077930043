import { useTranslation } from 'react-i18next';

import type { Item } from '@app/types/items';
import Avatar from '@component/Avatar';
import Member from '@component/Member';
import Popover from '@component/Popover';

type Props = {
  collectionName?: string;
  item?: Item;
};

export const ItemDetailTitle = ({ collectionName, item }: Props) => {
  const { t } = useTranslation();

  if (!item) {
    return null;
  }

  return (
    <div className="flex w-full items-center">
      {item.owner ? (
        <Popover
          trigger={
            <Avatar
              size="medium"
              id={item.owner.id}
              name={item.owner.name || t('label.unknown_member')}
            />
          }
        >
          <div className="px-2">
            <span className="ts-l6 pb-2 uppercase text-gray-60 dark:text-gray-55">
              {t('label.added_by')}
            </span>
            <Member member={item.owner} />
          </div>
        </Popover>
      ) : (
        <Avatar size="medium" name="Unknown" />
      )}
      <div className="flex-1 min-w-0 ml-3">
        <span
          className="w-full block whitespace-nowrap overflow-hidden text-ellipsis ts-h4"
          data-test-id="item-detail-title"
        >
          {item.meta.title}
        </span>
        <span
          className="w-full block whitespace-nowrap overflow-hidden text-ellipsis ts-l6 text-gray-60 dark:text-gray-55 mt-1"
          data-test-id="item-detail-collection-name"
        >
          {collectionName}
        </span>
      </div>
    </div>
  );
};
