import config from '@app/config';
import type { ITEM_STATE, FILE_TYPE } from '@app/config/constants';
import {
  ITEM_UPLOAD_STATUS,
  CONTENT_IDENTIFIERS,
  ITEM_TYPE,
} from '@app/config/constants';
import type { FileItem, Item, WebItem } from '@app/types/items';

export type BaseItemFromAPI = {
  id: string;
  owner?: {
    name: string;
    given_name: string;
    family_name: string;
    id: string;
  } | null;
  item_order: number;
  content_identifier: CONTENT_IDENTIFIERS;
  created_at: number;
  caption?: string;
};

type ItemPreviewFromAPI =
  // image / document
  | { image: string }
  // video
  | { image: string; video_static: string; video_stream: string }
  // audio
  | { audio: string }
  // unsupported
  | Record<string, never>;

export type FileItemFromAPI = BaseItemFromAPI & {
  content_identifier: CONTENT_IDENTIFIERS.FILE;
  name: string;
  filename: string;
  download_url: string;
  download_url_expires_at?: number;
  is_previewable: boolean;
  preview_url: string | null;
  previews?: ItemPreviewFromAPI;
  size: number;
  state: ITEM_STATE;
  tile_width: number | -1;
  tile_height: number | -1;
};

export type WebItemFromAPI = BaseItemFromAPI & {
  content_identifier: CONTENT_IDENTIFIERS.WEB;
  url: string;
  meta: {
    embedly?: {
      title: string;
      description?: string;
      provider_display: string;
      image_url?: string;
      favicon_url?: string;
    };
    title?: string;
    source?: string;
  };
  tile_width: number | null;
  tile_height: number | null;
};

export type ItemFromAPI = WebItemFromAPI | FileItemFromAPI;

export function getExtensionFromFilename(fileName: string): string | 'UNKNOWN' {
  if (!fileName.includes('.')) {
    return 'UNKNOWN';
  }
  return fileName.substring(fileName.lastIndexOf('.') + 1).toUpperCase();
}

function getFileTypeFromFilename(fileName: string) {
  const extension = getExtensionFromFilename(fileName);
  return ((
    Object.keys(config.supportedFiles) as Array<
      keyof typeof config.supportedFiles
    >
  ).find((type) => (config.supportedFiles[type] ?? []).includes(extension)) ??
    'unsupported') as FILE_TYPE;
}

function makeBaseItem(json: ItemFromAPI) {
  return {
    id: json.id,
    owner:
      json.owner !== undefined && json.owner !== null
        ? {
            id: json.owner.id,
            name: json.owner.name,
          }
        : undefined,
    order: json.item_order,
    createdAt: new Date(json.created_at * 1000),
    caption: json.caption,
    uploadStatus: ITEM_UPLOAD_STATUS.UPLOADED,
  };
}

function makeWebItem(json: WebItemFromAPI): Omit<WebItem, 'collectionId'> {
  const name = json.meta.embedly
    ? json.meta.embedly.title
    : json.meta.title ?? json.url;
  const previewImageUrl = json.meta.embedly?.image_url;

  return {
    ...makeBaseItem(json),
    url: json.url,
    type: ITEM_TYPE.WEB,
    meta: {
      title: name,
      description: json.meta.embedly?.description,
      source: json.meta.embedly
        ? json.meta.embedly.provider_display
        : json.meta.source,
      image: previewImageUrl,
    },
    ...(previewImageUrl !== undefined && {
      preview: {
        name,
        url: previewImageUrl,
        width: Math.max(0, json.tile_width ?? 0),
        height: Math.max(0, json.tile_height ?? 0),
      },
    }),
  };
}

function getDetailUrlFromPreviews(previews: FileItemFromAPI['previews']) {
  if (previews === undefined) {
    return;
  }

  if ('audio' in previews) {
    return previews.audio;
  }
}

export function getDetailUrlExpiryDate(detailUrl?: string) {
  if (detailUrl === undefined) {
    return null;
  }

  try {
    const detailUrlExpiry = new URL(detailUrl.toLowerCase()).searchParams.get(
      'expires'
    );
    if (detailUrlExpiry !== null) {
      return new Date(parseInt(detailUrlExpiry) * 1000);
    }
  } catch (error) {
    // new URL can throw TypeErrors
  }
}

function makeFileItem(json: FileItemFromAPI): Omit<FileItem, 'collectionId'> {
  const fileType = getFileTypeFromFilename(json.name || json.filename);
  const extension = getExtensionFromFilename(json.name || json.filename);
  const extensionReg = new RegExp(`.${extension.toLowerCase()}`, 'i');
  const name = json.name.replace(extensionReg, '');
  const previewImageUrl =
    json.previews !== undefined && 'image' in json.previews
      ? json.previews.image
      : json.preview_url ?? undefined;
  const detailUrl = getDetailUrlFromPreviews(json.previews);

  return {
    ...makeBaseItem(json),
    url: json.download_url,
    downloadUrlExpiresAt: json.download_url_expires_at
      ? new Date(json.download_url_expires_at * 1000)
      : undefined,
    type: ITEM_TYPE.FILE,
    fileType,
    meta: {
      title: name,
      source: '',
    },
    size: json.size,
    state: json.state,
    extension,
    detailUrl,
    currentDetailUrlExpiresAt: getDetailUrlExpiryDate(detailUrl),
    ...(previewImageUrl !== undefined && {
      preview: {
        name,
        url: previewImageUrl,
        width: Math.max(0, json.tile_width || 0),
        height: Math.max(0, json.tile_height || 0),
      },
    }),
  };
}

export function makeItem(json: ItemFromAPI): Omit<Item, 'collectionId'> {
  switch (json.content_identifier) {
    case CONTENT_IDENTIFIERS.FILE:
      return makeFileItem(json);
    case CONTENT_IDENTIFIERS.WEB:
      return makeWebItem(json);
    default:
      throw Error('Incorrect file type passed to makeItem');
  }
}
