import classNames from 'classnames';
import type { ReactNode } from 'react';

import type { IconType } from '@component/Icon';
import Icon from '@component/Icon';

export type ListItemContentProps = {
  children: ReactNode;
  size?: 'regular' | 'large';
  iconClassName?: string;
  icon?: IconType;
};

export const ListItemContent = ({
  children,
  size = 'regular',
  icon,
  iconClassName,
}: ListItemContentProps) => {
  const className = classNames(
    'w-full p-2 inline-flex flex-row justify-start items-center font-normal ts-l2 text-black dark:text-white',
    {
      'h-8': size === 'regular',
      'h-9': size === 'large',
    }
  );

  return (
    <span className={className}>
      {icon && (
        <Icon
          type={icon}
          className={classNames(
            'block mr-2 fill-current',
            {
              'h-4 w-4': size === 'regular',
              'h-5 w-8': size === 'large',
            },
            iconClassName
          )}
        />
      )}
      {children}
    </span>
  );
};
