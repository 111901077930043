import classNames from 'classnames';
import type { MouseEvent, ChangeEvent, FormEventHandler } from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '@component/Icon';
import { useAction } from '@hook/useAction';

type Props = {
  isSearchActive: boolean;
  onSearch: (searchQuery: string) => void;
};

const SidebarSearch = ({ isSearchActive, onSearch }: Props) => {
  const action = useAction();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const inputEl = useRef<HTMLInputElement>(null);

  const handleFormSubmit: FormEventHandler = useCallback(
    (event: ChangeEvent) => {
      event.preventDefault();
    },
    []
  );

  const handleSearchInput = useCallback(
    (event: ChangeEvent) => {
      event.preventDefault();
      setSearchValue((event.target as HTMLInputElement).value);
      onSearch((event.target as HTMLInputElement).value);
    },
    [onSearch]
  );

  const handleClearClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      setSearchValue('');
      onSearch('');
    },
    [onSearch]
  );

  useEffect(() => {
    if (isSearchActive) {
      setSearchValue('');
      inputEl?.current?.focus();
    }
  }, [isSearchActive]);

  return (
    <form
      onSubmit={handleFormSubmit}
      className="w-full h-full flex flex-row items-center bg-white dark:bg-black rounded-lg"
    >
      <Icon
        type="Search"
        className="mx-2 w-5 h-5 fill-current text-gray-50 dark:text-gray-70"
      />
      <input
        ref={inputEl}
        value={searchValue}
        onChange={handleSearchInput}
        type="text"
        placeholder={t('label.search_boards')}
        className="w-full h-full ts-l3 border-none outline-none bg-transparent text-blue dark:text-blue-50"
        disabled={!isSearchActive}
        data-test-id="search-input"
        aria-label={t('label.search_boards')}
      />
      {searchValue.length > 0 && (
        <button
          type="reset"
          onClick={handleClearClick}
          className={classNames(
            action.icon,
            '!bg-transparent !text-gray-50 dark:!text-gray-70 hover:!text-gray-60 dark:hover:!text-gray-50 ml-2 h-full'
          )}
          data-test-id="clear-search-button"
        >
          <Icon type="Cross" className="fill-current" />
        </button>
      )}
    </form>
  );
};

export default SidebarSearch;
