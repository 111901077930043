import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { Item } from '@app/types/items';

import type { UIState } from './types';

export const initialState: UIState = {
  mode: 'light',
  sidebarClosed: false,
  seenOnboarding: false,
  seenSurvey: false,
  ignoreUnsavedChanges: false,
  draggingItemIds: [],
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setUIMode: (state, action: PayloadAction<UIState['mode']>) => {
      state.mode = action.payload;
    },
    setSidebarClosed: (state, action: PayloadAction<boolean>) => {
      state.sidebarClosed = action.payload;
    },
    setIgnoreUnsavedChanges: (state, action: PayloadAction<boolean>) => {
      state.ignoreUnsavedChanges = action.payload;
    },
    hasSeenOnboarding: (state) => {
      state.seenOnboarding = true;
    },
    hasSeenSurvey: (state) => {
      state.seenSurvey = true;
    },
    setDraggingItems: (state, action: PayloadAction<Item['id'][]>) => {
      state.draggingItemIds = action.payload;
    },
  },
});

export default uiSlice.reducer;
