import classNames from 'classnames';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getCollectionById } from '@app/store/collections/selectors';
import { getNotification } from '@app/store/notification/selectors';
import type { ErrorNotificationItemsNotUploadedConfig } from '@app/store/notification/types';
import type { Collection } from '@app/types/collections';
import type { Item } from '@app/types/items';
import Icon from '@component/Icon';
import ItemPreviewLoader from '@component/Item/ItemPreviewLoader';
import { useAction } from '@hook/useAction';
import { useCollectionName } from '@hook/useCollectionName';

import {
  getTitleTranslation,
  getSubTitleTranslation,
  getButtonTranslation,
} from './NotificationTranslations';
import { NOTIFICATION_TYPE } from './types';

export type Props = {
  className?: string;
};

export const Notification = ({ className }: Props) => {
  const { t } = useTranslation();
  const action = useAction();
  const notification = useSelector(getNotification);
  const notificationType = notification?.type;

  const collection = useSelector(
    getCollectionById({
      id:
        (notification?.config as { collectionId?: Collection['id'] })
          ?.collectionId ?? undefined,
    })
  );

  const collectionName = useCollectionName(collection);

  const itemIds = useMemo(() => {
    return (notification?.config as { itemIds?: Item['id'][] })?.itemIds ?? [];
  }, [notification]);

  const itemCount = useMemo(() => {
    return itemIds.length;
  }, [itemIds]);

  const itemId = useMemo(() => {
    if (itemIds.length === 1) {
      return itemIds[0];
    }
    return undefined;
  }, [itemIds]);

  const title = useMemo(
    () =>
      notificationType !== undefined
        ? getTitleTranslation({
            t,
            type: notificationType,
            count: itemCount,
            collectionName,
          })
        : '',
    [t, notificationType, itemCount, collectionName]
  );

  const subTitleText = useMemo(
    () =>
      notificationType !== undefined
        ? getSubTitleTranslation({
            t,
            type: notificationType,
            count: itemCount,
          })
        : '',
    [t, notificationType, itemCount]
  );

  const buttonText = useMemo(
    () =>
      notificationType !== undefined
        ? getButtonTranslation({
            t,
            type: notificationType,
          })
        : '',
    [t, notificationType]
  );

  const isError = useMemo(() => {
    return (
      notificationType !== undefined &&
      [
        NOTIFICATION_TYPE.ERROR,
        NOTIFICATION_TYPE.ERROR_MESSAGE_NOT_ALLOWED_TO_ADD_ITEMS,
        NOTIFICATION_TYPE.ERROR_MESSAGE_NOT_ALL_ITEMS_ARE_SYNCED,
        NOTIFICATION_TYPE.ERROR_MESSAGE_ITEMS_NOT_UPLOADED,
      ].includes(notificationType)
    );
  }, [notificationType]);

  const hasImagePreview = useMemo(() => {
    return (
      notificationType !== undefined &&
      [
        NOTIFICATION_TYPE.SUCCESS_MESSAGE_ITEM_ADDED,
        NOTIFICATION_TYPE.SUCCESS_MESSAGE_ITEM_MOVED,
      ].includes(notificationType) &&
      itemId !== undefined
    );
  }, [notificationType, itemId]);

  const buttonHandler = useMemo(
    () =>
      notificationType === NOTIFICATION_TYPE.SUCCESS_MESSAGE_ITEM_ADDED ||
      notificationType === NOTIFICATION_TYPE.SUCCESS_MESSAGE_ITEM_MOVED ||
      notificationType === NOTIFICATION_TYPE.ERROR_MESSAGE_ITEMS_NOT_UPLOADED
        ? notification?.config.onButtonClicked
        : null,
    [notificationType, notification]
  );

  const hasButton = useMemo(
    () =>
      buttonText !== undefined &&
      buttonHandler !== undefined &&
      itemCount > 0 &&
      (hasImagePreview ||
        notificationType ===
          NOTIFICATION_TYPE.ERROR_MESSAGE_ITEMS_NOT_UPLOADED),
    [buttonHandler, buttonText, itemCount, hasImagePreview, notificationType]
  );

  const handleButtonClicked = useCallback(() => {
    if (
      notificationType === NOTIFICATION_TYPE.ERROR_MESSAGE_ITEMS_NOT_UPLOADED
    ) {
      if (notification?.id && buttonHandler) {
        (
          buttonHandler as ErrorNotificationItemsNotUploadedConfig['onButtonClicked']
        )(notification.id);
      }
    } else {
      if (itemId && buttonHandler) buttonHandler(itemId);
    }
  }, [buttonHandler, itemId, notificationType, notification?.id]);

  if (!notification) {
    return null;
  }

  return (
    <div
      className={classNames(
        'rounded-2xl shadow flex flex-row items-center w-96 min-w-max h-16 p-4 bg-white dark:bg-gray-90',
        className,
        {
          'justify-center': !isError,
        }
      )}
      data-test-id="notification"
    >
      {hasImagePreview && itemId !== undefined && (
        <ItemPreviewLoader
          className="object-cover w-9 h-9 mr-2 rounded-md overflow-hidden"
          itemId={itemId}
        />
      )}
      {isError && (
        <Icon
          type="Warning"
          className="text-red-60 dark:text-red-55 w-[22px] h-[22px] mr-3"
        />
      )}
      <p
        className={classNames({
          'mr-auto':
            hasButton ||
            (hasImagePreview && itemId !== undefined) ||
            subTitleText,
        })}
      >
        {subTitleText && (
          <span
            className="block ts-l4 text-gray-60 dark:text-gray-55"
            data-test-id="notification-subtitle-text"
          >
            {subTitleText}
          </span>
        )}
        <span
          className={classNames(
            'ts-h5 font-bold',
            isError
              ? 'text-red-60 dark:text-red-55'
              : 'text-black dark:text-white'
          )}
          data-test-id="notification-title"
        >
          {title}
        </span>
      </p>
      {hasButton && (
        <button
          className={classNames(action.tertiaryOnGray)}
          onClick={handleButtonClicked}
          data-test-id="notification-button"
        >
          {buttonText}
          <span className="ml-2 transform rotate-180">
            <Icon type="Chevron" className="fill-current h-3" />
          </span>
        </button>
      )}
    </div>
  );
};

export default Notification;
