const Config = {
  social_meta_tag_info: {
    social_title: 'Collect by WeTransfer',
    social_description:
      'The best way to save, organize and share ideas from all your apps',
  },
  navigation_info: {
    enable_forced_redirect: true,
  },
  ios_info: {
    ios_bundle_id: process.env.REACT_APP_DYNAMIC_LINK_IOS_ID,
    ios_app_store_id: '765359021',
  },
  android_info: {
    android_package_name: process.env.REACT_APP_DYNAMIC_LINK_ANDROID_ID,
  },
  analytics_info: {
    itunes_connect_analytics: {
      pt: '658364',
      ct: 'collect-web',
      mt: '8',
    },
    google_play_analytics: {
      utm_source: 'wetransfer',
      utm_medium: 'collect-web-popup',
    },
  },
};

export default Config;
