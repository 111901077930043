import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { COLLECTION_UPLOAD_STATUS } from '@app/config/constants';
import {
  getCollectionUploadStatusById,
  getCollectionUploadTotalById,
  getCollectionUploadRemainingById,
} from '@app/store/selectors';
import type { Collection } from '@app/types/collections';
import Tooltip from '@component/Tooltip';

import { UploadStatusIcon } from './UploadStatusIcon';

type Props = {
  collectionId: Collection['id'];
};

export const UploadStatusCollection = ({ collectionId }: Props) => {
  const { t } = useTranslation();

  const total = useSelector(getCollectionUploadTotalById({ id: collectionId }));
  const remaining = useSelector(
    getCollectionUploadRemainingById({ id: collectionId })
  );

  const progress = total - remaining;

  const uploadStatus = useSelector(
    getCollectionUploadStatusById({
      id: collectionId,
    })
  );

  const tooltipLabel =
    uploadStatus === COLLECTION_UPLOAD_STATUS.FAILED
      ? t('upload_status.failed')
      : uploadStatus === COLLECTION_UPLOAD_STATUS.UPLOADING
      ? t('upload_status.uploading', {
          progress,
          total,
        })
      : '';

  if (uploadStatus === COLLECTION_UPLOAD_STATUS.NONE) return null;

  return (
    <Tooltip
      label={
        <span
          className={classNames({
            'text-red dark:text-red-55':
              uploadStatus === COLLECTION_UPLOAD_STATUS.FAILED,
          })}
        >
          {tooltipLabel}
        </span>
      }
    >
      <span
        className={classNames(
          'text-blue dark:text-blue-55 inline-flex rounded-full justify-center items-center bg-white h-5 w-5',
          uploadStatus === COLLECTION_UPLOAD_STATUS.FAILED
            ? 'text-red dark:text-red-55'
            : 'text-blue dark:text-blue-55'
        )}
      >
        <UploadStatusIcon uploadStatus={uploadStatus} />
      </span>
    </Tooltip>
  );
};
