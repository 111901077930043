import type { TFunction } from 'i18next';

import { NOTIFICATION_TYPE } from '@component/Notification/types';

export function getTitleTranslation({
  count,
  t,
  type,
  collectionName,
}: {
  count: number;
  t: TFunction;
  type: NOTIFICATION_TYPE;
  collectionName: string;
}): string {
  switch (type) {
    case NOTIFICATION_TYPE.INFO_VIEW_ONLY_BOARD:
      return t('notification:view_only_board');

    case NOTIFICATION_TYPE.SUCCESS_MESSAGE_ITEM_ADDED:
    case NOTIFICATION_TYPE.SUCCESS_MESSAGE_ITEM_MOVED:
      return collectionName;

    case NOTIFICATION_TYPE.SUCCESS_MESSAGE_LOG_IN_COMPLETE:
      return t('notification:log_in_success');

    case NOTIFICATION_TYPE.SUCCESS_MESSAGE_CAPTION_DELETED:
      return t('notification:caption_deleted_success', 'Caption deleted');

    case NOTIFICATION_TYPE.SUCCESS_MESSAGE_BOARD_DELETED:
      return t('notification:board_deleted_success');

    case NOTIFICATION_TYPE.SUCCESS_MESSAGE_BOARD_LEFT:
      return t('notification:board_leave_success');

    case NOTIFICATION_TYPE.SUCCESS_MESSAGE_ITEM_INIT_DOWNLOAD:
      return t('notification:item_init_download_success');

    case NOTIFICATION_TYPE.ERROR_MESSAGE_NOT_ALLOWED_TO_ADD_ITEMS:
      return t('notification:item_rejected_not_owner_editor');

    case NOTIFICATION_TYPE.ERROR_MESSAGE_NOT_ALL_ITEMS_ARE_SYNCED:
      return t('notification:item_rejected_not_all_items_are_synced');

    case NOTIFICATION_TYPE.SUCCESS_MESSAGE_ITEM_DELETED:
      return t('notification:item_deleted_success', { count });

    case NOTIFICATION_TYPE.ERROR_MESSAGE_ITEMS_NOT_UPLOADED:
      return t('notification:item_not_uploaded', { count });

    case NOTIFICATION_TYPE.ERROR:
      return t('notification:general_exception');
  }
}

export function getSubTitleTranslation({
  count,
  t,
  type,
}: {
  count: number;
  t: TFunction;
  type: NOTIFICATION_TYPE;
}) {
  switch (type) {
    case NOTIFICATION_TYPE.SUCCESS_MESSAGE_ITEM_ADDED:
      return t('notification:item_added_success', { count });
    case NOTIFICATION_TYPE.SUCCESS_MESSAGE_ITEM_MOVED:
      return t('notification:item_moved_success', { count });
  }
}
export function getButtonTranslation({
  t,
  type,
}: {
  t: TFunction;
  type: NOTIFICATION_TYPE;
}) {
  switch (type) {
    case NOTIFICATION_TYPE.SUCCESS_MESSAGE_ITEM_ADDED:
    case NOTIFICATION_TYPE.SUCCESS_MESSAGE_ITEM_MOVED:
      return t('notification:button_write_caption');
    case NOTIFICATION_TYPE.ERROR_MESSAGE_ITEMS_NOT_UPLOADED:
      return t('notification:button_try_again');
  }
}
