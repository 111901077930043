import { lazy, Suspense } from 'react';

const availableAnimations = {
  AllItemsEmpty: lazy(() => import('./animations/AllItemsEmpty')),
  ClipperCallback: lazy(() => import('./animations/ClipperCallback')),
  CollectionDeleted: lazy(() => import('./animations/CollectionDeleted')),
  CollectionEmpty: lazy(() => import('./animations/CollectionEmpty')),
  NoPreview: lazy(() => import('./animations/NoPreview')),
  NotFound: lazy(() => import('./animations/NotFound')),
  SortLaterEmpty: lazy(() => import('./animations/SortLaterEmpty')),
  Survey: lazy(() => import('./animations/Survey')),
  WelcomeBack: lazy(() => import('./animations/WelcomeBack')),
};

export type AnimationType = keyof typeof availableAnimations;

export const allAnimations = Object.keys(
  availableAnimations
) as AnimationType[];

export interface Props {
  type: AnimationType;
  className?: string;
}

export const Animation = ({ type, className }: Props): JSX.Element => {
  const AnimationComponent = availableAnimations[type];

  return (
    <div className={className} data-test-id={`animation-${type}`}>
      <Suspense fallback={false}>
        <AnimationComponent className={className} />
      </Suspense>
    </div>
  );
};
Animation.types = allAnimations;
