import { COLLECTION_STATE, COLLECTION_TYPE } from '@app/config/constants';
import type { Collection, Membership } from '@app/types/collections';

import type { ItemFromAPI } from './item';

export type CollectionFromAPI = {
  id?: string;
  name?: string;
  title?: string;
  description?: string;
  state?: COLLECTION_STATE;
  collection_type?: COLLECTION_TYPE;
  total_items: number;
  operation_version?: string;
  memberships?: Membership[];
  invite_url?: string;
  shortened_url?: string;
  items?: ItemFromAPI[];
  thumbnails?: string[];
};

function getMembershipRole(memberships: Membership[], userId: string | number) {
  const membership = memberships.find((membership) => {
    return membership.id === userId;
  });

  return membership?.role;
}

export function parseUpdatedAt(version = '') {
  return parseInt(version.substring(version.length - 14));
}

function makePartialCollection(
  json: CollectionFromAPI,
  userId: string | number = 0
) {
  const collectionSlice: Partial<Collection> = {};
  if (json.id) {
    collectionSlice.id = json.id;
  }
  if (json.name || json.title) {
    collectionSlice.name = json.name ?? json.title;
  }
  if (json.description) {
    collectionSlice.description = json.description;
  }
  if (json.state) {
    collectionSlice.state = json.state;
  }
  if (json.collection_type) {
    collectionSlice.type = json.collection_type;
  }
  if (json.operation_version) {
    collectionSlice.version = json.operation_version;
    collectionSlice.updatedAt = parseUpdatedAt(String(json.operation_version));
  }
  if (json.memberships) {
    collectionSlice.memberships = json.memberships;
    if (userId) {
      collectionSlice.membershipRole = getMembershipRole(
        json.memberships,
        userId
      );
    }
  }
  if (json.invite_url) {
    collectionSlice.inviteUrl = json.invite_url;
  }
  if (json.shortened_url) {
    collectionSlice.shortenedUrl = json.shortened_url;
  }
  if (
    json.collection_type === COLLECTION_TYPE.COLLABORATIVE &&
    (json.shortened_url || json.invite_url)
  ) {
    collectionSlice.url = json.shortened_url ?? json.invite_url;
  }
  if (json.total_items) {
    collectionSlice.itemCount = json.total_items;
  }
  if (json.items) {
    collectionSlice.items = json.items.map((item) => item.id);
  }
  if (json.thumbnails) {
    collectionSlice.thumbnail = json.thumbnails.find(
      (thumbnail) => thumbnail !== undefined
    );
  }
  return collectionSlice;
}

export function makeCollection(
  json: CollectionFromAPI,
  userId?: string | number
): Collection;
export function makeCollection(
  json: CollectionFromAPI,
  userId: string | number | undefined,
  partial: true
): Partial<Collection>;
export function makeCollection(
  json: CollectionFromAPI,
  userId: string | number = 0,
  partial = false
) {
  const partialCollection = makePartialCollection(json, userId);
  if (partial) {
    return partialCollection;
  }

  const collection: Collection = {
    id: partialCollection.id ?? '',
    name: partialCollection.name ?? '',
    description: partialCollection.description ?? '',
    state: partialCollection.state ?? COLLECTION_STATE.DOWNLOADABLE,
    type: partialCollection.type ?? COLLECTION_TYPE.PRIVATE,
    version: partialCollection.version ?? '',
    updatedAt: partialCollection.updatedAt ?? 0,
    memberships: partialCollection.memberships ?? [],
    membershipRole:
      partialCollection.membershipRole ?? getMembershipRole([], userId),
    inviteUrl: partialCollection.inviteUrl,
    shortenedUrl: partialCollection.shortenedUrl,
    url: partialCollection.url ?? undefined,
    itemCount:
      partialCollection.itemCount ?? partialCollection.items?.length ?? 0,
    items: partialCollection.items ?? [],
    thumbnail: partialCollection.thumbnail,
  };

  return collection;
}
