import classNames from 'classnames';

import type { Item } from '@app/types/items';
import Icon from '@component/Icon';
import ItemActions from '@component/ItemActions';
import { ItemActionCaption } from '@component/ItemActions/ItemActionCaption';
import { useToolbarAction } from '@hook/useToolbarAction';

import { ItemDetailTitle } from './ItemDetailTitle';

type Props = {
  item?: Item;
  collectionName?: string;
  onClose: () => void;
};

export const ItemDetailToolbar = ({ item, collectionName, onClose }: Props) => {
  const toolbarAction = useToolbarAction();

  return (
    <div className="h-16 px-5 py-3 flex items-center bg-gray-20 dark:bg-black text-black dark:text-white">
      <div className="flex-1 min-w-0">
        <ItemDetailTitle item={item} collectionName={collectionName} />
      </div>
      <div
        className="relative h-full flex items-center ml-auto pl-2"
        data-test-id="item-detail-toolbar"
      >
        {item && (
          <>
            <ItemActionCaption
              collectionId={item.collectionId}
              itemId={item.id}
            />
            <ItemActions collectionId={item.collectionId} itemIds={[item.id]} />
          </>
        )}
        <button
          onClick={onClose}
          data-test-id="close-button"
          className={classNames(toolbarAction, 'w-7 ml-5')}
        >
          <Icon type="Close" className="h-4" />
        </button>
      </div>
    </div>
  );
};
