import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export const initialState: Record<string, boolean> = {
  fetchInitialData: true,
};

export const pendingSlice = createSlice({
  name: 'pending',
  initialState,
  reducers: {
    setPending: (state, action: PayloadAction<string>) => {
      state[action.payload] = true;
    },
    resetPending: (state, action: PayloadAction<string>) => {
      delete state[action.payload];
    },
  },
});

export default pendingSlice.reducer;
