const API_URL = process.env.REACT_APP_SPACESHIP_API_ENDPOINT;
const API_SIGNATURE = process.env.REACT_APP_SPACESHIP_API_SIGNATURE;
const DD_SITE = process.env.REACT_APP_DD_SITE;
const DD_LOG_CLIENT_TOKEN = process.env.REACT_APP_DD_LOG_CLIENT_TOKEN;
const DD_APP_ID = process.env.REACT_APP_DD_APP_ID;
const DD_RUM_CLIENT_TOKEN = process.env.REACT_APP_DD_RUM_CLIENT_TOKEN;
const DD_ENV =
  process.env.REACT_APP_DD_ENV === ''
    ? undefined
    : process.env.REACT_APP_DD_ENV;
const DD_SAMPLE_RATE = process.env.REACT_APP_DD_SAMPLE_RATE; // 0-100
const CENTRAL_ACCOUNT_MANAGEMENT_URL =
  process.env.REACT_APP_CENTRAL_ACCOUNT_MANAGEMENT_URL;

if (
  !API_URL ||
  !API_SIGNATURE ||
  !DD_SITE ||
  !DD_LOG_CLIENT_TOKEN ||
  !DD_APP_ID ||
  !DD_RUM_CLIENT_TOKEN ||
  !DD_ENV ||
  !CENTRAL_ACCOUNT_MANAGEMENT_URL
) {
  throw new Error(
    'Please make sure all environment variables are properly set'
  );
}

const config = Object.freeze({
  allItems: {
    batchSize: 20,
  },
  api: {
    url: API_URL,
    signature: API_SIGNATURE,
    device_prefix: 'wt-portia-',
  },
  centralAccountManagement: {
    url: CENTRAL_ACCOUNT_MANAGEMENT_URL,
  },
  monitor: {
    site: DD_SITE,
    logClientToken: DD_LOG_CLIENT_TOKEN,
    applicationId: DD_APP_ID,
    rumClientToken: DD_RUM_CLIENT_TOKEN,
    env: DD_ENV,
    sampleRate: Number(DD_SAMPLE_RATE ?? 1),
  },
  supportedFiles: {
    image: ['JPG', 'JPEG', 'PNG'],
    audio: ['MP3'],
    document: ['PDF', 'PSD', 'TIF', 'TIFF'],
  },
  fileTypesWithMeta: ['audio', 'document', 'unsupported'],
  fetchDiffInterval: 15 * 1000,
});

export default config;
