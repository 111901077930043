import { groupBy, flatten } from 'lodash';

import config from '@app/config';
import { CONTENT_IDENTIFIERS, MAX_ITEMS_PER_PAGE } from '@app/config/constants';
import { getRandomToken } from '@app/helper';
import type {
  FileItemFromAPI,
  ItemFromAPI,
  WebItemFromAPI,
} from '@app/transformers/item';
import type { Collection } from '@app/types/collections';
import type { Item } from '@app/types/items';
import type { PreviewSize } from '@app/types/previews';

import { request } from '../helpers';
import type { APIInstances } from '../types';

const createSection = ({ api, publicApi }: APIInstances) => ({
  fetchSingle: async ({
    collectionId,
    itemId,
  }: {
    collectionId: Collection['id'];
    itemId: Item['id'];
  }) =>
    request<ItemFromAPI>({
      call: api.get(`/v2/mobile/collections/${collectionId}/items/${itemId}`),
    }),

  fetchPerCollection: async ({
    collectionId,
    totalItems,
    isPublic = false,
  }: {
    collectionId: Item['collectionId'];
    totalItems: number;
    isPublic?: boolean;
  }) => {
    const itemBatches = await Promise.all(
      Array.from({ length: Math.ceil(totalItems / MAX_ITEMS_PER_PAGE) }).map(
        (_, index) =>
          request<ItemFromAPI[]>({
            call: api.get(
              isPublic
                ? `/v2/web/collections/${collectionId}/public/items`
                : `/v2/mobile/collections/${collectionId}/items`,
              {
                params: {
                  offset: MAX_ITEMS_PER_PAGE * index,
                },
              }
            ),
          })
      )
    );

    return flatten(itemBatches);
  },

  fetchAllItems: async ({ batch = 0 }: { batch: number }) =>
    request<Array<ItemFromAPI & { collection_id: string }>>({
      call: api.get('/v2/collections/items/all', {
        params: {
          limit: config.allItems.batchSize,
          offset: batch * config.allItems.batchSize,
        },
      }),
    }),

  delete: async ({ items }: { items: Item[] }) => {
    if (items.length === 0) {
      return;
    }

    const itemsGroupedByCollection = groupBy(items, 'collectionId');

    await Promise.all(
      Object.keys(itemsGroupedByCollection).map((collectionId) => {
        const ids = itemsGroupedByCollection[collectionId].map(
          (item) => item.id
        );
        return request({
          call: api.patch(`/v1/collections/${collectionId}/items`, {
            ids,
          }),
        });
      })
    );
  },

  move: async ({
    toCollectionId,
    itemIds,
  }: {
    toCollectionId: Collection['id'];
    itemIds: Item['id'][];
  }) => {
    if (!itemIds.length) {
      return Promise.resolve([]);
    }

    return request<ItemFromAPI[]>({
      call: api.post(`/v2/collections/${toCollectionId}/move`, itemIds),
    });
  },

  addWebItem: async ({
    collectionId,
    url,
    meta,
  }: {
    collectionId: Collection['id'];
    url: Item['url'];
    meta: WebItemFromAPI['meta'];
  }) => {
    const { id: itemId } = await request<WebItemFromAPI[], WebItemFromAPI>({
      call: api.post(`/v2/collections/${collectionId}/items`, {
        items: [
          {
            local_identifier: getRandomToken().substring(0, 32),
            content_identifier: CONTENT_IDENTIFIERS.WEB,
            url,
            meta,
          },
        ],
      }),
      transform: (data) => data[0],
    });

    return request<ItemFromAPI>({
      call: api.get(`/v2/mobile/collections/${collectionId}/items/${itemId}`),
    });
  },

  addFileItem: async ({
    collectionId,
    filename,
    filesize,
  }: {
    collectionId: Collection['id'];
    filename?: string;
    filesize?: number;
  }) =>
    request<FileItemFromAPI[], FileItemFromAPI>({
      call: api.post(`/v2/collections/${collectionId}/items`, {
        items: [
          {
            local_identifier: getRandomToken().substring(0, 32),
            content_identifier: CONTENT_IDENTIFIERS.FILE,
            filename,
            filesize,
          },
        ],
      }),
      transform: (data) => data[0],
    }),

  download: async ({
    collectionId,
    itemIds,
    isPublic = false,
  }: {
    collectionId: Collection['id'];
    itemIds: Item['id'][];
    isPublic?: boolean;
  }) =>
    request<{ download_url: string }, string>({
      call: api.post(
        isPublic
          ? `/v2/web/downloads/${collectionId}/public`
          : `/v2/downloads/${collectionId}`,
        { file_ids: itemIds }
      ),
      transform: ({ download_url }) => download_url,
    }),

  fetchUrlInfo: async ({ url }: { url: string }) =>
    request<WebItemFromAPI['meta']['embedly']>({
      call: api.post(`/v1/mobile/embedly`, { url }),
    }),

  fetchDetailUrl: async ({
    collectionId,
    itemId,
    size = 'medium',
  }: {
    collectionId: Collection['id'];
    itemId: Item['id'];
    size?: PreviewSize;
  }) =>
    request<
      {
        url: string | null;
      },
      string | null
    >({
      call: api.get(`/v2/previews/${collectionId}/${itemId}/${size}`),
      transform: ({ url }) => url,
    }),

  updateCaption: async ({
    itemId,
    caption,
  }: {
    itemId: Item['id'];
    caption?: Item['caption'];
  }) =>
    request<ItemFromAPI>({
      call: api.post(`/v2/items/${itemId}/captions`, { text: caption }),
    }),

  removeCaption: async ({ itemId }: { itemId: Item['id'] }) =>
    request<ItemFromAPI>({
      call: api.delete(`/v2/items/${itemId}/caption`),
    }),
});

export default createSection;
