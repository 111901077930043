import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useAction } from '@app/hooks/useAction';
import type { WarningModalProps } from '@app/types/modal';

export const WarningModal = ({ closeModal }: WarningModalProps) => {
  const action = useAction();
  const { t } = useTranslation();

  const modal = {
    title: t('modal:warning.title'),
    description: t('modal:warning.description'),
    confirm: t('modal:warning.button'),
    handleClick: () => {
      closeModal();
    },
  };

  return (
    <>
      <Dialog.Title
        className="ts-h3 mb-1 text-black dark:text-white"
        data-test-id="modal-confirm-title"
      >
        {modal.title}
      </Dialog.Title>
      <Dialog.Description
        className="ts-b2 mb-4 dark:text-white"
        data-test-id="modal-confirm-description"
      >
        {modal.description}
      </Dialog.Description>
      <div className="flex justify-end">
        <button
          type="button"
          className={classNames(action.primary)}
          onClick={modal.handleClick}
          data-test-id="cta-confirm-warning-action"
        >
          {modal.confirm}
        </button>
      </div>
    </>
  );
};
