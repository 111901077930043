import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import { useAction } from '@hook/useAction';

export type Props = {
  bytesUsed: number;
  bytesLimit: number;
  percentage: number;
  isFull: boolean;
  userCanUpgrade: boolean;
};

export const StorageStatus = ({
  bytesUsed,
  bytesLimit,
  percentage,
  isFull,
  userCanUpgrade,
}: Props) => {
  const action = useAction();
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <div className="relative w-full h-1 rounded-sm bg-gray-40">
        <div
          className={classNames(
            'absolute top-0 left-0 h-full max-w-full rounded-sm transition-all',
            {
              'bg-red': isFull,
              'bg-blue': !isFull,
            }
          )}
          style={{ width: `${percentage}%` }}
        ></div>
      </div>

      <p
        className={classNames('ts-l3 mt-1', {
          'text-red': isFull,
        })}
        data-test-id="storage-usage-text"
      >
        {t('storage_usage.amount_used', {
          used: Math.min(bytesUsed, bytesLimit), // never show users that they can go beyond limit
          limit: bytesLimit,
        })}
      </p>
      {(isFull || userCanUpgrade) && (
        <p className="ts-b2 mt-3">
          {isFull ? (
            !userCanUpgrade ? (
              <Trans i18nKey="storage_usage.hit_storage_limit_pro">
                You&apos;re out of storage and may have to delete some items.
                Interested in more storage? You can{' '}
                <a
                  href="https://wetransfer.zendesk.com/hc/en-us/requests/new?ticket_form_id=61235"
                  title="Suggest a feature"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue"
                >
                  suggest a feature
                </a>{' '}
                (no promises!).
              </Trans>
            ) : (
              t('storage_usage.hit_storage_limit_free')
            )
          ) : (
            userCanUpgrade && t('storage_usage.upsell')
          )}
        </p>
      )}
      {userCanUpgrade && (
        <a
          href="https://wetransfer.com/pricing"
          title={t('button:wetransfer_premium', 'WeTransfer Plans')}
          target="_blank"
          rel="noopener noreferrer"
          className={classNames(action.primarySmall, 'mt-2')}
        >
          {t('button:wetransfer_premium', 'WeTransfer Plans')}
        </a>
      )}
    </div>
  );
};
