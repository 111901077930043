import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import type { AppDispatch } from '@app/store';
import { fetchCollectionItems } from '@app/store/collections/actions';
import {
  getCollectionById,
  getSortedItemIdsByCollectionId,
} from '@app/store/collections/selectors';
import { getIsFetchingInitialData } from '@app/store/pending/selectors';
import { getUserUnsortedId } from '@app/store/user/selectors';
import StorageAlert from '@component/Alert/Storage';
import CollectionToolbar from '@component/CollectionToolbar';
import Dropzone from '@component/Dropzone';
import EmptyState from '@component/EmptyState';
import Grid from '@component/Grid';
import PopoverAddContent from '@component/PopoverAddContent';
import { useSelectedItems } from '@hook/useSelectedItems';

const SortLaterPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isFetchingInitialData = useSelector(getIsFetchingInitialData);
  const collectionId = useSelector(getUserUnsortedId);
  const collection = useSelector(getCollectionById({ id: collectionId }));
  const itemIds = useSelector(
    getSortedItemIdsByCollectionId({ id: collectionId })
  );
  const fetchItems = useCallback(() => {
    if (collectionId === undefined) {
      return;
    }
    void dispatch(fetchCollectionItems(collectionId));
  }, [dispatch, collectionId]);

  const { t } = useTranslation();
  const {
    selectedItems,
    selectAll,
    selectItem,
    clearSelection,
    isAllItemsSelected,
  } = useSelectedItems(itemIds);

  const isLoading = useMemo(
    () =>
      isFetchingInitialData ||
      itemIds.length < (collection?.itemCount ?? Infinity),
    [isFetchingInitialData, itemIds.length, collection?.itemCount]
  );

  const isEmpty = useMemo(
    () => itemIds && itemIds.length === 0 && !isLoading,
    [itemIds, isLoading]
  );

  // Load items
  useEffect(() => {
    if (collection === undefined || collection.itemCount === itemIds.length) {
      return;
    }

    fetchItems();
  }, [fetchItems, itemIds.length, collection]);

  return (
    <div className="grow px-4 flex flex-col">
      <CollectionToolbar
        title={t('title.sort_later')}
        description={t('description.sort_later')}
        collectionId={collectionId}
        selectedItems={selectedItems}
        isAllItemsSelected={isAllItemsSelected}
        onSelectAll={selectAll}
        onClearSelection={clearSelection}
      >
        <span className="h-7">
          <PopoverAddContent collectionId={collectionId} />
        </span>
      </CollectionToolbar>
      <StorageAlert className="mt-0 mx-4 mb-4" />
      <Dropzone collectionId={collectionId} isEmpty={isEmpty} hasDropzoneTarget>
        {isEmpty ? (
          <EmptyState collectionId={collection?.id} />
        ) : (
          <Grid
            isLoading={isLoading}
            itemIds={itemIds}
            selectedItems={selectedItems}
            onSelect={selectItem}
            showItemCaption
          />
        )}
      </Dropzone>
    </div>
  );
};

export default SortLaterPage;
