import classNames from 'classnames';
import type { AnchorHTMLAttributes } from 'react';

import Icon from '@component/Icon';
import type { ListItemContentProps } from '@component/ListItemContent';
import ListItemContent from '@component/ListItemContent';

type ListItemLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> &
  ListItemContentProps;

export const ListItemLink = ({
  children,
  className,
  ...props
}: ListItemLinkProps) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(
        'w-full inline-block rounded-xl hover:bg-gray-20 dark:hover:bg-black dark:hover:bg-opacity-70',
        className
      )}
      {...props}
    >
      <ListItemContent>
        {children}
        <Icon
          type="ExternalLink"
          className="ml-auto h-3 fill-current text-gray-60 dark:text-gray-55"
        />
      </ListItemContent>
    </a>
  );
};
