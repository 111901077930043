import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import type { AppDispatch } from '@app/store';
import { joinCollection as joinCollectionAction } from '@app/store/collections/actions';
import {
  getCollectionById,
  getCollectionMembershipsById,
} from '@app/store/collections/selectors';
import type { CollectionFromAPI } from '@app/transformers/collection';
import type { JoinBoardModalProps } from '@app/types/modal';
import AvatarList from '@component/AvatarList';
import { useAction } from '@hook/useAction';
import { track } from '@module/analytics';

export type Props = Pick<JoinBoardModalProps, 'closeModal'> & {
  joinCollection: () => void;
  title: string;
  description: string;
  memberships: CollectionFromAPI['memberships'];
};

export const JoinBoardModalContent = ({
  closeModal,
  joinCollection,
  title,
  description,
  memberships,
}: Props) => {
  const { t } = useTranslation();
  const action = useAction();

  const handleOnClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      joinCollection();
      track('bucket_invite_joined');
      closeModal();
    },
    [closeModal, joinCollection]
  );

  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      closeModal();
    },
    [closeModal]
  );

  return (
    <>
      <h4 className="text-center ts-l6 uppercase text-gray-60 mt-2 mb-4 dark:text-gray-55">
        {t('join.subtitle')}
      </h4>
      <Dialog.Title className="ts-h2 text-center text-black dark:text-white">
        {title}
      </Dialog.Title>
      <Dialog.Description className="ts-l6 mt-1 text-center text-black dark:text-white">
        {description}
      </Dialog.Description>
      <div className="flex items-center flex-col mb-6 mt-4">
        <AvatarList members={memberships} limit={4} />
      </div>
      <div className="flex flex-col items-center">
        <button
          className={action.primary}
          type="button"
          onClick={handleOnClick}
          data-test-id="cta-confirm-dangerous-action"
        >
          {t('button:join_board')}
        </button>
        <button
          className={classNames(action.tertiary, 'mt-1')}
          type="button"
          onClick={handleClose}
        >
          {t('button:cancel')}
        </button>
      </div>
    </>
  );
};

const JoinBoardModal = (props: JoinBoardModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { collectionId, collaborativeToken } = props.config;
  const collection = useSelector(getCollectionById({ id: collectionId }));
  const memberships = useSelector(
    getCollectionMembershipsById({ id: collectionId })
  );

  const joinCollection = useCallback<() => void>(
    () => dispatch(joinCollectionAction(collectionId, collaborativeToken)),
    [dispatch, collectionId, collaborativeToken]
  );

  return (
    <JoinBoardModalContent
      title={collection?.name ?? ''}
      description={collection?.description ?? ''}
      closeModal={props.closeModal}
      joinCollection={joinCollection}
      memberships={memberships}
    />
  );
};

export default JoinBoardModal;
