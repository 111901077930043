import { InView } from 'react-intersection-observer';

import type { FILE_TYPE } from '@app/config/constants';
import type { Item } from '@app/types/items';
import type { IconType } from '@component/Icon';
import Icon from '@component/Icon';

import type { Props as ImageProps } from './ItemPreviewLoader';
import ItemPreviewLoader from './ItemPreviewLoader';

type Props = Pick<ImageProps, 'handleError'> & {
  fileType: FILE_TYPE | 'web';
  itemId: Item['id'];
};

const iconByFileType: Record<Props['fileType'], IconType> = {
  image: 'Image',
  audio: 'Audio',
  document: 'Document',
  unsupported: 'Unsupported',
  web: 'Web',
};

const ItemContent = ({ fileType, itemId, handleError, ...props }: Props) => {
  return (
    <InView triggerOnce={true}>
      {({ ref, inView }) => (
        <div
          {...props}
          ref={ref}
          className="flex h-full items-center justify-center overflow-hidden"
          draggable="false"
        >
          {inView && (
            <ItemPreviewLoader
              itemId={itemId}
              className="relative w-full h-full"
              handleError={handleError}
              fallback={
                <Icon
                  type={iconByFileType[fileType]}
                  className="fill-current absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                />
              }
            />
          )}
        </div>
      )}
    </InView>
  );
};

export default ItemContent;
