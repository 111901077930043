import classnames from 'classnames';

export enum Variant {
  primary = 'primary',
  primarySmall = 'primarySmall',
  secondary = 'secondary',
  secondarySmall = 'secondarySmall',
  secondarySmallOnGray = 'secondarySmallOnGray',
  tertiary = 'tertiary',
  tertiaryOnGray = 'tertiaryOnGray',
  tertiarySmall = 'tertiarySmall',
  critical = 'critical',
  bundle = 'bundle',
  icon = 'icon',
  addContent = 'addContent',
}
type Variants = Record<Variant, string>;

const createVariant = (variant: Variant) =>
  classnames(
    // Apply to all buttons
    'cursor-pointer disabled:cursor-default inline-flex justify-center items-center focus:outline-none focus-visible:ring-2 ring-inset focus-visible:ring-blue font-normal transition-all',
    {
      // All regular buttons
      'rounded-xl px-3 py-2':
        variant === Variant.primary ||
        variant === Variant.secondary ||
        variant === Variant.tertiary ||
        variant === Variant.tertiaryOnGray ||
        variant === Variant.critical ||
        variant === Variant.addContent,
      // Everything but bundle
      'ts-l3': variant !== Variant.bundle,
      // All small buttons except tertiarySmall
      'rounded-xl px-3 py-1.5':
        variant === Variant.primarySmall ||
        variant === Variant.secondarySmall ||
        variant === Variant.secondarySmallOnGray,
      // Primary button
      'text-white dark:text-white bg-blue dark:bg-blue-50 hover:bg-blue-70 dark:hover:bg-blue active:bg-blue-80 dark:active:bg-blue-70  disabled:text-white dark:disabled:text-gray-50 disabled:bg-gray-40 dark:disabled:bg-gray-60':
        variant === Variant.primary,
      // Primary small & secondary buttons
      'text-blue dark:text-blue-70 bg-blue-20 dark:bg-blue-30 hover:bg-blue-30 dark:hover:bg-blue-40 active:bg-blue-40 dark:active:bg-blue-50 active:text-blue-70 disabled:text-white dark:disabled:text-gray-50 disabled:bg-gray-40 dark:disabled:bg-gray-60':
        variant === Variant.primarySmall || variant === Variant.secondary,
      // Secondary small & tertiary buttons
      'text-blue dark:text-blue-50 bg-transparent hover:bg-gray-20 active:bg-gray-20 active:text-blue-70 dark:active:text-blue disabled:text-gray-50 dark:disabled:text-gray-70 disabled:bg-transparent':
        variant === Variant.secondarySmall ||
        variant === Variant.secondarySmallOnGray ||
        variant === Variant.tertiary ||
        variant === Variant.tertiaryOnGray,
      'hover:bg-gray-20 active:bg-gray-20':
        variant === Variant.secondarySmall ||
        variant === Variant.tertiary ||
        variant === Variant.tertiaryOnGray,
      'hover:bg-white active:bg-white':
        variant === Variant.secondarySmallOnGray,
      'dark:hover:bg-gray-90 dark:active:bg-gray-90':
        variant === Variant.secondarySmall ||
        variant === Variant.secondarySmallOnGray ||
        variant === Variant.tertiary,
      'dark:hover:bg-black dark:active:bg-black':
        variant === Variant.tertiaryOnGray,
      // Tertiary small button
      'text-blue dark:text-blue-50 hover:text-blue-70 dark:hover:text-blue active:text-blue-80 dark:active:text-blue-70 disabled:text-gray-50 dark:disabled:text-gray-70':
        variant === Variant.tertiarySmall,
      // Critical button
      'text-red-55 bg-transparent hover:bg-gray-20 dark:hover:bg-gray-90 active:bg-gray-20 dark:active:bg-gray-90 active:text-red-60 disabled:text-gray-50 dark:disabled:text-gray-70 disabled:bg-transparent':
        variant === Variant.critical,
      // Bundle button
      'rounded-full px-6 py-2.5 text-white dark:text-gray-90 bg-black dark:bg-white hover:bg-gray-90 dark:hover:bg-gray-20 active:bg-gray-80 dark:active:bg-gray-20  disabled:bg-gray-40 dark:disabled:bg-gray-60 dark:disabled:text-black ts-l1':
        variant === Variant.bundle,
      'm-w-0 h-auto p-2 rounded opacity-100 disabled:opacity-0 fill-current text-gray-60 dark:text-gray-55 hover:bg-gray-20 dark:hover:bg-gray-90 active:text-black dark:active:text-white':
        variant === Variant.icon,
      'text-white dark:text-black bg-orange hover:bg-orange-65 active:bg-orange-70 dark:active:bg-orange-70 disabled:text-white dark:disabled:text-black disabled:bg-gray-40 dark:disabled:bg-gray-60':
        variant === Variant.addContent,
    }
  );

export const variants: Variants = Object.values(Variant).reduce(
  (newVariants, variant) => ({
    ...newVariants,
    [variant]: createVariant(variant as Variant),
  }),
  {} as Variants
);

export const useAction = () => variants;
