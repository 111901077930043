import type { AxiosResponse, AxiosError } from 'axios';

class ApiAssertionError extends Error {
  public config: AxiosError['config'];

  constructor(config: AxiosError['config']) {
    super('Assertion failed');
    this.config = config;
  }
}

class ApiTransformationError extends Error {
  public config: AxiosError['config'];

  constructor(config: AxiosError['config'], message: string) {
    super(`Transformation failed: ${message}`);
    this.config = config;
  }
}

class ApiError extends Error {
  public status = -1;
  public errorCode?: string;
  public body?: unknown;

  constructor(error: AxiosError | ApiAssertionError | ApiTransformationError) {
    super(
      `[API] Unable to ${error.config?.method?.toUpperCase() ?? 'REQUEST'} ${
        error.config?.url ?? 'data from api'
      } - ${error.message}`
    );

    if ('isAxiosError' in error) {
      this.status = error.response?.status ?? -1;
      this.errorCode = (
        error.response as AxiosResponse<{ error_key?: string }> | undefined
      )?.data.error_key;
      this.body = error.response?.data;
    }

    // Strip this Error and the request helper from the stack trace
    if (this.stack !== undefined) {
      this.stack = this.stack.split('\n').slice(3).join('\n');
    }
  }
}

// plain request type
async function request<Data = void>(config: {
  call: Promise<AxiosResponse<Data>>;
  assert?: (data: AxiosResponse<Data>) => boolean;
}): Promise<Data>;
// request type with transform function
async function request<Data, TransformedData>(config: {
  call: Promise<AxiosResponse<Data>>;
  transform: (data: Data) => TransformedData;
  assert?: (data: AxiosResponse<Data>) => boolean;
}): Promise<TransformedData>;
// request function
async function request<Data, TransformedData>({
  call,
  assert,
  transform,
}: {
  call: Promise<AxiosResponse<Data>>;
  transform?: (data: Data) => TransformedData;
  assert?: (data: AxiosResponse<Data>) => boolean;
}) {
  const data = await call
    .then((response) => {
      if (response instanceof Error) {
        throw response;
      }

      if (assert !== undefined && !assert(response)) {
        throw new ApiAssertionError(response.config);
      }

      if (transform !== undefined) {
        try {
          return transform(response.data);
        } catch (error) {
          throw new ApiTransformationError(
            response.config,
            (error as Error).message
          );
        }
      }

      return response.data;
    })
    .catch((error: AxiosError | ApiAssertionError) => {
      throw new ApiError(error);
    });

  return data;
}
export { request };

export const isApiError = (error: unknown): error is ApiError => {
  return error instanceof ApiError;
};
