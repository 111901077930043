import type { AnyAction } from '@reduxjs/toolkit';
import { batchActions } from 'redux-batched-actions';

import { COLLECTION_TYPE, MODAL_TYPE } from '@app/config/constants';
import type { AppThunk } from '@app/store';
import { addCollection } from '@app/store/collections/actions';
import { addItems } from '@app/store/items/actions';
import { openModal } from '@app/store/modal/actions';
import { setPending, resetPending } from '@app/store/pending/actions';
import { getShouldShowOnboarding } from '@app/store/ui/selectors';
import { getUser } from '@app/store/user/selectors';
import { makeCollection } from '@app/transformers/collection';
import { makeItem } from '@app/transformers/item';
import type { Collection } from '@app/types/collections';
import API, { isApiError } from '@module/api';
import Monitor from '@module/Monitor';

export function fetchInitialData({
  isLoggedIn,
  collectionId,
}: {
  isLoggedIn: boolean;
  collectionId?: Collection['id'];
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const batchedActions: AnyAction[] = [];
    const pendingId = 'fetchInitialData';
    dispatch(setPending(pendingId));

    if (!isLoggedIn) {
      // At this point the user has not logged in, and could be viewing a
      // public board or embedded board, no need to continue loading state
      if (collectionId !== undefined) {
        const publicActions =
          await addPublicCollectionActionsToBatch(collectionId);
        batchedActions.push(...publicActions);
      }
      dispatch(batchActions(batchedActions));
      dispatch(resetPending(pendingId));
      return;
    }

    // Start loading the initial state needed to render the app
    const state = getState();
    const user = getUser(state);
    try {
      const collections = await API.collections.fetchAll();

      // Add all the collections to the state
      for (const collection of collections) {
        if (collection.id === undefined) {
          throw new Error(`Invalid collection in fetchInitialData`);
        }

        batchedActions.push(
          addCollection({
            collection: makeCollection(collection, user.id),
          })
        );
      }

      // When the user loads the app on a collection that is shared with them
      // but has not joined it yet
      if (
        collectionId !== undefined &&
        collections.find((collection) => collection.id === collectionId) ===
          undefined
      ) {
        const publicActions =
          await addPublicCollectionActionsToBatch(collectionId);
        batchedActions.push(...publicActions);
      }

      dispatch(batchActions(batchedActions));
    } catch (error) {
      Monitor.error(error);
    }
    dispatch(resetPending(pendingId));

    if (getShouldShowOnboarding(getState())) {
      dispatch(openModal({ type: MODAL_TYPE.ONBOARDING }));
    }
  };
}

async function addPublicCollectionActionsToBatch(
  collectionId: Collection['id']
) {
  const publicData = await loadPublicCollection(collectionId);
  if (publicData !== undefined) {
    return [
      addCollection({
        collection: publicData.collection,
      }),
      addItems({
        collectionId,
        items: publicData.items,
      }),
    ];
  }
  return [];
}

async function loadPublicCollection(collectionId: Collection['id']) {
  try {
    const collection = await API.collections.fetchSingle({
      collectionId,
      isPublic: true,
    });

    const items = await API.items.fetchPerCollection({
      collectionId,
      totalItems: collection.total_items,
      isPublic: true,
    });
    return {
      collection: makeCollection({
        ...collection,
        collection_type: COLLECTION_TYPE.PUBLIC,
      }),
      items: items.map(makeItem),
    };
  } catch (error) {
    if (isApiError(error) && error.status === 404) {
      return;
    }
    Monitor.error(error);
  }
}
