import classNames from 'classnames';
import { useEffect } from 'react';

import Icon from '@component/Icon';

type Props = {
  hidden?: boolean;
  hasNextItem: boolean;
  hasPrevItem: boolean;
  onNextClick: () => void;
  onPrevClick: () => void;
  onCancel: () => void;
};

export const ItemDetailArrowNavigation = ({
  hidden = false,
  hasNextItem,
  hasPrevItem,
  onNextClick,
  onPrevClick,
  onCancel,
}: Props) => {
  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  });

  function handleKeyUp(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowRight':
        hasNextItem && onNextClick();
        break;
      case 'ArrowLeft':
        hasPrevItem && onPrevClick();
        break;
      case 'Escape':
        onCancel();
        break;
      default:
    }
  }

  const arrowClassNames = classNames(
    'flex justify-center items-center absolute top-1/2 w-7 h-7 my-6 rounded-full z-arrow-navigation border-none outline-none bg-white dark:bg-gray-90 text-blue dark:text-blue-50 hover:text-blue-70 dark:hover:text-blue active:text-blue-80 dark:active:text-blue-70 disabled:text-gray-50 dark:disabled:text-gray-70 transition-opacity',
    hidden
      ? 'opacity-0 pointer-events-none duration-100'
      : 'opacity-1 delay-100'
  );

  return (
    <>
      <button
        onClick={onPrevClick}
        disabled={!hasPrevItem}
        className={classNames(arrowClassNames, 'left-4')}
        data-test-id="item-detail-navigation-previous"
      >
        <Icon type="Chevron" className="fill-current mr-0.5" />
      </button>

      <button
        onClick={onNextClick}
        disabled={!hasNextItem}
        className={classNames(arrowClassNames, 'right-4 transform rotate-180')}
        data-test-id="item-detail-navigation-next"
      >
        <Icon type="Chevron" className="fill-current mr-0.5" />
      </button>
    </>
  );
};
