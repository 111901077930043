import { MODAL_TYPE } from '@app/config/constants';
import type { ModalConfig } from '@app/store/modal/types';
import type {
  JoinBoardModalProps,
  ConfirmModalProps,
  CreateCollectionModalProps,
  AddLinkModalProps,
  MoveItemsModalProps,
  OnboardingModalProps,
  StorageLimitModalProps,
  StorageUpsellModalProps,
  CaptionModalProps,
  SurveyModalProps,
  BaseModalProps,
  WarningModalProps,
} from '@app/types/modal';
import AddLink from '@component/Modal/AddLink';
import Caption from '@component/Modal/Caption';
import Collection from '@component/Modal/Collection';
import Confirm from '@component/Modal/Confirm';
import JoinBoard from '@component/Modal/JoinBoard';
import MoveItems from '@component/Modal/MoveItems';
import Onboarding from '@component/Modal/Onboarding';
import StorageLimit from '@component/Modal/StorageLimit';
import StorageUpsell from '@component/Modal/StorageUpsell';
import Survey from '@component/Modal/Survey';
import Warning from '@component/Modal/Warning';

type Props = BaseModalProps & {
  type: MODAL_TYPE;
  config?: ModalConfig;
};

const ModalContent = ({ type, ...props }: Props) => {
  switch (type) {
    case MODAL_TYPE.COLLECTION:
      return (
        <Collection {...(props as CreateCollectionModalProps)} type={type} />
      );
    case MODAL_TYPE.ADD_LINK:
      return <AddLink {...(props as AddLinkModalProps)} type={type} />;
    case MODAL_TYPE.MOVE_ITEMS:
      return <MoveItems {...(props as MoveItemsModalProps)} type={type} />;
    case MODAL_TYPE.CONFIRM:
      return <Confirm {...(props as ConfirmModalProps)} type={type} />;
    case MODAL_TYPE.JOIN_BOARD:
      return <JoinBoard {...(props as JoinBoardModalProps)} type={type} />;
    case MODAL_TYPE.ONBOARDING:
      return <Onboarding {...(props as OnboardingModalProps)} type={type} />;
    case MODAL_TYPE.STORAGE_LIMIT:
      return (
        <StorageLimit {...(props as StorageLimitModalProps)} type={type} />
      );
    case MODAL_TYPE.STORAGE_UPSELL:
      return (
        <StorageUpsell {...(props as StorageUpsellModalProps)} type={type} />
      );
    case MODAL_TYPE.CAPTION:
      return <Caption {...(props as CaptionModalProps)} type={type} />;
    case MODAL_TYPE.SURVEY:
      return <Survey {...(props as SurveyModalProps)} type={type} />;
    case MODAL_TYPE.WARNING:
      return <Warning {...(props as WarningModalProps)} />;
    default:
      throw new Error('Wrong modal type');
  }
};

export default ModalContent;
