import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { ROUTES } from '@app/config/constants';
import { getUserIsLoading } from '@app/store/user/selectors';
import { useAuthLinks } from '@hook/useAuthLinks';
import { useSearchParams } from '@hook/useSearchParams';

enum Type {
  callback,
  login,
  logout,
  signUp,
}

const AuthPage = ({ type }: { type: Type }) => {
  const isLoading = useSelector(getUserIsLoading);
  const {
    params: { return_to },
    search,
  } = useSearchParams();
  const { login, logout, signUp } = useAuthLinks();

  useEffect(() => {
    if (type === Type.callback) {
      return;
    }

    switch (type) {
      case Type.login:
        return void login();
      case Type.logout:
        return void logout();
      case Type.signUp:
        return void signUp();
    }
  }, [login, logout, signUp, type]);

  if (
    type === Type.login ||
    type === Type.logout ||
    type === Type.signUp ||
    isLoading
  ) {
    return null;
  }

  if (type === Type.callback) {
    // Strip Auth params from url
    search.delete('code');
    search.delete('state');
  }

  return (
    <Navigate
      to={{
        pathname: return_to ?? ROUTES.HOME,
        search: search.toString(),
      }}
    />
  );
};

AuthPage.type = Type;

export default AuthPage;
