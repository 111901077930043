import { composeWithDevTools } from '@redux-devtools/extension';
import type {
  ThunkAction,
  Action,
  ThunkDispatch,
  AnyAction,
} from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';

/* Reducers */

import collections from '@app/store/collections';
import items from '@app/store/items';
import modal from '@app/store/modal';
import notification from '@app/store/notification';
import pending from '@app/store/pending';
import ui from '@app/store/ui';
import uploader from '@app/store/uploader';
import user from '@app/store/user';
// Router
import {
  createRouterMiddleware,
  createRouterReducer,
  history,
} from '@module/ReduxRouter';

const reducers = {
  collections,
  items,
  modal,
  notification,
  pending,
  router: createRouterReducer(history),
  ui: persistReducer(
    {
      key: 'ui',
      storage,
      whitelist: ['mode', 'seenOnboarding', 'seenSurvey'],
    },
    ui
  ),
  uploader,
  user,
};

const store = createStore(
  enableBatching(combineReducers(reducers)),
  composeWithDevTools(
    applyMiddleware(createRouterMiddleware(history), thunkMiddleware)
  )
);

export default store;
export const persistor = persistStore(store);

export type AppDispatch = ThunkDispatch<RootState, unknown, AnyAction>;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<R = void> = ThunkAction<
  R,
  RootState,
  unknown,
  Action<string>
>;
