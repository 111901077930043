import { fromPairs } from 'lodash';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useSearchParams() {
  const { search: raw } = useLocation();
  const search = useMemo(() => new URLSearchParams(raw), [raw]);
  const params = useMemo(
    () => fromPairs<string | undefined>(Array.from(search)),
    [search]
  );

  return { raw, search, params };
}
