import classnames from 'classnames';
import type { InputHTMLAttributes } from 'react';
import { useMemo, forwardRef } from 'react';

import { getRandomToken } from '@app/helper';
import Label from '@component/Label';
import useFieldState from '@hook/useFieldState';

type Props = InputHTMLAttributes<HTMLTextAreaElement> & {
  hasError?: boolean;
  message?: string;
  hasFloatingPlaceholder?: boolean;
};

export const TextareaField = forwardRef(
  (
    {
      value,
      name,
      placeholder,
      id,
      type,
      className,
      hasError,
      message,
      onChange,
      hasFloatingPlaceholder,
      ...props
    }: Props,
    ref
  ) => {
    const textareaField = useFieldState<HTMLTextAreaElement>();
    const fieldId = useMemo(
      () => id || `${name || 'textarea'}-${getRandomToken()}`,
      [id, name]
    );

    const textareaClassName = classnames(
      'peer w-full p-4 rounded-xl bg-gray-20 dark:bg-gray-90 appearance-none focus:outline-none ts-l1 disabled:text-gray-60 dark:disabled:text-gray-55 placeholder-gray-60 dark:placeholder-gray-55',
      className,
      {
        'text-black dark:text-white': !hasError,
        'text-red-55 dark:text-red-50': hasError,
        'placeholder-opacity-0 dark:placeholder-opacity-0 pt-5':
          hasFloatingPlaceholder,
      }
    );

    const messageClassName = classnames('ts-l4 pl-4', {
      'text-gray-60 dark:text-gray-55': !hasError,
      'text-red-55 dark:text-red-50': hasError,
    });

    const labelClassName = classnames('absolute left-4 top-4 origin-0', {
      'transform -translate-y-3 scale-75':
        textareaField.isFocused || value !== '',
      'text-blue dark:text-blue-50': textareaField.isFocused,
      hidden: !hasFloatingPlaceholder,
    });

    return (
      <>
        <div className="relative w-full rounded-xl">
          <textarea
            id={fieldId}
            ref={textareaField.ref}
            className={textareaClassName}
            onChange={onChange}
            value={value}
            name={name}
            placeholder={placeholder}
            data-test-id={fieldId}
            {...props}
          />
          {placeholder && (
            <Label
              className={labelClassName}
              htmlFor={fieldId}
              hasError={hasError}
            >
              {placeholder}
            </Label>
          )}
        </div>
        {message && (
          <span
            data-test-id={`message-${fieldId}`}
            className={messageClassName}
          >
            {message}
          </span>
        )}
      </>
    );
  }
);

TextareaField.displayName = 'TextareaField';
