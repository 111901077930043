import { useTranslation } from 'react-i18next';

import { COLLECTION_TYPE } from '@app/config/constants';
import type { Collection } from '@app/types/collections';

export function useCollectionName(collection?: Collection) {
  const { t } = useTranslation();
  if (collection?.type === COLLECTION_TYPE.UNSORTED) {
    return t('title.sort_later');
  }
  return collection?.name ?? '';
}
