import classNames from 'classnames';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import config from '@app/config';
import { ITEM_TYPE } from '@app/config/constants';
import { getItemByItemId } from '@app/store/items/selectors';
import type { Item as ItemType } from '@app/types/items';

type Props = {
  itemId: ItemType['id'];
  hasImage?: boolean;
  className?: string;
};

type Meta = { title?: string; description?: string };

const GridItemMeta = ({ itemId, hasImage, className }: Props) => {
  const item = useSelector(getItemByItemId({ id: itemId }));

  const meta: Meta = useMemo(() => {
    if (item === undefined) {
      return {};
    }

    if (item.type === ITEM_TYPE.WEB) {
      return { title: item.meta.title, description: item.meta.source };
    }

    return config.fileTypesWithMeta.includes(item.fileType)
      ? {
          title: item.meta.title,
          description: `.${item.extension && item.extension.toLowerCase()}`,
        }
      : {};
  }, [item]);

  const showMeta = useMemo(
    () => meta.title !== undefined || meta.description !== undefined,
    [meta]
  );

  return showMeta ? (
    <div
      className={classNames(
        'p-3',
        hasImage && 'bg-white dark:bg-gray-90',
        className
      )}
    >
      {meta.title !== undefined && (
        <h5
          data-test-id="item-name"
          className={classNames(
            'overflow-hidden text-ellipsis ts-h5 m-0 text-black',
            {
              'dark:text-white': hasImage,
            }
          )}
        >
          {meta.title}
        </h5>
      )}
      {meta.description !== undefined && (
        <p
          className={classNames(
            'ts-l7 uppercase',
            hasImage ? 'text-gray-60 dark:text-gray-55' : 'text-black',
            meta.title !== undefined && 'mt-1'
          )}
          data-test-id="item-description"
        >
          {meta.description}
        </p>
      )}
    </div>
  ) : null;
};

export default GridItemMeta;
