import type { SVGAttributes } from 'react';
import { memo, forwardRef } from 'react';

import { ReactComponent as Add } from './svgs/add.svg';
import { ReactComponent as AllItems } from './svgs/all-items.svg';
import { ReactComponent as Arrow } from './svgs/arrow.svg';
import { ReactComponent as Audio } from './svgs/audio.svg';
import { ReactComponent as CaptionFilled } from './svgs/caption-filled.svg';
import { ReactComponent as Caption } from './svgs/caption-outline.svg';
import { ReactComponent as Check } from './svgs/checkmark.svg';
import { ReactComponent as Chevron } from './svgs/chevron.svg';
import { ReactComponent as CloseSmall } from './svgs/close-small.svg';
import { ReactComponent as Close } from './svgs/close.svg';
import { ReactComponent as CollectLogo } from './svgs/collect-logo.svg';
import { ReactComponent as Collect } from './svgs/collect.svg';
import { ReactComponent as Create } from './svgs/create.svg';
import { ReactComponent as Cross } from './svgs/cross.svg';
import { ReactComponent as Delete } from './svgs/delete.svg';
import { ReactComponent as Document } from './svgs/document.svg';
import { ReactComponent as Dots } from './svgs/dots.svg';
import { ReactComponent as Download } from './svgs/download.svg';
import { ReactComponent as Dropzone } from './svgs/dropzone.svg';
import { ReactComponent as ExternalLink } from './svgs/external-link.svg';
import { ReactComponent as External } from './svgs/external.svg';
import { ReactComponent as File } from './svgs/file.svg';
import { ReactComponent as Image } from './svgs/image.svg';
import { ReactComponent as Logo } from './svgs/logo.svg';
import { ReactComponent as Minimize } from './svgs/minimize.svg';
import { ReactComponent as Move } from './svgs/move.svg';
import { ReactComponent as Play } from './svgs/play.svg';
import { ReactComponent as Plus } from './svgs/plus.svg';
import { ReactComponent as Search } from './svgs/search.svg';
import { ReactComponent as SortLater } from './svgs/sort-later.svg';
import { ReactComponent as Unsupported } from './svgs/unsupported.svg';
import { ReactComponent as Uploading } from './svgs/uploading.svg';
import { ReactComponent as Url } from './svgs/url.svg';
import { ReactComponent as User } from './svgs/user.svg';
import { ReactComponent as WarningUpload } from './svgs/warning-upload.svg';
import { ReactComponent as Warning } from './svgs/warning.svg';
import { ReactComponent as We } from './svgs/we.svg';
import { ReactComponent as Web } from './svgs/web.svg';
import { ReactComponent as Wordmark } from './svgs/wordmark.svg';
import { ReactComponent as WordmarkPro } from './svgs/wordmarkpro.svg';
import { ReactComponent as Write } from './svgs/write.svg';

const availableIcons = {
  Add,
  AllItems,
  CollectLogo,
  Arrow,
  Audio,
  CaptionFilled,
  Caption,
  Check,
  Chevron,
  Close,
  CloseSmall,
  Collect,
  Create,
  Cross,
  Delete,
  Document,
  Dots,
  Download,
  Dropzone,
  External,
  ExternalLink,
  File,
  Image,
  Logo,
  Minimize,
  Move,
  Play,
  Plus,
  Search,
  SortLater,
  Unsupported,
  Uploading,
  Url,
  User,
  Warning,
  WarningUpload,
  We,
  Web,
  Wordmark,
  WordmarkPro,
  Write,
};

export type IconType = keyof typeof availableIcons;

export const allIcons = Object.keys(availableIcons) as IconType[];

export interface Props extends SVGAttributes<SVGElement> {
  type: IconType;
}

const Icon = forwardRef<SVGSVGElement, Props>(
  ({ type, ...props }, ref): JSX.Element => {
    const El = availableIcons[type];
    return <El ref={ref} {...props} />;
  }
);

Icon.displayName = 'Icon';

function areEqual(prevProps: Props, nextProps: Props) {
  return (
    prevProps.type === nextProps.type &&
    prevProps.className === nextProps.className
  );
}

export default memo(Icon, areEqual);
