import classNames from 'classnames';
import type { ChangeEvent, FormEvent } from 'react';
import { useState, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { COLLECTION_TYPE } from '@app/config/constants';
import type { AppDispatch } from '@app/store';
import {
  createEmbedCollectionUrl,
  createSharedCollectionUrl,
} from '@app/store/collections/actions';
import { getPending } from '@app/store/pending/selectors';
import { getCanEditCollection } from '@app/store/selectors';
import { getIsWeTransferProUser } from '@app/store/user/selectors';
import type { Collection } from '@app/types/collections';
import Icon from '@component/Icon';
import Popover from '@component/Popover';
import { useAction } from '@hook/useAction';
import { track } from '@module/analytics';

type Props = {
  collection: Collection;
};

export const PopoverShareCollection = ({ collection }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const action = useAction();
  const { t } = useTranslation();
  const pending = useSelector(getPending);
  const isWeTransferProUser = useSelector(getIsWeTransferProUser);

  const [copied, setCopied] = useState<{
    type: null | 'share' | 'embed';
    url: null | string;
  }>({ type: null, url: null });
  const [collectionType, setCollectionType] = useState<COLLECTION_TYPE>();
  const canEditCollection = useSelector(
    getCanEditCollection({ id: collection.id })
  );

  const handleCopyToClipboard = useCallback(
    async (url: string, type: 'share' | 'embed') => {
      await navigator.clipboard.writeText(url);

      setCopied({
        type,
        url,
      });

      track('bucket_shared', {
        content_count: collection.itemCount,
        members_count: collection.memberships.length,
        public: collection.type !== COLLECTION_TYPE.PRIVATE,
      });

      setTimeout(() => {
        document.querySelector('body')?.click();
        setCopied((copied) => ({ ...copied, type: null }));
      }, 2000);
    },
    [collection.itemCount, collection.memberships.length, collection.type]
  );

  const handleShareFormSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      const url = await dispatch(
        createSharedCollectionUrl({
          id: collection.id,
          type: collectionType,
        })
      );
      if (url !== undefined) {
        await handleCopyToClipboard(url, 'share');
      }
    },
    [collection.id, collectionType, dispatch, handleCopyToClipboard]
  );

  const handleCollectionTypeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setCollectionType(event.target.value as COLLECTION_TYPE);
    },
    []
  );

  const handleCopyEmbedLink = useCallback(async () => {
    const url = await dispatch(
      createEmbedCollectionUrl({
        id: collection.id,
      })
    );
    if (url !== undefined) {
      await handleCopyToClipboard(url, 'embed');
    }
  }, [collection.id, dispatch, handleCopyToClipboard]);

  const onPopoverOpen = useCallback(() => {
    setCollectionType(undefined);
  }, []);

  return (
    <Popover
      trigger={
        <span
          className={action.primarySmall}
          onClick={onPopoverOpen}
          data-test-id="share-collection-trigger"
        >
          Share
        </span>
      }
    >
      <form
        onSubmit={handleShareFormSubmit}
        className="px-2 py-3"
        data-test-id="share-collection-form"
        data-url={copied.url}
      >
        <div className="mb-3">
          <Trans i18nKey="share.title" parent="span" className="block ts-l1">
            Invite <span className="font-bold">anyone</span> to
          </Trans>
        </div>
        <div className="mb-3">
          <label className="flex items-center ts-l4">
            <input
              type="radio"
              value={COLLECTION_TYPE.SHARED}
              name="collectionType"
              onChange={handleCollectionTypeChange}
              className="mr-1"
              data-test-id="share-collection-view"
            />{' '}
            {t('share.view')}
          </label>
        </div>
        <div className="mb-3">
          <label
            className={classNames('flex items-center ts-l4', {
              'opacity-30': !canEditCollection,
            })}
          >
            <input
              type="radio"
              value={COLLECTION_TYPE.COLLABORATIVE}
              name="collectionType"
              onChange={handleCollectionTypeChange}
              disabled={!canEditCollection}
              className="mr-1"
              data-test-id="share-collection-edit"
            />{' '}
            {t('share.edit')}
          </label>
        </div>
        <button
          type="submit"
          className={classNames('w-full', action.secondary)}
          data-test-id="share-collection-submit"
          disabled={
            pending.createSharedCollectionUrl || collectionType === undefined
          }
        >
          {copied.type === 'share' ? (
            <>
              <Icon type="Check" className="fill-current mr-1" />{' '}
              {t('share.copied')}
            </>
          ) : (
            t('share.copy')
          )}
        </button>
      </form>
      {isWeTransferProUser && (
        <>
          <div className="relative text-center w-72 -mx-2">
            <hr className="absolute top-1/2 left-0 w-full border-gray-30 mt-[1px]" />
            <span className="relative inline-block ts-l6 text-gray-60 bg-white px-3 uppercase">
              {t('label.or', 'or')}
            </span>
          </div>
          <div className="w-full px-2 py-3">
            <Trans
              i18nKey="share.add_to_review"
              parent="span"
              className="block ts-l1 mb-3"
            >
              Add to<span className="font-bold">review</span>.
            </Trans>
            <button
              onClick={handleCopyEmbedLink}
              className={classNames('block w-full', action.secondary)}
              disabled={pending.createSharedCollectionUrl}
              data-test-id="share-collection-embed"
            >
              {copied.type === 'embed' ? (
                <>
                  <Icon type="Check" className="fill-current mr-1" />{' '}
                  {t('share.copied')}
                </>
              ) : (
                t('share.copy_embed_link', 'Copy embed link')
              )}
            </button>
            <a
              href="https://wetransfer.zendesk.com/hc/en-us/sections/4408198012692"
              className={classNames('block w-full mt-2', action.secondarySmall)}
              target="_blank"
              rel="noreferrer"
            >
              {t('share.what_are_reviews', 'What are reviews?')}{' '}
              <Icon type="ExternalLink" className="fill-current ml-1 h-3" />
            </a>
          </div>
        </>
      )}
    </Popover>
  );
};
