import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import { useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { MODAL_MOVE_TYPE, MODAL_TYPE } from '@app/config/constants';
import { isValidUrl } from '@app/helper';
import type { AppDispatch } from '@app/store';
import { addWebItem } from '@app/store/items/actions';
import { openModal } from '@app/store/modal/actions';
import type { AddLinkModalProps } from '@app/types/modal';
import InputField from '@component/InputField';
import { useAction } from '@hook/useAction';

export const AddLinkModal = ({ closeModal, config }: AddLinkModalProps) => {
  const action = useAction();
  const { t } = useTranslation();
  const [url, setUrl] = useState('https://');
  const urlInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch<AppDispatch>();

  const collectionId = config?.collectionId;

  async function readClipboard() {
    try {
      const text = await navigator.clipboard.readText();
      if (isValidUrl(text)) {
        setUrl(text);
        urlInputRef.current?.focus();
      }
    } catch (error) {
      //
    }
  }

  useEffect(() => {
    urlInputRef.current?.focus();

    void readClipboard();
  }, []);

  const handleSubmit = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();

      if (!isValidUrl(url)) {
        return;
      }

      if (collectionId) {
        void dispatch(addWebItem(url, collectionId));
        closeModal();
        return;
      }

      void dispatch(
        openModal({
          type: MODAL_TYPE.MOVE_ITEMS,
          config: {
            type: MODAL_MOVE_TYPE.ADD_LINK,
            url,
          },
        })
      );
    },
    [closeModal, collectionId, dispatch, url]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUrl(
        event.target.value
          .replace('https://http://', 'http://')
          .replace('https://https://', 'https://')
      );
    },
    []
  );

  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      event.preventDefault();
      closeModal();
    },
    [closeModal]
  );

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Dialog.Title className="ts-h3 mb-3 text-black dark:text-white">
          {t('modal:add_link.title')}
        </Dialog.Title>
        <div className="mb-2">
          <InputField
            ref={urlInputRef}
            type="text"
            value={url}
            name="link-url"
            onChange={handleInputChange}
            placeholder={t('modal:add_link.label.url')}
            data-test-id="input-link-url"
          />
        </div>
        <div className="mt-3 flex justify-end align-center">
          <button
            className={classNames(action.secondary, 'ml-2')}
            type="button"
            onClick={handleClose}
            data-test-id="modal-close"
          >
            {t('button:cancel')}
          </button>
          <button
            className={classNames(action.primary, 'ml-2')}
            type="submit"
            disabled={!isValidUrl(url)}
            data-test-id="submit-link-url"
          >
            {t('button:add', 'Add')}
          </button>
        </div>
      </form>
    </>
  );
};
