import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { useCollectionName } from '@app/hooks/useCollectionName';
import { getCollectionByItemId } from '@app/store/selectors';
import type { Item as ItemType } from '@app/types/items';

type Props = {
  itemId: ItemType['id'];
  hasImage: boolean;
  className?: string;
};

const GridItemLocation = ({ itemId, hasImage, className }: Props) => {
  const collection = useSelector(getCollectionByItemId({ id: itemId }));
  const collectionName = useCollectionName(collection);
  return (
    <div
      className={classNames(
        'block w-full p-3 m-0 ts-l7 uppercase text-ellipsis whitespace-nowrap overflow-hidden',
        hasImage
          ? 'bg-white dark:bg-gray-90 text-gray-60 dark:text-gray-55'
          : 'text-black dark:text-black',
        className
      )}
      data-test-id="item-collection-name"
    >
      {collectionName}
    </div>
  );
};

export default GridItemLocation;
