import type { ReactNode } from 'react';
import { Component } from 'react';

import Monitor from './Monitor';

type Props = {
  children: ReactNode;
  error: ReactNode;
};

export class ErrorBoundary extends Component<Props> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    Monitor.error(error);
  }

  render() {
    if (this.state.hasError) {
      return this.props.error;
    }
    return this.props.children;
  }
}
