import { useMemo } from 'react';

import type { Collection } from '@app/types/collections';
import type { Item } from '@app/types/items';
import Grid from '@component/Grid';

type Props = {
  collection: Collection;
  items: Item[];
};

export const EmbeddedCollection = ({ collection, items }: Props) => {
  const itemIds = useMemo(() => items.map((item) => item.id), [items]);

  return (
    <>
      <div className="p-4 w-full">
        <div className="w-full pr-10 mb-3">
          <h1
            className="m-0 text-ellipsis whitespace-nowrap overflow-hidden w-full ts-h2"
            data-test-id="embedded-collection-title"
          >
            {collection.name ?? ''}
          </h1>
          {collection.description && (
            <p
              className="ts-l6 mt-1 w-full"
              data-test-id="embedded-collection-description"
            >
              {collection.description}
            </p>
          )}
        </div>

        <Grid itemIds={itemIds} showItemCaption />
      </div>
    </>
  );
};
