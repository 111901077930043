import type { HTMLAttributes, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { getPreviewByItemId } from '@app/store/items/selectors';
import type { Item } from '@app/types/items';
import Preloader from '@component/Preloader';
import type { Props as PreloaderProps } from '@component/Preloader';

export type Props = {
  itemId?: Item['id'];
  fallback?: ReactNode;
  handleError?: PreloaderProps['onError'];
} & HTMLAttributes<HTMLDivElement>;

const ItemPreviewLoader = ({
  itemId,
  fallback = null,
  handleError,
  ...props
}: Props) => {
  const preview = useSelector(getPreviewByItemId({ id: itemId }));

  return (
    <div {...props}>
      <Preloader media={preview?.url} onError={handleError} fallback={fallback}>
        <img
          src={preview?.url}
          alt=""
          className="object-cover w-full h-full"
          data-test-id="item-preview-image"
          draggable={false}
        />
      </Preloader>
    </div>
  );
};

export default ItemPreviewLoader;
