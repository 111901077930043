import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

import { COLLECTION_TYPE } from '@app/config/constants';
import type { RootState } from '@app/store';
import { paramId, paramIds, paramItemId } from '@app/store/selectorParams';

/* State */

const collectionsState = (state: RootState) => state.collections;
const itemsState = (state: RootState) => state.items;

/* Selectors */

const getCollections = createSelector(
  [collectionsState],
  (collections) => collections
);

export const getAllCollections = createSelector(
  [getCollections],
  (collections) => sortBy(collections, ['updatedAt']).reverse()
);

export const getAllCollectionIds = createSelector(
  [getAllCollections],
  (allCollections) => allCollections.map(({ id }) => id)
);

export const getAllOwnedCollections = createSelector(
  [getCollections],
  (collections) =>
    sortBy(collections, ['updatedAt']) // Exclude public boards
      .filter((collection) => collection.type !== COLLECTION_TYPE.PUBLIC)
      .reverse()
);

export const getAllSharedCollections = createSelector(
  [getAllCollections],
  (collections) => {
    const syncedCollections = collections.filter(
      (collection) =>
        collection.type === COLLECTION_TYPE.SHARED ||
        collection.type === COLLECTION_TYPE.COLLABORATIVE
    );
    return syncedCollections;
  }
);

export const getCollectionById = createSelector([paramId], (id) =>
  createSelector([getCollections], (collections) =>
    id === undefined ? undefined : collections[id]
  )
);

export const getCollectionsByIds = createSelector([paramIds], (ids) =>
  createSelector([getCollections], (collections) =>
    // We use a string to make sure that we don't return a new response on a new
    // array:
    //  getCollectionsByIds(state, { ids: [1,2,3]}) !== getCollectionsByIds(state, { ids: [1,2,3]})
    ids.split(',').map((collectionId) => collections[collectionId])
  )
);

export const getSortedItemsByCollectionId = createSelector([paramId], (id) =>
  createSelector(
    [getCollectionById({ id }), itemsState],
    (collection, items) => {
      if (Object.keys(items).length === 0) {
        return [];
      }

      if (collection === undefined) {
        return [];
      }
      if (Object.keys(collection.items).length === 0) {
        return [];
      }

      return sortBy(
        collection.items
          .map((collectionItemId) => items[collectionItemId])
          .filter((item) => item !== undefined),
        ['order', 'createdAt']
      ).reverse();
    }
  )
);

export const getCollectionShareUrlByIdAndType = createSelector(
  [paramId, ({ type }: { type: COLLECTION_TYPE }) => type],
  (id, type) =>
    createSelector([getCollectionById({ id })], (collection) => {
      if (type === COLLECTION_TYPE.SHARED && collection?.shortenedUrl) {
        return collection.shortenedUrl;
      }

      if (type === COLLECTION_TYPE.COLLABORATIVE && collection?.inviteUrl) {
        return collection.inviteUrl;
      }
    })
);

export const getSortedItemIdsByCollectionId = createSelector([paramId], (id) =>
  createSelector([getSortedItemsByCollectionId({ id })], (items) =>
    items.map((item) => item?.id)
  )
);

export const getPreviousItemByCollectionIdAndItemId = createSelector(
  [paramId, paramItemId],
  (id, itemId) =>
    createSelector([getSortedItemsByCollectionId({ id })], (items) => {
      if (Object.keys(items).length === 1 || itemId === undefined) {
        return undefined;
      }

      const currentIndex = items.findIndex((item) => item.id === itemId);

      if (currentIndex <= 0 || currentIndex === -1) {
        return undefined;
      }

      return items[currentIndex - 1];
    })
);
export const getNextItemByCollectionIdAndItemId = createSelector(
  [paramId, paramItemId],
  (id, itemId) =>
    createSelector([getSortedItemsByCollectionId({ id })], (items) => {
      if (Object.keys(items).length === 1 || itemId === undefined) {
        return undefined;
      }

      const currentIndex = items.findIndex((item) => item.id === itemId);

      if (currentIndex >= items.length - 1 || currentIndex === -1) {
        return undefined;
      }

      return items[currentIndex + 1];
    })
);

export const getCollectionThumbnailById = createSelector([paramId], (id) =>
  createSelector(
    [getCollectionById({ id })],
    (collection) => collection?.thumbnail
  )
);

export const getCollectionTypeById = createSelector([paramId], (id) =>
  createSelector([getCollectionById({ id })], (collection) => collection?.type)
);

export const getCollectionVersionById = createSelector([paramId], (id) =>
  createSelector(
    [getCollectionById({ id })],
    (collection) => collection?.version
  )
);

export const getCollectionMembershipsById = createSelector([paramId], (id) =>
  createSelector(
    [getCollectionById({ id })],
    (collection) => collection?.memberships
  )
);

export const getCollectionMembershipRoleById = createSelector([paramId], (id) =>
  createSelector(
    [getCollectionById({ id })],
    (collection) => collection?.membershipRole
  )
);

export const getCollectionNameById = createSelector([paramId], (id) =>
  createSelector([getCollectionById({ id })], (collection) => collection?.name)
);
