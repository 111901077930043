import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { ROUTES } from '@app/config/constants';

const sets = {
  sidebar: [ROUTES.HOME, ROUTES.SORT_LATER, ROUTES.COLLECTION],
  embedded: [ROUTES.EMBED_COLLECTION, ROUTES.EMBED_ITEM],
  largeScreen: [ROUTES.HOME, ROUTES.SORT_LATER, ROUTES.COLLECTION, ROUTES.ITEM],
};

// A simple helper that can match the current location against multiple routes
// it also supports predefined sets
export function useMatchRoutes(routes: ROUTES[] | keyof typeof sets) {
  const [state, setState] = useState<typeof routes>(routes);
  const { pathname } = useLocation();

  useEffect(() => {
    if (isEqual(state, routes)) {
      return;
    }
    setState(routes);
  }, [routes, state]);

  const matchRoutes: ROUTES[] = useMemo(() => {
    if (typeof state === 'string') {
      return sets[state];
    }
    return state;
  }, [state]);

  const matched = useMemo(
    () => matchRoutes.some((route) => matchPath(route, pathname) !== null),
    [pathname, matchRoutes]
  );

  return matched;
}

useMatchRoutes.routes = ROUTES;
