import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import AppLoader from '@app/components/AppLoader';
import {
  getCollectionById,
  getSortedItemsByCollectionId,
} from '@app/store/collections/selectors';
import { getIsFetchingInitialData } from '@app/store/pending/selectors';
import EmbeddedCollection from '@component/EmbeddedCollection';
import ErrorState from '@component/ErrorState';
import OpenInApp from '@component/OpenInApp';
import OpenInCollect from '@component/OpenInCollect';
import PublicCollection from '@component/PublicCollection';
import PublicCollectionHeader from '@component/PublicCollectionHeader';

enum Type {
  public = 'public',
  embed = 'embed',
}

export const PublicCollectionPage = ({ type }: { type: Type }) => {
  const { collectionId } = useParams();
  const collection = useSelector(getCollectionById({ id: collectionId }));
  const items = useSelector(getSortedItemsByCollectionId({ id: collectionId }));
  const isLoading = useSelector(getIsFetchingInitialData);

  if (isLoading) {
    return <AppLoader />;
  }

  // not found
  if (collection === undefined || collectionId === undefined) {
    return (
      <div className="flex flex-col h-screen w-screen">
        {type === Type.public && <PublicCollectionHeader />}
        <ErrorState
          type={
            type === Type.public
              ? ErrorState.type.collectionNotFound
              : ErrorState.type.embedNotFound
          }
          className="px-4"
        />
      </div>
    );
  }

  return type === Type.public ? (
    <div className="flex flex-col w-screen">
      <PublicCollectionHeader collectionId={collectionId} />
      <PublicCollection collection={collection} items={items} />
      <OpenInApp collectionId={collectionId} />
    </div>
  ) : (
    <>
      <EmbeddedCollection collection={collection} items={items} />
      <OpenInCollect collectionId={collectionId} />
    </>
  );
};

PublicCollectionPage.type = Type;
