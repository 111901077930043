import classNames from 'classnames';
import { uniq } from 'lodash';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  ROUTES,
  MODAL_CONFIRM_TYPE,
  ITEM_TYPE,
  MODAL_MOVE_TYPE,
  MODAL_TYPE,
} from '@app/config/constants';
import type { AppDispatch } from '@app/store';
import { downloadItems } from '@app/store/items/actions';
import { getItemsByItemIds } from '@app/store/items/selectors';
import { openModal } from '@app/store/modal/actions';
import { setNotification } from '@app/store/notification/actions';
import { getCanEditCollection } from '@app/store/selectors';
import type { Collection } from '@app/types/collections';
import type { Item } from '@app/types/items';
import Icon from '@component/Icon';
import { NOTIFICATION_TYPE } from '@component/Notification/types';
import Tooltip from '@component/Tooltip';
import { useToolbarAction } from '@hook/useToolbarAction';

type Props = {
  collectionId?: Collection['id'];
  itemIds?: Item['id'][];
};

export const ItemActions = ({ itemIds = [], collectionId = '' }: Props) => {
  const { t } = useTranslation();
  const toolbarAction = useToolbarAction();
  const dispatch = useDispatch<AppDispatch>();
  const { pathname } = useLocation();
  const items = useSelector(getItemsByItemIds({ ids: itemIds }));
  const canDownloadItems = items.some((item) => item.type === ITEM_TYPE.FILE);
  const canEditContent =
    useSelector(getCanEditCollection({ id: collectionId })) ||
    pathname === ROUTES.HOME;

  const currentCollectionId = useMemo(() => {
    if (collectionId !== '') {
      return collectionId;
    }

    const allUniqueCollectionIds = uniq(items.map((item) => item.collectionId));

    return allUniqueCollectionIds.length === 1
      ? allUniqueCollectionIds[0]
      : collectionId;
  }, [items, collectionId]);

  const handleMove = useCallback(() => {
    if (!canEditContent) {
      void dispatch(
        setNotification({ type: NOTIFICATION_TYPE.INFO_VIEW_ONLY_BOARD })
      );
      return;
    }
    void dispatch(
      openModal({
        type: MODAL_TYPE.MOVE_ITEMS,
        config: {
          type: MODAL_MOVE_TYPE.MOVE_ITEMS,
          itemIds,
          currentCollectionId,
        },
      })
    );
  }, [canEditContent, currentCollectionId, dispatch, itemIds]);

  const handleDownload = useCallback(() => {
    if (!canDownloadItems) {
      void dispatch(
        setNotification({ type: NOTIFICATION_TYPE.INFO_VIEW_ONLY_BOARD })
      );
      return;
    }
    void dispatch(downloadItems(itemIds));
  }, [canDownloadItems, dispatch, itemIds]);

  const handleDelete = useCallback(() => {
    if (!canEditContent) {
      void dispatch(
        setNotification({ type: NOTIFICATION_TYPE.INFO_VIEW_ONLY_BOARD })
      );
      return;
    }
    void dispatch(
      openModal({
        type: MODAL_TYPE.CONFIRM,
        config: {
          type:
            itemIds.length === 1
              ? MODAL_CONFIRM_TYPE.DELETE_ITEM
              : MODAL_CONFIRM_TYPE.DELETE_ITEMS,
          itemIds,
        },
      })
    );
  }, [canEditContent, dispatch, itemIds]);

  return (
    <div className="flex">
      <Tooltip label={t('button:tooltip_move_item')}>
        <button
          onClick={handleMove}
          className={classNames(toolbarAction, 'w-7 mr-1', {
            'opacity-50': !canEditContent,
          })}
          data-test-id="item-action-move-item"
        >
          <Icon type="Move" className="h-4" />
        </button>
      </Tooltip>
      <Tooltip label={t('button:tooltip_download_item')}>
        <button
          onClick={handleDownload}
          className={classNames(toolbarAction, 'w-7 mr-1', {
            'opacity-50': !canDownloadItems,
          })}
          data-test-id="item-action-download-item"
        >
          <Icon type="Download" className="h-[18px]" />
        </button>
      </Tooltip>
      <Tooltip label={t('button:tooltip_delete_item')}>
        <button
          onClick={handleDelete}
          className={classNames(toolbarAction, 'w-7', {
            'opacity-50': !canEditContent,
          })}
          data-test-id="item-action-delete-item"
        >
          <Icon type="Delete" className="h-4 text-red-55" />
        </button>
      </Tooltip>
    </div>
  );
};
