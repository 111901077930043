import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useMatch, useParams } from 'react-router-dom';

import config from '@app/config';
import { ROUTES } from '@app/config/constants';
import { useMatchRoutes } from '@app/hooks/useMatchRoutes';
import type { AppDispatch } from '@app/store';
import { fetchInitialData } from '@app/store/actions';
import { fetchCollectionsDiff } from '@app/store/collections/actions';
import { setNotification } from '@app/store/notification/actions';
import {
  setInitialUserData,
  setAuthLoading,
  fetchUser,
} from '@app/store/user/actions';
import { getUserIsLoading, getUserIsLoggedIn } from '@app/store/user/selectors';
import AppLoader from '@component/AppLoader';
import Modal from '@component/Modal';
import Notification from '@component/Notification';
import { NOTIFICATION_TYPE } from '@component/Notification/types';
import Sidebar from '@component/Sidebar';
import { useTrackStats } from '@hook/useTrackStats';
import { useUIMode } from '@hook/useUIMode';
import { useUnsavedChangesGuard } from '@hook/useUnsavedChangesGuard';
import { init } from '@module/auth0';
import Monitor from '@module/Monitor';

const Application = () => {
  useUnsavedChangesGuard();
  useTrackStats();
  useUIMode();

  const { collectionId } = useParams();
  const [hasStarted, setHasStarted] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const callbackRouteMath = useMatch(ROUTES.CALLBACK);

  // Routes
  const isEmbeddedRoute = useMatchRoutes('embedded');
  const isSidebarRoute = useMatchRoutes('sidebar');

  const isLoading = useSelector(getUserIsLoading);
  const isLoggedIn = useSelector(getUserIsLoggedIn);

  const skipAuth = useMemo(() => isEmbeddedRoute, [isEmbeddedRoute]);
  const showSideBar = useMemo(
    () => isLoggedIn && isSidebarRoute,
    [isLoggedIn, isSidebarRoute]
  );

  const fetchDiff = useCallback(() => {
    Promise.all([
      dispatch(fetchUser()),
      dispatch(fetchCollectionsDiff()),
    ]).catch(Monitor.error);
  }, [dispatch]);

  const startAuth = useCallback(async () => {
    if (skipAuth) {
      // Skip authentication
      dispatch(setAuthLoading(false));
      await dispatch(fetchInitialData({ isLoggedIn: false, collectionId }));
      return;
    }

    const { isLoggedIn, getToken, getUser } = await init({
      callback: callbackRouteMath !== null,
      onLoginCallback: () =>
        dispatch(
          setNotification({
            type: NOTIFICATION_TYPE.SUCCESS_MESSAGE_LOG_IN_COMPLETE,
          })
        ),
    });

    if (isLoggedIn) {
      await dispatch(setInitialUserData({ getToken, getUser }));
    }

    await dispatch(fetchInitialData({ isLoggedIn, collectionId }));
    dispatch(setAuthLoading(false));

    // Start polling the backend for changes
    if (isLoggedIn) {
      window.setInterval(fetchDiff, config.fetchDiffInterval);
    }
  }, [skipAuth, callbackRouteMath, dispatch, collectionId, fetchDiff]);

  useEffect(() => {
    if (hasStarted) {
      return;
    }
    setHasStarted(true);
    void startAuth();
  }, [hasStarted, startAuth]);

  return (
    <>
      {isLoading ? (
        <AppLoader />
      ) : (
        <div className="flex">
          {showSideBar && <Sidebar />}
          <Outlet />
        </div>
      )}
      <Notification className="fixed top-3.5 left-1/2 -translate-x-1/2 z-notification" />
      <Modal />
    </>
  );
};

export default Application;
