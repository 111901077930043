import type { AxiosError, InternalAxiosRequestConfig } from 'axios';
import axios from 'axios';

import config from '@app/config';
import { getRandomToken } from '@app/helper';
import type { GetToken } from '@module/auth0/types';

import { request } from './helpers';
// API methods
import createCollectionsSection from './sections/collections';
import createItemsSection from './sections/items';
import createTeamsSection from './sections/teams';
import createUploadSection from './sections/upload';
import createUserSection from './sections/user';

const baseURL = config.api.url;

const headers: Record<string, string> = {
  'Content-Type': 'application/json',
  'X-Signature': config.api.signature,
  'X-Origin': window.location.origin,
};

const apiInstances = {
  api: axios.create({
    baseURL,
    headers,
  }),
  publicApi: axios.create({
    baseURL,
    headers,
  }),
};

const configure = async ({
  getToken,
  onAuthError,
}: {
  getToken: GetToken;
  onAuthError: (error: AxiosError) => AxiosError;
}) => {
  const deviceToken = `${config.api.device_prefix}${getRandomToken()}`;

  // Authenticate with API
  const { token: spaceshipToken } = await request<{
    success: boolean;
    token: string;
  }>({
    call: apiInstances.publicApi.post('/v1/authorize', {
      device_token: deviceToken,
    }),
    assert: ({ data }) => data.success,
  });

  // Add DeviceToken to requests
  const deviceTokenInterceptor = (config: InternalAxiosRequestConfig) => {
    config.headers.set({
      X_DEVICE_TOKEN: deviceToken,
      ...(config.headers ?? {}),
    });

    return config;
  };
  apiInstances.api.interceptors.request.use(deviceTokenInterceptor);
  apiInstances.publicApi.interceptors.request.use(deviceTokenInterceptor);

  // Register with API
  const token = await getToken();
  const { unsorted_id: unsortedId } = await request<{
    unsorted_id: string;
  }>({
    call: apiInstances.publicApi.patch(
      '/v2/auth0/sign_up',
      {
        auth0_jwt: token,
      },
      {
        headers: {
          X_DEVICE_TOKEN: deviceToken,
          Authorization: `Bearer ${spaceshipToken}`,
        },
      }
    ),
  });

  // Add auth handling to API
  apiInstances.api.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      const accessToken = await getToken();

      // Add auth header to request
      config.headers.set({
        ...(config.headers ?? {}),
        Authorization: `Bearer ${accessToken}`,
      });

      return config;
    }
  );
  apiInstances.api.interceptors.response.use(
    undefined,
    (error: Error | AxiosError) => {
      if (
        error instanceof Error &&
        'isAxiosError' in error &&
        error.response?.status === 401
      ) {
        return onAuthError(error);
      }

      return Promise.reject(error);
    }
  );

  return {
    unsortedId,
    deviceToken,
  };
};

export const api = {
  instances: apiInstances,
  configure,
  collections: createCollectionsSection(apiInstances),
  items: createItemsSection(apiInstances),
  upload: createUploadSection(apiInstances),
  teams: createTeamsSection(apiInstances),
  user: createUserSection(apiInstances),
};
