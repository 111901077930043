import classNames from 'classnames';
import { memo } from 'react';

type Props = {
  size: number;
};

const GridLoading = ({ size }: Props) => {
  return (
    <>
      {[...Array.from({ length: 30 })].map((_, i) => (
        <div
          key={`item--${i}`}
          className={classNames(
            'rounded-xl relative overflow-hidden bg-gray-30 animate-pulse h-[240px] grow'
          )}
          style={{
            width: Math.floor(
              Math.random() * (size * 1.25 - size * 0.75) + size * 0.75
            ),
            flexBasis: 'auto',
          }}
        />
      ))}
    </>
  );
};

export default memo(GridLoading);
