import { ITEM_UPLOAD_STATUS, MODAL_TYPE } from '@app/config/constants';
import { getRandomToken } from '@app/helper';
import type { AppThunk } from '@app/store';
import { updateItem } from '@app/store/items/actions';
import { openModal } from '@app/store/modal/actions';
import type { Collection } from '@app/types/collections';
import type { Item } from '@app/types/items';
import type { FileUpload } from '@app/types/uploads';
import { NOTIFICATION_TYPE } from '@component/Notification/types';
import { track } from '@module/analytics';

import { getCollectionById } from '../collections/selectors';
import { queueFiles, startUpload } from '../uploader/actions';

import type { NotificationState } from './types';

import { notificationSlice } from './';

export const { addNotification, resetNotification } = notificationSlice.actions;

export const setNotification =
  (notification: NotificationState, duration = 2000): AppThunk =>
  (dispatch) => {
    const id = getRandomToken();

    dispatch(
      addNotification({
        ...notification,
        id,
      })
    );

    if (duration > 0) {
      setTimeout(() => {
        dispatch(resetNotification(id));
      }, duration);
    }
  };

const setSuccessNotification =
  (
    collectionId: Collection['id'],
    itemIds: Item['id'][],
    type:
      | NOTIFICATION_TYPE.SUCCESS_MESSAGE_ITEM_ADDED
      | NOTIFICATION_TYPE.SUCCESS_MESSAGE_ITEM_MOVED
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const collection = getCollectionById({ id: collectionId })(state);

    if (collection === undefined) {
      return;
    }

    dispatch(
      setNotification({
        type,
        config: {
          collectionId: collection.id,
          itemIds,
          onButtonClicked: (itemId) => {
            track('add_caption_tapped', {
              location: 'notification',
            });

            dispatch(
              openModal({
                type: MODAL_TYPE.CAPTION,
                config: {
                  itemId,
                },
              })
            );
          },
        },
      })
    );
  };

export const setUploadSuccessNotification =
  (collectionId: Collection['id'], itemIds: Item['id'][]): AppThunk =>
  (dispatch) => {
    dispatch(
      setSuccessNotification(
        collectionId,
        itemIds,
        NOTIFICATION_TYPE.SUCCESS_MESSAGE_ITEM_ADDED
      )
    );
  };

export const setMoveSuccessNotification =
  (collectionId: Collection['id'], itemIds: Item['id'][]): AppThunk =>
  (dispatch) => {
    dispatch(
      setSuccessNotification(
        collectionId,
        itemIds,
        NOTIFICATION_TYPE.SUCCESS_MESSAGE_ITEM_MOVED
      )
    );
  };

export const setUploadErrorNotification =
  (errorFiles: Record<Item['id'], FileUpload>): AppThunk =>
  (dispatch) => {
    dispatch(
      setNotification(
        {
          type: NOTIFICATION_TYPE.ERROR_MESSAGE_ITEMS_NOT_UPLOADED,
          config: {
            itemIds: Object.values(errorFiles).map((file) => file.id),
            onButtonClicked: (notificationId) => {
              dispatch(resetNotification(notificationId));

              Object.keys(errorFiles).forEach((itemId) => {
                dispatch(
                  updateItem({
                    itemId,
                    item: {
                      uploadStatus: ITEM_UPLOAD_STATUS.QUEUED,
                    },
                  })
                );
              });
              dispatch(queueFiles(errorFiles));
              dispatch(startUpload());
            },
          },
        },
        0
      )
    );
  };
