import { parseJSON } from 'date-fns';

import type { User, UserSubscription } from '@app/types/user';

export type UserFromAPI = {
  id: string;
  email: string;
  email_verified?: boolean;
  name: string;
  subscription?: UserSubscriptionFromAPI;
  unsorted_id: string;
  device_token: string;
};

export type UserSubscriptionFromAPI = {
  id: string;
  status: string;
  expires_at: string | null;
  subscription_type: string;
  subscription_origin: string;
  tier: string;
};

export type TeamFromAPI = {
  id: string;
  name: string;
  logo: string;
  membersCount: number;
  creatorId: string;
  updaterId: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

export function makeUser(json: UserFromAPI): User {
  const subscription = makeSubscription(json.subscription);

  return {
    id: json.id,
    email: json.email,
    emailVerified: json.email_verified === true,
    name: json.name,
    deviceToken: json.device_token,
    unsortedId: json.unsorted_id,
    subscription,
  };
}

export function makeSubscription(
  subscription?: UserSubscriptionFromAPI
): UserSubscription | undefined {
  if (!subscription) {
    return undefined;
  }

  if ('expires_at' in subscription) {
    // it means we have a UserSubscriptionFromAPI
    return {
      id: subscription.id,
      status: subscription.status,
      expires:
        subscription.expires_at !== null
          ? parseJSON(subscription.expires_at)
          : undefined,
      type: subscription.subscription_type,
      origin: subscription.subscription_origin,
      tier: subscription.tier,
    };
  }

  return subscription;
}
