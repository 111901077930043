import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { ROUTES } from '@app/config/constants';
import type { Collection } from '@app/types/collections';
import type { Item } from '@app/types/items';
import Icon from '@component/Icon';

export type Props = {
  collectionId?: Collection['id'];
  itemId?: Item['id'];
};

export const OpenInCollect = ({ collectionId, itemId }: Props) => {
  const { t } = useTranslation();

  const openInCollectUrl = useMemo(
    () =>
      itemId !== undefined
        ? generatePath(ROUTES.ITEM, {
            collectionId: collectionId ?? null,
            itemId,
          })
        : generatePath(ROUTES.COLLECTION, {
            collectionId: collectionId ?? null,
          }),
    [collectionId, itemId]
  );

  return (
    <a
      href={openInCollectUrl}
      target="_blank"
      rel="noreferrer"
      className="fixed top-4 right-3 bg-white rounded-xl flex items-center justify-center group text-black opacity-70 hover:opacity-100 transition-opacity shadow-sm"
    >
      <span className="ts-l4 w-0 translate-x-5 opacity-0 group-hover:translate-x-0 group-hover:opacity-100 transition-all duration-200 overflow-hidden inline-block whitespace-nowrap group-hover:w-auto group-hover:ml-2">
        {t('button:open_in_collect', 'Open in Collect')}
      </span>
      <span
        className="w-7 h-7 flex items-center justify-center"
        data-test-id="open-in-collection-button"
      >
        <Icon type="CollectLogo" />
      </span>
    </a>
  );
};
