import ItemMeta from '@app/components/Item/ItemMeta';
import type { Item } from '@app/types/items';
import ItemComponent from '@component/Item';

type Props = {
  itemId: Item['id'];
};

export const ItemDetailContentWebItem = ({ itemId }: Props) => (
  <div className="w-80 h-60 rounded-xl overflow-hidden">
    <ItemComponent id={itemId}>
      <ItemMeta itemId={itemId} hasImage={true} />
    </ItemComponent>
  </div>
);
