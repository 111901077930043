import classNames from 'classnames';
import { useRef } from 'react';
import { useSelector } from 'react-redux';

import { getIsFetchingInitialData } from '@app/store/pending/selectors';
import { getSidebarClosed } from '@app/store/ui/selectors';
import { useScrollListener } from '@hook/useScroll';

import type { Props as CollectionToolbarSelectedItemsActionsProps } from './CollectionToolbarSelectedItemsActions';
import CollectionToolbarSelectedItemsActions from './CollectionToolbarSelectedItemsActions';

type Props = CollectionToolbarSelectedItemsActionsProps & {
  title: string;
  description?: string;
  isCollectionDetail?: boolean;
};

export const CollectionToolbar = ({
  title,
  description = '',
  children,
  isCollectionDetail = false,
  selectedItems,
  isAllItemsSelected,
  onSelectAll,
  onClearSelection,
  collectionId,
}: Props) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const [hasScrolled] = useScrollListener(document, titleRef, 75);
  const sidebarClosed = useSelector(getSidebarClosed);
  const isFetchingInitialData = useSelector(getIsFetchingInitialData);

  const titlePadding = {
    'pl-4': isCollectionDetail,
    'pl-14': !isCollectionDetail && sidebarClosed,
    'pl-0': !isCollectionDetail && !sidebarClosed,
  };

  if (isFetchingInitialData) {
    return (
      <div
        className={classNames(
          'sticky h-16 flex items-center top-0 left-0',
          titlePadding
        )}
      >
        <span className="inline-block rounded-md bg-gray-30 dark:bg-gray-80 w-52 h-4 animate-pulse"></span>
        <span className="inline-block rounded-lg bg-gray-30 dark:bg-gray-80 w-20 h-6 animate-pulse ml-auto"></span>
      </div>
    );
  }

  return (
    <>
      <div className="sticky z-toolbar bg-gray-20 dark:bg-black h-16 flex items-center content-end top-0 left-0 right-0 py-4">
        <div
          className={classNames(
            'mr-auto transition-all duration-300',
            titlePadding
          )}
        >
          <h3
            className={classNames(
              'ts-h4 transition-opacity duration-100 text-black dark:text-white',
              {
                'opacity-0': !hasScrolled,
                'opacity-1': hasScrolled,
              }
            )}
          >
            {title}
          </h3>
        </div>
        <div
          className="relative flex items-center ml-auto"
          data-test-id="collection-toolbar"
        >
          <CollectionToolbarSelectedItemsActions
            collectionId={collectionId}
            selectedItems={selectedItems}
            isAllItemsSelected={isAllItemsSelected}
            onSelectAll={onSelectAll}
            onClearSelection={onClearSelection}
          >
            {children}
          </CollectionToolbarSelectedItemsActions>
        </div>
      </div>
      <div
        ref={titleRef}
        className={classNames(
          'relative transition-opacity duration-100 pb-4 text-black dark:text-white',
          {
            'opacity-0': hasScrolled,
            'opacity-1': !hasScrolled,
            'mx-4': isCollectionDetail,
          }
        )}
      >
        <h1
          className="m-0 text-ellipsis whitespace-nowrap overflow-hidden w-full ts-h2"
          data-test-id="collection-title"
        >
          {title}
        </h1>
        <p className="ts-l5 mt-1 w-full" data-test-id="collection-description">
          {description !== '' ? description : <span>&nbsp;</span>}
        </p>
      </div>
    </>
  );
};
