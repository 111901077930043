import { MODAL_TYPE } from '@app/config/constants';
import type { AppThunk } from '@app/store';
import { modalSlice } from '@app/store/modal';
import { hasSeenOnboarding, hasSeenSurvey } from '@app/store/ui/actions';

export const { openModal } = modalSlice.actions;

export const closeModal = (): AppThunk => (dispatch, getState) => {
  const { type } = getState().modal;
  if (type === MODAL_TYPE.ONBOARDING) {
    dispatch(hasSeenOnboarding());
  }
  if (type === MODAL_TYPE.SURVEY) {
    dispatch(hasSeenSurvey());
  }

  dispatch(modalSlice.actions.closeModal());
};
