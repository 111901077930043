import {
  useState,
  useDebugValue,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

export function useSelectedItems(ids: string[]) {
  const [itemIds, setItemIds] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  useEffect(() => {
    setItemIds(ids);
    setSelectedItems((items) => {
      return items.filter((currentId) => ids.includes(currentId));
    });
  }, [ids]);

  const isAllItemsSelected = useMemo(
    () => selectedItems.length > 0 && itemIds.length === selectedItems.length,
    [itemIds.length, selectedItems.length]
  );

  function selectItem(id: string) {
    const existsInItems = itemIds.includes(id);

    existsInItems &&
      setSelectedItems((items) => {
        const isIdSelected = items.includes(id);
        if (!isIdSelected) {
          return [...items, id];
        }

        return items.filter((currentId) => id !== currentId);
      });
  }

  function selectAll() {
    setSelectedItems(itemIds);
  }

  function clearSelection() {
    setSelectedItems([]);
  }

  const resetSelectedItems = useCallback(() => {
    setSelectedItems([]);
  }, []);

  useDebugValue(itemIds ? itemIds : []);
  useDebugValue(selectedItems ? selectedItems : []);

  return {
    selectedItems,
    selectAll,
    selectItem,
    clearSelection,
    resetSelectedItems,
    isAllItemsSelected,
  };
}
