import type { Auth0ClientAdapter } from '@wetransfer/authentication.js';
import { AuthenticationAdapter } from '@wetransfer/authentication.js';

import { ROUTES } from '@app/config/constants';

const auth0Config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
  audience: process.env.REACT_APP_AUTH0_AUDIENCE ?? '',
  redirect_uri: `${window.location.origin}${ROUTES.CALLBACK}`,
  useRefreshTokens: true,
};

const client = new AuthenticationAdapter({
  clientOptions: {
    auth0ClientOptions: auth0Config,
  },
});

export async function init({
  callback = false,
  onLoginCallback,
}: {
  callback?: boolean;
  onLoginCallback: () => void | Promise<void>;
}) {
  if (callback) {
    await (
      client.getActiveAuthClient() as Auth0ClientAdapter
    ).handleRedirectCallback();
  }

  let isLoggedIn;

  try {
    await client.checkSession();
    isLoggedIn = await client.isAuthenticated();

    if (callback) {
      await onLoginCallback();
    }
  } catch {
    isLoggedIn = false;
  }

  return {
    isLoggedIn,
    getToken: () => client.getTokenSilently(),
    getUser: () =>
      (client.getActiveAuthClient() as Auth0ClientAdapter).getUser(),
  };
}

export async function signUp({
  language = 'en',
  search,
}: { language?: string; search?: string } = {}) {
  await client.signUp({
    language,
    search,
  });
}

export async function login({
  language = 'en',
  search,
}: { language?: string; search?: string } = {}) {
  await client.login({
    language,
    search,
  });
}

export function logout() {
  void client.logoutSession({
    returnTo: window.location.origin,
  });
}
