import { createSelector } from 'reselect';

import type { RootState } from '@app/store';

/* State */

const uploaderState = (state: RootState) => state.uploader;

/* Selectors */

export const getUploader = createSelector(
  [uploaderState],
  (uploader) => uploader
);
export const getIsUploadingItems = createSelector(
  [getUploader],
  (uploader) => uploader.isUploading
);
export const getUploaderFiles = createSelector(
  [getUploader],
  (uploader) => uploader.files
);
export const getTotalBytesUploading = createSelector(
  [getUploader],
  (uploader) =>
    !uploader.isUploading
      ? 0
      : Object.values(uploader.files).reduce(
          (total, file) => (total += file.size),
          0
        )
);
