import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { COLLECTION_TYPE } from '@app/config/constants';
import {
  getCollectionById,
  getCollectionThumbnailById,
} from '@app/store/collections/selectors';
import { getCanEditCollection } from '@app/store/selectors';
import type { Collection } from '@app/types/collections';
import Preloader from '@component/Preloader';
import UploadStatus from '@component/UploadStatus';

type Props = {
  collectionId: Collection['id'];
  disabled?: boolean;
};

export const ListItemCollectionContent = ({
  collectionId,
  disabled,
}: Props) => {
  const { t } = useTranslation();

  const collection = useSelector(getCollectionById({ id: collectionId }));
  const thumbnail = useSelector(
    getCollectionThumbnailById({ id: collectionId })
  );

  const canEditCollection = useSelector(
    getCanEditCollection({ id: collectionId })
  );

  const collectionStatus = useMemo(() => {
    if (!collection) {
      return null;
    }

    if (
      collection.type === COLLECTION_TYPE.COLLABORATIVE ||
      collection.type === COLLECTION_TYPE.SHARED
    ) {
      if (!canEditCollection) {
        return t('collection_type.shared_view_only');
      }

      if (collection.memberships.length === 1) {
        return t('collection_type.shared');
      }

      return t('collection_type.shared_public', {
        count: collection.memberships.length - 1,
      });
    }

    return t('collection_type.private');
  }, [canEditCollection, collection, t]);

  if (!collection) {
    return null;
  }

  const { name } = collection;

  return (
    <div
      className="w-full min-h-12 p-2 inline-flex flex-row justify-start items-center ts-l2"
      data-test-id="collection-list-item"
    >
      <div className="rounded-lg mr-2 w-8 h-8 shrink-0 overflow-hidden">
        <Preloader
          media={thumbnail}
          fallback={
            <span className="block w-full h-full bg-gray-40 dark:bg-gray-60" />
          }
        >
          <img
            src={thumbnail}
            alt=""
            className="object-cover w-full h-full"
            draggable={false}
          />
        </Preloader>
      </div>
      <div className="text-left overflow-hidden">
        <span
          className={classNames(
            'block transition-colors ts-l2  w-full overflow-hidden text-ellipsis whitespace-nowrap',
            disabled
              ? 'text-gray-60 dark:text-gray-55'
              : 'text-black dark:text-white'
          )}
          data-test-id="collection-name"
        >
          {name}
        </span>
        <span className="block transition-colors ts-l6 text-gray-60 dark:text-gray-55">
          {collectionStatus}
        </span>
      </div>
      <div className="ml-auto inline-flex">
        <UploadStatus collectionId={collectionId} />
      </div>
    </div>
  );
};
