import classNames from 'classnames';
import { forwardRef, useCallback } from 'react';
import type { ForwardedRef, ReactNode } from 'react';
import type { NavLinkProps } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import type { IconType } from '@component/Icon';
import Icon from '@component/Icon';

type ListItemSidebarNavLinkProps = NavLinkProps & {
  children: ReactNode;
  icon?: IconType;
  disabled?: boolean;
  rightIcon?: ReactNode;
};

export const ListItemSidebarNavLink = forwardRef(
  (
    {
      children,
      icon,
      disabled,
      rightIcon,
      ...props
    }: ListItemSidebarNavLinkProps,
    ref
  ) => {
    const propsClassName: string =
      typeof props.className === 'string' ? props.className : '';

    const createClassName = useCallback(
      ({ isActive }: { isActive: boolean }) =>
        classNames(
          'w-full rounded-xl block hover:bg-white transition-colors dark:hover:bg-gray-90 focus:outline-none focus-visible:ring-2 ring-inset focus-visible:ring-blue ',
          propsClassName,
          isActive
            ? 'bg-white dark:bg-gray-90 text-blue dark:text-blue-50'
            : 'text-gray-60 dark:text-gray-55'
        ),
      [propsClassName]
    );

    return (
      <NavLink
        {...props}
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        className={createClassName}
      >
        <div className="w-full h-9 p-2 inline-flex flex-row justify-start items-center">
          {icon && (
            <Icon
              type={icon}
              className="block mr-2 fill-current h-5 w-8 transition-colors"
            />
          )}
          <span
            className={classNames(
              'font-normal ts-l2 transition-colors',
              disabled
                ? 'text-gray-60 dark:text-gray-55'
                : 'text-black dark:text-white'
            )}
          >
            {children}
          </span>
          {rightIcon && (
            <span className="ml-auto inline-flex">{rightIcon}</span>
          )}
        </div>
      </NavLink>
    );
  }
);

ListItemSidebarNavLink.displayName = 'ListItemSidebarNavLink';
