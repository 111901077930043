import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getUserCanUpgrade } from '@app/store/user/selectors';
import type { StorageUpsellModalProps } from '@app/types/modal';
import appIconCollect from '@asset/images/app-icon-collect.png';
import appIconCollect2x from '@asset/images/app-icon-collect@2x.png';
import appIconWetransfer from '@asset/images/app-icon-wetransfer.png';
import appIconWetransfer2x from '@asset/images/app-icon-wetransfer@2x.png';
import upsell from '@asset/images/storage-upsell.png';
import upsell2x from '@asset/images/storage-upsell@2x.png';
import Icon from '@component/Icon';
import { useAction } from '@hook/useAction';

export const StorageUpsell = ({ closeModal }: StorageUpsellModalProps) => {
  const action = useAction();
  const { t } = useTranslation();
  const userCanUpgrade = useSelector(getUserCanUpgrade);

  const handleClick = useCallback(() => closeModal(), [closeModal]);

  return (
    <>
      <button
        className={classNames(action.icon, 'absolute mt-3 mr-3 top-0 right-0')}
        onClick={handleClick}
      >
        <Icon type="Close" />
      </button>
      <div className="flex p-12">
        <div className="pt-8 flex-1">
          <img src={upsell} srcSet={`${upsell2x} 2x`} alt="" />
        </div>
        <div className="flex-none w-72">
          <Dialog.Title className="ts-h1 mb-2 text-black dark:text-white">
            {t('storage.prompt.title.free')}
          </Dialog.Title>
          <Dialog.Description className="ts-b2 mb-5 dark:text-white">
            {userCanUpgrade
              ? t('storage.prompt.description.free')
              : t('storage.prompt.description.pro')}
          </Dialog.Description>
          <div className="mb-5 rounded-2xl relative p-px bg-gradient-to-br from-orange-60 via-purple-70 to-blue-80">
            <div className="rounded-2xl p-5 bg-white dark:bg-black">
              <ul className="m-0 p-0 list-none ts-l3 mb-8 text-black dark:text-white">
                <li className="mb-2 bg-no-repeat bg-left pl-5 bg-checkmark-orange ">
                  {t('storage.prompt.features.storage')}
                </li>
                <li className="mb-2 bg-no-repeat bg-left pl-5 bg-checkmark-orange">
                  {t('storage.prompt.features.teams')}
                </li>
              </ul>
              <span className="block ts-l3 mb-3 text-black dark:text-white">
                {t('storage.prompt.apps-title')}
              </span>
              <ul className="m-0 p-0 list-none ts-l3 text-black dark:text-white">
                <li className="mb-2 flex items-center">
                  <img
                    className="mr-2"
                    src={appIconCollect}
                    srcSet={`${appIconCollect2x} 2x`}
                    alt=""
                  />
                  <Trans i18nKey="storage.prompt.apps.collect" parent="span">
                    <strong>Collect</strong> to save inspiration
                  </Trans>
                </li>
                <li className="mb-2 flex items-center">
                  <img
                    className="mr-2"
                    src={appIconWetransfer}
                    srcSet={`${appIconWetransfer2x} 2x`}
                    alt=""
                  />
                  <Trans i18nKey="storage.prompt.apps.wetransfer" parent="span">
                    <strong>WeTransfer</strong> to send big files
                  </Trans>
                </li>
              </ul>
            </div>
          </div>
          {userCanUpgrade && (
            <a
              className={action.primary}
              href={t('upsell.pro.button-link')}
              target="_blank"
              title={t('button:get_premium')}
              rel="noopener noreferrer"
              data-test-id="cta-confirm-dangerous-action"
            >
              {t('button:get_premium')}
            </a>
          )}
        </div>
      </div>
    </>
  );
};
