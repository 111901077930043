import { differenceInCalendarDays } from 'date-fns';
import { createSelector } from 'reselect';

import { SUBSCRIPTION_STATE, SUBSCRIPTION_TIER } from '@app/config/constants';
import type { RootState } from '@app/store';

/* State */

const userState = (state: RootState) => state.user;

/* Selectors */

export const getUser = createSelector([userState], (user) => user);

export const getUserId = createSelector([getUser], (user) => user.id);

export const getUserSubscription = createSelector(
  [getUser],
  (user) => user.subscription
);

export const getUserIsLoggedIn = createSelector(
  [getUser],
  (user) => user.isLoggedIn
);

export const getUserIsLoading = createSelector(
  [getUser],
  (user) => user.isLoading
);

export const getUserHasSubscription = createSelector(
  [getUserSubscription],
  (subscription) =>
    subscription !== undefined &&
    subscription.tier !== SUBSCRIPTION_TIER.FREE &&
    (subscription.status === SUBSCRIPTION_STATE.ACTIVE ||
      subscription.status === SUBSCRIPTION_STATE.NOTICE)
);

export const getUserTeams = createSelector([getUser], (user) => user.teams);

export const getRemainingDaysInNoticePeriod = createSelector(
  [getUserSubscription],
  (subscription) =>
    (subscription &&
      subscription.status === 'notice' &&
      subscription.expires !== undefined &&
      differenceInCalendarDays(new Date(subscription.expires), new Date())) ||
    0
);

export const getUserSubscriptionExpiryDate = createSelector(
  [getUserSubscription],
  (subscription) => subscription?.expires
);

export const getIsWeTransferProUser = createSelector(
  [getUserSubscription],
  (subscription) =>
    subscription?.tier === SUBSCRIPTION_TIER.PRO ||
    subscription?.tier === SUBSCRIPTION_TIER.PRO_2022 ||
    subscription?.tier === SUBSCRIPTION_TIER.PREMIUM_2022
);

export const getUserCanUpgrade = createSelector(
  [getUserSubscription],
  (subscription) =>
    subscription === undefined ||
    subscription.tier === SUBSCRIPTION_TIER.FREE ||
    subscription.tier === SUBSCRIPTION_TIER.PRO_2022
);

export const getUserUnsortedId = createSelector(
  [getUser],
  (user) => user?.unsortedId
);

const getUserUsage = createSelector([getUser], (user) => user.usage);
export const getUsage = createSelector([getUserUsage], (usage) => {
  return {
    ...usage,
    // This can be more than 100% if the user has used more than allowed
    percentage: Math.round((usage.bytesUsed / usage.bytesLimit) * 100),
    isFull: usage.bytesUsed >= usage.bytesLimit,
  };
});

export const getHasFreeSpace = createSelector([getUsage], (usage) => {
  return !usage.isFull;
});
