import classnames from 'classnames';
import type { MouseEventHandler } from 'react';
import { memo, useEffect, useRef, useMemo } from 'react';

import { isWebItem } from '@app/types/items';
import type { FileItem, Item } from '@app/types/items';
import Loader from '@component/Loader';
import useItemDetailUrl from '@hook/useItemDetailUrl';
import { track } from '@module/analytics';

import { ItemDetailContentFileItem } from './ItemDetailContentFileItem';
import { ItemDetailContentWebItem } from './ItemDetailContentWebItem';

type Props = {
  item?: Item;
  isLoading?: boolean;
  focusMode?: boolean;
  handleImageClick?: MouseEventHandler;
  isPublic?: boolean;
};

export const ItemDetail = ({
  item,
  isLoading: itemIsLoading = false,
  focusMode = false,
  handleImageClick,
  isPublic = false,
}: Props) => {
  const lastItemId = useRef<undefined | Item['id']>();
  const itemDetail = useItemDetailUrl(item?.id);

  // Consolidate loading cases
  const isLoading = useMemo(
    () => itemIsLoading || itemDetail.isLoading,
    [itemIsLoading, itemDetail]
  );

  // Handle analytics
  useEffect(() => {
    if (!item || lastItemId.current === item['id']) {
      return;
    }

    track('content_viewed', {
      type: item.type,
      extension: (item as FileItem).extension ?? '',
      filesize: (item as FileItem).size ?? 0,
      public: isPublic,
    });

    lastItemId.current = item['id'];
  }, [item, isPublic]);

  const contentClassName = classnames(
    'flex relative h-full justify-center transition-all duration-150',
    {
      'items-center': isLoading || item === undefined,
      'bg-black': focusMode,
    }
  );

  // The item can also not be present during loading, so we show a loader
  if (isLoading || item === undefined) {
    return (
      <div className={contentClassName}>
        <div className="max-w-full w-14 h-1 absolute left-1/2 top-1/2 -mt-0.5 -ml-7">
          <Loader type={Loader.type.BAR} />
        </div>
      </div>
    );
  }

  if (isWebItem(item)) {
    return (
      <div className={classnames(contentClassName, 'items-center')}>
        <ItemDetailContentWebItem itemId={item.id} />
      </div>
    );
  }

  if (item.detailUrl === undefined) {
    return (
      <div className={classnames(contentClassName, { dark: focusMode })}>
        <ItemDetailContentFileItem type="unsupported" preview={item.preview} />
      </div>
    );
  }

  return (
    <div className={contentClassName}>
      <ItemDetailContentFileItem
        type={item.fileType}
        url={itemDetail.url}
        preview={item.preview}
        onClick={handleImageClick}
      />
    </div>
  );
};

export default memo(ItemDetail);
