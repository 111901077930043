// ONLY IMPORT THIS FILE IN REDUCER SLICES
import { createAction } from '@reduxjs/toolkit';

import type {
  AddCollectionPayload,
  RemoveCollectionPayload,
  EditCollectionPayload,
  AddCollectionMembershipsPayload,
  RemoveCollectionMembershipsPayload,
} from './types';

export const addCollection = createAction<AddCollectionPayload>(
  'collections/addCollection'
);
export const removeCollection = createAction<RemoveCollectionPayload>(
  'collections/removeCollection'
);
export const editCollection = createAction<EditCollectionPayload>(
  'collections/editCollection'
);
export const addCollectionMemberships =
  createAction<AddCollectionMembershipsPayload>(
    'collections/addCollectionMemberships'
  );
export const removeCollectionMemberships =
  createAction<RemoveCollectionMembershipsPayload>(
    'collections/removeCollectionMemberships'
  );
