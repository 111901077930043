import { groupBy, maxBy } from 'lodash';
import multiDownload from 'multi-download';
import { generatePath, matchPath } from 'react-router-dom';

import {
  ITEM_UPLOAD_STATUS,
  ITEM_TYPE,
  ROUTES,
  COLLECTION_TYPE,
  MODAL_TYPE,
  HTTP_STATUS_CODE,
} from '@app/config/constants';
import type { AppThunk } from '@app/store';
import {
  getCollectionById,
  getCollectionTypeById,
  getSortedItemsByCollectionId,
} from '@app/store/collections/selectors';
import { itemsSlice } from '@app/store/items';
import {
  getItemByItemId,
  getItemsByItemIds,
  getUploadStatusByItemId,
} from '@app/store/items/selectors';
import { openModal } from '@app/store/modal/actions';
import {
  setMoveSuccessNotification,
  setNotification,
  setUploadSuccessNotification,
} from '@app/store/notification/actions';
import { setPending, resetPending } from '@app/store/pending/actions';
import { queueFiles, startUpload } from '@app/store/uploader/actions';
import { makeFileUpload } from '@app/store/uploader/helpers';
import { fetchUsage } from '@app/store/user/actions';
import {
  getHasFreeSpace,
  getUser,
  getUserCanUpgrade,
} from '@app/store/user/selectors';
import type { FileItemFromAPI } from '@app/transformers/item';
import { makeItem } from '@app/transformers/item';
import type { Collection } from '@app/types/collections';
import type { Item, FileItem, WebItem, ItemPreview } from '@app/types/items';
import type { FileUpload } from '@app/types/uploads';
import { NOTIFICATION_TYPE } from '@component/Notification/types';
import { track } from '@module/analytics';
import API, { isApiError } from '@module/api';
import Monitor from '@module/Monitor';
import { push } from '@module/ReduxRouter';

import { getCanEditCollection, getWillExceedStorageCheck } from '../selectors';

import { removeItems } from './action-creators';
export { removeItems };
export const { addItems, updateItem, moveItem } = itemsSlice.actions;

export function fetchItem(
  collectionId: string,
  itemId: string
): AppThunk<Promise<void | Partial<Item>>> {
  return async (dispatch) => {
    try {
      const itemFromApi = await API.items.fetchSingle({
        itemId,
        collectionId,
      });
      const item = makeItem(itemFromApi);

      if (!item) {
        return;
      }
      dispatch(updateItem({ itemId, item }));
      return item;
    } catch (error) {
      Monitor.error(error);
    }
  };
}

export function fetchItems(
  { collectionId }: { collectionId: string },
  totalItems: number
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const items = await API.items.fetchPerCollection({
        collectionId,
        totalItems,
      });
      const mappedItems = items.map((item) => {
        return {
          ...makeItem(item),
          collectionId,
        };
      });

      dispatch(
        addItems({
          collectionId,
          items: mappedItems,
        })
      );
    } catch (error) {
      Monitor.error(error);
    }
  };
}

export function downloadItems(itemIds: string[]): AppThunk<Promise<void>> {
  const pendingId = 'downloadItem';
  return async (dispatch, getState) => {
    dispatch(setPending(pendingId));
    const state = getState();
    const itemsToDownload = getItemsByItemIds({ ids: itemIds })(state).filter(
      (item) => item.type !== ITEM_TYPE.WEB
    ) as FileItem[];

    if (!itemsToDownload.length) {
      return;
    }

    const itemsGroupedPerCollection = groupBy(itemsToDownload, 'collectionId');

    try {
      const downloadUrls = await Promise.all(
        Object.keys(itemsGroupedPerCollection).map((collectionId) => {
          const groupedItemIds = itemsGroupedPerCollection[collectionId].map(
            (item) => item.id
          );

          const collectionType = getCollectionTypeById({
            id: collectionId,
          })(state);

          const isPublic =
            collectionType === COLLECTION_TYPE.COLLABORATIVE ||
            collectionType === COLLECTION_TYPE.SHARED ||
            collectionType === COLLECTION_TYPE.PUBLIC;

          return API.items.download({
            collectionId,
            itemIds: groupedItemIds,
            isPublic,
          });
        })
      );

      await multiDownload(downloadUrls);

      itemsToDownload.forEach((item) => {
        track('content_downloaded', {
          type: item.type,
          extension: item.extension ?? '',
          filesize: item.size ?? 0,
        });
      });

      dispatch(
        setNotification({
          type: NOTIFICATION_TYPE.SUCCESS_MESSAGE_ITEM_INIT_DOWNLOAD,
          config: {
            itemIds,
          },
        })
      );
    } catch (error) {
      Monitor.error(error);
      dispatch(setNotification({ type: NOTIFICATION_TYPE.ERROR }));
    }
    dispatch(resetPending(pendingId));
  };
}

export function moveItems({
  itemIds,
  toCollectionId,
}: {
  itemIds: string[];
  toCollectionId: string;
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { router } = state;
    const user = getUser(state);
    const toCollection = getCollectionById({ id: toCollectionId })(state);
    const userCanUpgrade = getUserCanUpgrade(state);

    const canEditCollection = getCanEditCollection({ id: toCollectionId })(
      state
    );

    try {
      if (toCollection === undefined) {
        dispatch(setNotification({ type: NOTIFICATION_TYPE.ERROR }));
        return;
      }

      if (!canEditCollection) {
        dispatch(
          setNotification({
            type: NOTIFICATION_TYPE.ERROR_MESSAGE_NOT_ALLOWED_TO_ADD_ITEMS,
          })
        );
        return;
      }

      const allItemsAreSynced = itemIds.every(
        (id) =>
          getUploadStatusByItemId({ id })(state) === ITEM_UPLOAD_STATUS.UPLOADED
      );
      if (!allItemsAreSynced) {
        dispatch(
          setNotification({
            type: NOTIFICATION_TYPE.ERROR_MESSAGE_NOT_ALL_ITEMS_ARE_SYNCED,
          })
        );
        return;
      }

      // Storage enforcement
      const { bytesUsed, bytesLimit } = user.usage;
      const fileItems = getItemsByItemIds({ ids: itemIds })(state).filter(
        (item): item is FileItem => item.type === ITEM_TYPE.FILE
      );

      const uploadSize: number = fileItems.reduce(
        (size: number, item: FileItem) => size + item.size,
        0
      );
      const isExceedingStorageLimit = bytesUsed + uploadSize > bytesLimit;

      if (
        isExceedingStorageLimit &&
        (toCollection.type === COLLECTION_TYPE.PRIVATE ||
          toCollection.type === COLLECTION_TYPE.UNSORTED)
      ) {
        dispatch(
          openModal({
            type: userCanUpgrade
              ? MODAL_TYPE.STORAGE_UPSELL
              : MODAL_TYPE.STORAGE_LIMIT,
          })
        );
        return;
      }

      await API.items.move({ toCollectionId, itemIds });

      const match = matchPath(ROUTES.ITEM, router.location.pathname);
      if (match !== null) {
        const path = generatePath(ROUTES.COLLECTION, {
          collectionId: match.params.collectionId ?? null,
        });
        dispatch(push(path));
      }

      const items = itemIds.map((itemId) =>
        getItemByItemId({ id: itemId })(state)
      );
      items.forEach((item) => {
        if (item !== undefined) {
          dispatch(
            moveItem({
              collectionId: item.collectionId,
              newCollectionId: toCollectionId,
              itemId: item.id,
            })
          );

          track('content_moved', {
            type: item.type,
            extension: (item as FileItem).extension ?? '',
            filesize: (item as FileItem).size ?? 0,
          });
        }
      });
      dispatch(setMoveSuccessNotification(toCollection.id, itemIds));
    } catch (error) {
      Monitor.error(error);
      dispatch(setNotification({ type: NOTIFICATION_TYPE.ERROR }));
    }
  };
}

async function createPreview(name: string, src: string): Promise<ItemPreview> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.addEventListener('load', (e) => {
      const { width, height, src: url } = e.target as HTMLImageElement;
      resolve({
        width,
        height,
        url,
        name,
      });
    });
    img.addEventListener('error', reject);
    img.src = src;
  });
}

export function addWebItem(
  url: string,
  collectionId: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { user } = state;
    const itemsInCollection = getSortedItemsByCollectionId({
      id: collectionId,
    })(state);
    const latestItem = maxBy(itemsInCollection, 'order');

    try {
      const embedly = await API.items.fetchUrlInfo({ url });

      const itemFromApi = await API.items.addWebItem({
        collectionId,
        url,
        meta: {
          embedly,
        },
      });

      const transformedItem = makeItem(itemFromApi) as Omit<
        WebItem,
        'collectionId'
      >;

      const item: WebItem = {
        ...transformedItem,
        createdAt: new Date(),
        collectionId,
        url,
        order: latestItem?.order ?? 0,
        preview: undefined,
        uploadStatus: ITEM_UPLOAD_STATUS.UPLOADING,
        owner: {
          id: user.id,
          name: user.name,
        },
      };

      if (transformedItem.meta.image !== undefined) {
        item.preview =
          (await createPreview(
            transformedItem.meta.title,
            transformedItem.meta.image
          )) ?? transformedItem.preview;
      }

      track('content_saved', {
        type: ITEM_TYPE.WEB,
        extension: '',
        filesize: '',
      });

      dispatch(
        addItems({
          collectionId,
          items: [item],
        })
      );

      dispatch(
        updateItem({
          item: { ...item, uploadStatus: ITEM_UPLOAD_STATUS.UPLOADED },
          itemId: item.id,
        })
      );

      dispatch(setUploadSuccessNotification(collectionId, [item.id]));
    } catch (error) {
      Monitor.error(error);
    }
  };
}

function createFileItemUpload({
  collectionId,
  size,
  name,
}: {
  collectionId: Collection['id'];
  size: number;
  name: string;
}): AppThunk<Promise<FileItemFromAPI>> {
  return async () => {
    const itemFromApi = await API.items.addFileItem({
      collectionId,
      filename: name,
      filesize: size,
    });
    return itemFromApi;
  };
}

export function addFileItems(
  files: FileList | File[],
  collectionId: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    await dispatch(fetchUsage());

    const state = getState();
    const user = getUser(state);
    const userCanUpgrade = getUserCanUpgrade(state);
    const collection = getCollectionById({ id: collectionId })(state);

    const willExceedStorage = getWillExceedStorageCheck(state);
    // Storage enforcement
    const uploadSize: number = Array.from(files).reduce(
      (size, file: File) => size + file.size,
      0
    );
    if (
      willExceedStorage(uploadSize) &&
      (collection?.type === COLLECTION_TYPE.PRIVATE ||
        collection?.type === COLLECTION_TYPE.UNSORTED)
    ) {
      dispatch(
        openModal({
          type: userCanUpgrade
            ? MODAL_TYPE.STORAGE_UPSELL
            : MODAL_TYPE.STORAGE_LIMIT,
        })
      );
      return;
    }

    try {
      // Let's add the files
      const itemsInCollection = getSortedItemsByCollectionId({
        id: collectionId,
      })(state);
      const latestItem = maxBy(itemsInCollection, 'order');

      const uploads = {} as Record<Item['id'], FileUpload>;
      const newItems = await Promise.all(
        Array.from(files).map(async (file, index) => {
          const upload = makeFileUpload(file);

          const itemFromApi = await dispatch(
            createFileItemUpload({
              collectionId,
              size: upload.size,
              name: upload.name,
            })
          );

          const transformedItem = makeItem(itemFromApi) as Omit<
            FileItem,
            'collectionId'
          >;

          const item: FileItem = {
            ...transformedItem,
            createdAt: new Date(),
            uploadStatus: ITEM_UPLOAD_STATUS.QUEUED,
            collectionId,
            url: upload.preview,
            order: (latestItem?.order ?? 0) + (index + 1),
            preview: undefined,
            owner: {
              id: user.id,
              name: user.name,
            },
          };

          if (item.fileType === 'image') {
            item.preview = await createPreview(
              transformedItem.meta.title,
              upload.preview
            );
            item.detailUrl = item.preview.url;
            item.currentDetailUrlExpiresAt = null;
          }

          if (item.fileType === 'audio') {
            item.detailUrl = upload.preview;
          }

          track('content_saved', {
            type: ITEM_TYPE.WEB,
            extension: item.extension,
            filesize: item.size,
          });

          uploads[item.id] = { ...upload, id: item.id, errorCount: 0 };

          return item;
        })
      );

      dispatch(queueFiles(uploads));
      dispatch(
        addItems({
          collectionId,
          items: newItems,
        })
      );
      dispatch(startUpload());
    } catch (error) {
      Monitor.error(error);
    }
  };
}

export function deleteLocalItems(itemIds: string[]): AppThunk {
  return (dispatch, getState) => {
    const state = getState();
    const itemsToDelete = getItemsByItemIds({ ids: itemIds })(state);
    const itemsGroupedPerCollection = groupBy(itemsToDelete, 'collectionId');
    for (const collectionId in itemsGroupedPerCollection) {
      const groupedItemIds = itemsGroupedPerCollection[collectionId].map(
        (item) => item.id
      );
      dispatch(
        removeItems({
          collectionId,
          itemIds: groupedItemIds,
        })
      );
    }
  };
}

export function deleteItems(itemIds: string[]): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { router } = state;
    try {
      const itemsToDelete = getItemsByItemIds({ ids: itemIds })(state);
      const syncedItems = itemsToDelete.filter(
        (item) =>
          item.uploadStatus === ITEM_UPLOAD_STATUS.UPLOADED ||
          item.uploadStatus === ITEM_UPLOAD_STATUS.UPLOADING
      );

      await API.items.delete({ items: syncedItems });

      syncedItems.forEach((item) => {
        track('content_deleted', {
          type: item.type,
          extension: item.type === ITEM_TYPE.FILE ? item.extension : '',
          filesize: item.type === ITEM_TYPE.FILE ? item.size : 0,
          storage_full: !getHasFreeSpace(state),
        });
      });

      const match = matchPath(ROUTES.ITEM, router.location.pathname);
      if (match) {
        const path = generatePath(ROUTES.COLLECTION, {
          collectionId: match.params.collectionId ?? null,
        });
        dispatch(push(path));
      }

      // We only delete local items if the api request has not thrown an error
      dispatch(deleteLocalItems(itemIds));

      dispatch(
        setNotification({
          type: NOTIFICATION_TYPE.SUCCESS_MESSAGE_ITEM_DELETED,
          config: {
            itemIds,
          },
        })
      );

      await dispatch(fetchUsage());
    } catch (error) {
      Monitor.error(error);
      // Items are not found, so delete them locally as well
      if (isApiError(error) && error.status === HTTP_STATUS_CODE.NOT_FOUND) {
        dispatch(deleteLocalItems(itemIds));
        dispatch(
          setNotification({
            type: NOTIFICATION_TYPE.SUCCESS_MESSAGE_ITEM_DELETED,
            config: {
              itemIds,
            },
          })
        );
      } else {
        dispatch(setNotification({ type: NOTIFICATION_TYPE.ERROR }));
      }
    }
  };
}

export function updateCaption(
  itemId: string,
  caption: string
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const itemFromApi = await API.items.updateCaption({
        itemId,
        caption,
      });
      dispatch(
        itemsSlice.actions.updateCaption({
          itemId,
          caption: itemFromApi.caption ?? caption,
        })
      );
    } catch (error) {
      Monitor.error(error);
      dispatch(setNotification({ type: NOTIFICATION_TYPE.ERROR }));
    }
  };
}

export function removeCaption(
  itemId: string
): AppThunk<Promise<void | Partial<Item>>> {
  return async (dispatch) => {
    try {
      await API.items.removeCaption({
        itemId,
      });
      dispatch(
        itemsSlice.actions.removeCaption({
          itemId,
        })
      );
      dispatch(
        setNotification({
          type: NOTIFICATION_TYPE.SUCCESS_MESSAGE_CAPTION_DELETED,
        })
      );
    } catch (error) {
      Monitor.error(error);
      dispatch(setNotification({ type: NOTIFICATION_TYPE.ERROR }));
    }
  };
}
